import React, { useEffect, useMemo, useState, useCallback } from "react";
import axios from "axios";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Spinner,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";
import FeatherIcon from "feather-icons-react";
import { toast } from "react-toastify";
import ConnectMetamaskButton from "../ConnectMetamaskButton";
import {
  mumbai,
  arbitrum,
  polygon,
  bsc,
} from "../ConnectMetamaskButton/networks";
import ModalEnvio from "./ModalEnvio";

//redux
import TableContainer from "../../../Components/Common/TableContainer";

const EnviosSemanais = ({ isCliente }) => {
  const [isConnected, setIsConnected] = useState(false);
  const [transation, setTransation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [network, setNetwork] = useState(
    process.env.REACT_APP_TESTNET === "true" ? mumbai : arbitrum
  );
  const [shouldFetch, setShouldFetch] = useState(false);
  const [coins, setCoins] = useState([]);

  const fetchPrices = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/moedas/precos`
      );

      setCoins(response.data);
    } catch (error) {
      toast.error("Houve um erro ao buscar os preços das moedas! " + error);
    }
  };

  const copyToClipboard = async (text) => {
    if (!navigator.clipboard) {
      toast.error("API de Clipboard não suportada no seu navegador.", {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: false,
        className: "bg-danger text-white",
      });
      return;
    }
    try {
      await navigator.clipboard.writeText(text);
      toast.success("Copiado para a área de transferência!", {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: false,
        className: "bg-success text-white",
      });
    } catch (err) {
      toast.error("Falha ao copiar o texto: ", err, {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: false,
        className: "bg-danger text-white",
      });
    }
  };

  function splitDateTime(dateTimeStr) {
    const dateTime = parseISO(dateTimeStr);

    const data = format(dateTime, "dd MMM, yyyy", { locale: ptBR });
    const hora = format(dateTime, "hh:mm a");

    return {
      data,
      hora,
    };
  }

  const formatCurrency = (value) => {
    if (!value) {
      return "";
    }
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  const fetchTransations = useCallback(async () => {
    const user = JSON.parse(sessionStorage.getItem("authUser"));
    try {
      const url = isCliente
        ? `${process.env.REACT_APP_API_URL}/cripto-td/envio-semanal-cliente/${user.data._id}`
        : `${process.env.REACT_APP_API_URL}/cripto-td/envio-semanal`;

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      if (response?.status === 1) {
        setLoading(false);
        return response.data;
      } else {
        toast.error(response.mensagem);
        console.error(response);
        return [];
      }
    } catch (error) {
      toast.error("Erro ao buscar operações: " + error, {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: false,
        className: "bg-danger text-white",
      });
      return [];
    }
  }, [isCliente]);

  const handleButtonClick = useCallback((data) => {
    setModalData(data);
    setShowModal(true);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchPrices();
      const retorno = await fetchTransations();
      const filteredRetorno = retorno.filter(
        (item) => item.quantidade_moeda > 0
      );
      setTransation(filteredRetorno);
      setShouldFetch(false);
      setLoading(false);
    };

    fetchData();
  }, [shouldFetch, fetchTransations]);

  const columns = useMemo(() => {
    const retornoTabela = isCliente
      ? [
          {
            Header: "Data",
            accessor: "data_envio",
            Cell: (cell) => (
              <>
                {splitDateTime(cell.row.original.data_envio).data}{" "}
                <small className="text-muted">
                  {splitDateTime(cell.row.original.data_envio).hora}
                </small>
              </>
            ),
          },
          {
            Header: "Conta",
            accessor: "cripto_td_conta.nome",
            Cell: (cell) => (
              <>
                <b>{cell.value}</b>
                <br />
                <small className="text-muted">
                  {`${cell.row.original.carteira.substring(
                    0,
                    6
                  )}...${cell.row.original.carteira.substring(
                    cell.row.original.carteira.length - 4
                  )}`}
                </small>
              </>
            ),
          },
          {
            Header: "Moeda",
            accessor: "moeda",
            Cell: (cell) => (
              <>
                <div className="d-flex align-items-center">
                  <img
                    src={`https://api.fort.exchange/crypto-icons/${cell.row.original.moeda.toLowerCase()}.svg`}
                    alt=""
                    className="avatar-xxs me-2"
                  />
                  {cell.value}
                </div>
              </>
            ),
          },
          {
            Header: "Quantidade",
            accessor: "quantidade_moeda",
            Cell: (cell) => {
              const moeda = coins.find(
                (item) => item.moeda === cell.row.original.moeda
              );
              const preco_atual_brl = cell.value * moeda.preco_atual_brl;
              return (
                <>
                  <h6
                    className={"text-primary amount mb-1"}
                    style={{ cursor: "pointer" }}
                    onClick={() => copyToClipboard(cell.value)}
                    title="Clique para copiar o valor"
                  >
                    {parseFloat(cell.value).toFixed(moeda?.decimais)}{" "}
                    {cell.row.original.moeda}{" "}
                    <FeatherIcon
                      icon="copy"
                      style={{ color: "#ccc", height: "15px" }}
                    />
                  </h6>
                  <p className="text-success mb-0">
                    <b>{"Preço Atual: "}</b>
                    {formatCurrency(preco_atual_brl)}
                  </p>
                </>
              );
            },
          },
          {
            Header: "Transaction ID",
            accessor: "transaction_id",
            Cell: (cell) => {
              const moeda = coins.find(
                (item) => item.moeda === cell.row.original.moeda
              );
              return (
                <>
                  {cell.value !== "" &&
                    (moeda.rede === "Arbitrum" ? (
                      <Link
                        to={arbitrum.blockExplorerUrls[0] + "tx/" + cell.value}
                        className="btn btn-sm btn-secondary"
                        target="_blank"
                      >
                        Transação
                      </Link>
                    ) : moeda.rede === "Polygon" ? (
                      <Link
                        to={polygon.blockExplorerUrls[0] + "tx/" + cell.value}
                        className="btn btn-sm btn-secondary"
                        target="_blank"
                      >
                        Transação
                      </Link>
                    ) : moeda.rede === "BSC" ? (
                      <Link
                        to={bsc.blockExplorerUrls[0] + "tx/" + cell.value}
                        className="btn btn-sm btn-secondary"
                        target="_blank"
                      >
                        Transação
                      </Link>
                    ) : moeda.rede === "Core" ? (
                      <Link
                        to={`https://blockchair.com/bitcoin/transaction/${cell.value}`}
                        className="btn btn-sm btn-secondary"
                        target="_blank"
                      >
                        Transação
                      </Link>
                    ) : (
                      <Link
                        to={`https://solscan.io/tx/${cell.value}`}
                        className="btn btn-sm btn-secondary"
                        target="_blank"
                      >
                        Transação
                      </Link>
                    ))}
                </>
              );
            },
          },
        ]
      : [
          {
            Header: "Moeda",
            accessor: "moeda",
            Cell: (cell) => (
              <>
                <div className="d-flex align-items-center">
                  <img
                    src={`https://api.fort.exchange/crypto-icons/${cell.row.original.moeda.toLowerCase()}.svg`}
                    alt=""
                    className="avatar-xxs me-2"
                  />
                  {cell.value}
                </div>
              </>
            ),
          },
          {
            Header: "Data",
            Cell: (cell) => (
              <>
                {splitDateTime(cell.row.original.updated_at).data}{" "}
                <small className="text-muted">
                  {splitDateTime(cell.row.original.updated_at).hora}
                </small>
              </>
            ),
          },
          {
            Header: "Quantidade",
            accessor: "quantidade_moeda",
            Cell: (cell) => (
              <>
                <h6
                  className={"text-success amount mb-1"}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    copyToClipboard(cell.row.original.quantidade_moeda)
                  }
                  title="Clique para copiar o valor"
                >
                  {parseFloat(cell.value).toFixed(6)} {cell.row.original.moeda}{" "}
                  <FeatherIcon
                    icon="copy"
                    style={{ color: "#ccc", height: "15px" }}
                  />
                </h6>
                <p className="text-muted mb-0">
                  <b>{"Comprados a: "}</b>
                  {formatCurrency(cell.row.original.quantidade_brl)}
                </p>
                <p className="text-muted mb-0">
                  <b>{"Preço Atual: "}</b>
                  {formatCurrency(
                    cell.value *
                      coins.find(
                        (item) => item.moeda === cell.row.original.moeda
                      ).preco_atual_brl
                  )}
                </p>
              </>
            ),
          },
          {
            Header: "Taxa Fort",
            accessor: "lucro",
            Cell: (cell) => <>{formatCurrency(cell.value)}</>,
          },
          {
            Header: "Taxa de Contrato",
            accessor: "taxa_envio",
            Cell: (cell) => (
              <>
                <h6 className={"text-danger amount mb-1"}>
                  {parseFloat(cell.value).toFixed(6)}
                </h6>
                <p className="text-muted mb-0">
                  {formatCurrency(
                    cell.value *
                      coins.find(
                        (item) => item.moeda === cell.row.original.moeda
                      ).preco_atual_brl
                  )}
                </p>
              </>
            ),
          },
          {
            Header: "Transaction ID",
            accessor: "transaction_id",
            Cell: (cell) => {
              const moeda = coins.find(
                (item) => item.moeda === cell.row.original.moeda
              );
              return (
                <>
                  {cell.value === "" ? (
                    <>
                      {moeda.rede === "Core" || moeda.rede === "Solana" ? (
                        <Button
                          className="btn btn-sm btn-danger"
                          onClick={() => handleButtonClick(cell.row.original)}
                          disabled={network === null}
                        >
                          Enviar
                        </Button>
                      ) : (
                        isConnected &&
                        (moeda.rede !== network.chainName ? (
                          <Button
                            className="btn btn-sm btn-warning"
                            onClick={() =>
                              setNetwork(
                                moeda.rede === "Arbitrum"
                                  ? arbitrum
                                  : moeda.rede === "Polygon"
                                  ? polygon
                                  : moeda.rede === "BSC"
                                  ? bsc
                                  : mumbai
                              )
                            }
                          >
                            Mudar para {moeda.rede}
                          </Button>
                        ) : (
                          <Button
                            className="btn btn-sm btn-danger"
                            onClick={() => handleButtonClick(cell.row.original)}
                            disabled={network === null}
                          >
                            Enviar
                          </Button>
                        ))
                      )}
                    </>
                  ) : process.env.REACT_APP_TESTNET === "false" ? (
                    moeda.rede === "Arbitrum" ? (
                      <Link
                        to={arbitrum.blockExplorerUrls[0] + "tx/" + cell.value}
                        className="btn btn-sm btn-secondary"
                        target="_blank"
                      >
                        Transação
                      </Link>
                    ) : process.env.REACT_APP_TESTNET === "false" &&
                      moeda.rede === "Polygon" ? (
                      <Link
                        to={polygon.blockExplorerUrls[0] + "tx/" + cell.value}
                        className="btn btn-sm btn-secondary"
                        target="_blank"
                      >
                        Transação
                      </Link>
                    ) : process.env.REACT_APP_TESTNET === "false" &&
                      moeda.rede === "BSC" ? (
                      <Link
                        to={bsc.blockExplorerUrls[0] + "tx/" + cell.value}
                        className="btn btn-sm btn-secondary"
                        target="_blank"
                      >
                        Transação
                      </Link>
                    ) : moeda.rede === "Core" ? (
                      <Link
                        to={`https://blockchair.com/bitcoin/transaction/${cell.value}`}
                        className="btn btn-sm btn-secondary"
                        target="_blank"
                      >
                        Transação
                      </Link>
                    ) : (
                      <Link
                        to={`https://solscan.io/tx/${cell.value}`}
                        className="btn btn-sm btn-secondary"
                        target="_blank"
                      >
                        Transação
                      </Link>
                    )
                  ) : (
                    <Link
                      to={`${mumbai.blockExplorerUrls[0]}tx/${cell.value}`}
                      className="btn btn-sm btn-secondary"
                      target="_blank"
                    >
                      Transação
                    </Link>
                  )}
                </>
              );
            },
          },
        ];
    return retornoTabela;
  }, [handleButtonClick, network, isCliente, coins, isConnected]);
  return (
    <React.Fragment>
      {!isCliente ? (
        <ModalEnvio
          show={showModal}
          data={modalData}
          setShowModal={setShowModal}
          network={network}
          setShouldFetch={setShouldFetch}
          coins={coins}
        />
      ) : null}

      <Card>
        <CardHeader>
          <Row className="align-items-center g-3">
            <Col md={3}>
              <h5 className="card-title mb-0">Últimos Envios Semanais</h5>
            </Col>
            {!isCliente ? (
              <div className="col-md-auto ms-auto">
                <div className="d-flex gap-2">
                  <ConnectMetamaskButton
                    networkConfig={network}
                    loading={loading}
                    isConnected={isConnected}
                    setIsConnected={setIsConnected}
                  />
                </div>
              </div>
            ) : null}
          </Row>
        </CardHeader>
        <CardBody>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                padding: "20px",
              }}
            >
              <Spinner height={100} width={100} />
            </div>
          ) : (
            <TableContainer
              columns={columns}
              data={transation || []}
              isGlobalFilter={false}
              isAddUserList={false}
              customPageSize={20}
              className="custom-header-css"
              divClass="table-responsive table-card mb-4"
              tableClass="align-middle table-nowrap mb-0"
              theadClass="table-light table-nowrap"
              thClass="table-light text-muted"
            />
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default EnviosSemanais;
