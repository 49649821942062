import React, { useState, useCallback, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Button,
  Modal,
  ModalBody,
  Spinner,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import MyCurrencies from "./MyCurrencies";
import MyPortfolio from "./MyPortfolio";
import Trading from "./Trading";
import Widgets from "./Widgets";
import axios from "axios";
import { toast } from "react-toastify";

const DashboardCrypto = () => {
  const user = JSON.parse(sessionStorage.getItem("authUser"));
  const [verValores, setVerValores] = useState(
    user.data.clientData.visualizar_saldo === 0 ? true : false
  );
  const [modal_center, setmodal_center] = useState(false);
  const tog_center = useCallback(() => {
    setmodal_center(!modal_center);
    sessionStorage.setItem("show_warning", "false");
  }, [modal_center]);

  document.title = "Painel Cliente | Painel Fort Exchange";
  const [cryptoWidgets, setSaldos] = useState([
    {
      id: 1,
      icon: "ri-money-dollar-circle-fill",
      label: "Conta Principal",
      counter: "0",
      badge: "ri-arrow-up-s-fill",
      badgeColor: "success",
      decimal: "2",
      prefix: "R$ ",
      separator: ",",
    },
    {
      id: 2,
      icon: "ri-money-dollar-circle-fill",
      label: "Subcontas",
      counter: "0",
      badge: "ri-arrow-up-s-fill",
      badgeColor: "success",
      decimal: "2",
      prefix: "R$ ",
      separator: ",",
    },
    {
      id: 3,
      icon: "ri-money-dollar-circle-fill",
      label: "Total R$",
      counter: "0",
      badge: "ri-arrow-up-s-fill",
      badgeColor: "success",
      decimal: "2",
      prefix: "R$ ",
      separator: ",",
    },
  ]);
  const [loading, setLoading] = useState(true);
  const [contas, setContas] = useState([]);
  const [coins, setCoins] = useState([]);
  const [fetchData, setFetch] = useState(true);

  const checkTime = () => {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();

    const currentTimeInMinutes = hours * 60 + minutes;
    const startInterval = 9 * 60 + 45; // 9:45 convertido para minutos
    const endInterval = 10 * 60 + 15; // 10:15 convertido para minutos

    return (
      currentTimeInMinutes >= startInterval &&
      currentTimeInMinutes <= endInterval
    );
  };

  const blockedTime = checkTime();

  const fetchPrices = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/moedas/precos`
      );

      if (response.data && response.status && response.status === 1) {
        setCoins(response.data);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      console.error("Houve um erro ao buscar os preços das moedas!", error);
    }
  }, []);

  const formatCurrency = (value) => {
    if (!value) {
      return "R$ 0,00";
    }
    try {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    } catch (error) {
      return "R$ 0,00";
    }
  };

  const getSaldos = useCallback(async () => {
    setLoading(true);
    await fetchPrices();
    const clientData = JSON.parse(sessionStorage.getItem("authUser") || "{}");
    const userId = clientData.data._id ? clientData.data._id : 0;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/cripto-td/contas/saldos/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${clientData.token}`,
          },
        }
      );

      if (response.status === 1) {
        let temp = cryptoWidgets;
        temp[0].counter = response.data.saldo_principal.toString();
        temp[1].counter = response.data.saldo_subcontas.toString();
        temp[2].counter = (
          response.data.saldo_subcontas + response.data.saldo_principal
        ).toString();
        setContas(response.data.contas);
        setSaldos(temp);
      }
    } catch (error) {
      toast.error("Erro ao buscar saldos");
    } finally {
      setLoading(false);
      setFetch(false);
    }
  }, [cryptoWidgets, fetchPrices]);

  useEffect(() => {
    const _fetchData = async () => {
      await getSaldos();
    };

    if (fetchData) _fetchData();
  }, [getSaldos, fetchData, coins]);

  if (user.data.role === "Lojista") {
    window.location.href = "/perfil-lojista";
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          padding: "20px",
        }}
      >
        <Spinner height={100} width={100} />
      </div>
    );
  } else if (parseInt(user.data.clientData.nivel_kyc) === 0) {
    window.location.href = "/profile";
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          padding: "20px",
        }}
      >
        <Spinner height={100} width={100} />
      </div>
    );
  } else
    return (
      <React.Fragment>
        <Modal
          isOpen={modal_center}
          toggle={() => {
            tog_center();
          }}
          centered
        >
          <ModalBody className="text-center p-5">
            <img
              src="https://api.fort.exchange/image/danger.png"
              alt="danger"
              className="avatar-md"
              style={{ height: "200px", width: "200px" }}
            />
            <div className="mt-4">
              <h4 className="mb-3">Atenção! Risco de perda de ativos!</h4>
              <p className="text-warning-light mb-4">
                {" "}
                Se você tem alguma conta de ETH ou MATIC,{" "}
                <b className="text-warning">
                  não utilize carteiras de exchange
                </b>{" "}
                para receber os valores. Exchanges dificilmente rastreiam a
                entrada desses ativos via MULTISEND.
              </p>
              <p className="text-danger mb-4">
                <b>
                  UMA EXCHANGE PODE NEGAR A ENTRADA DESSAS MOEDAS QUANDO ENVIADO
                  POR MULTISEND!
                </b>
              </p>
              <p className="text-warning-light mb-4">
                Se a carteira que você está usando para ETH e MATIC é de sua
                posse mesmo,{" "}
                <b className="text-warning">
                  apenas fique ciente de que a transação de envio é uma
                  transação interna
                </b>
                . Ou seja, embora você receba normalmente os seus tokens e seu
                saldo aumente, o aplicativo da sua carteira pode não exibir a
                transação.
              </p>
              <div className="hstack gap-2 justify-content-center">
                <Button color="danger" onClick={() => setmodal_center(false)}>
                  Clique aqui se entendeu
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title="Painel" pageTitle="Clientes Fort" />
            <Row>
              <MyPortfolio
                contas={contas}
                loading={loading}
                coins={coins}
                saldos={cryptoWidgets}
                formatCurrency={formatCurrency}
                setVerValores={setVerValores}
                verValores={verValores}
                verValoresAtivado={
                  user.data.clientData.visualizar_saldo === 1 ? true : false
                }
              />
              <Col className="order-xxl-0 order-first">
                <Row>
                  <Widgets
                    cryptoWidgets={cryptoWidgets}
                    loading={loading}
                    coins={coins}
                    setFetch={setFetch}
                    setVerValores={setVerValores}
                    verValores={verValores}
                    verValoresAtivado={
                      user.data.clientData.visualizar_saldo === 1 ? true : false
                    }
                  />
                  <MyCurrencies
                    contas={contas}
                    loading={loading}
                    coins={coins}
                    setContas={setContas}
                    formatCurrency={formatCurrency}
                    blockedTime={blockedTime}
                    setVerValores={setVerValores}
                    verValores={verValores}
                    verValoresAtivado={
                      user.data.clientData.visualizar_saldo === 1 ? true : false
                    }
                  />
                  <Trading
                    coins={coins}
                    contas={contas}
                    loading={loading}
                    saldos={cryptoWidgets}
                    setFetch={setFetch}
                    setLoading={setLoading}
                    formatCurrency={formatCurrency}
                    tog_center={tog_center}
                    blockedTime={blockedTime}
                  />
                </Row>
              </Col>
            </Row>
            {/* <Row>
            <Widgets1 />
          </Row> */}
          </Container>
        </div>
      </React.Fragment>
    );
};

export default DashboardCrypto;
