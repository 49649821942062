import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import ParticlesAuth from "../ParticlesAuth";
import ChatScript from "../../../Layouts/ChatScript";

const Basic404 = () => {
  document.title =
    "Erro 404 | Painel Fort Exchange";
  return (
    <React.Fragment>
      <div className="auth-page-wrapper">
        <ParticlesAuth>
          <div className="auth-page-content">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="text-center pt-4">
                    <div className="">
                      <img
                        src={"https://api.fort.exchange/image/gato-ufo.png"}
                        alt=""
                        className="error-basic-img move-animation"
                      />
                    </div>
                    <div className="mt-n4">
                      <h1 className="display-1 fw-medium">404</h1>
                      <h3 className="text-uppercase">
                        Vish... Página não encontrada 😭
                      </h3>
                      <p className="text-muted mb-4" style={{fontSize:"16px"}}>
                        A página que você está procurando deve ter sido abduzida
                        por alienígenas, ou talvez nosso gato programador tenha
                        apagado o arquivo por engano enquanto tirava uma soneca
                        no teclado.{" "}
                        <span role="img" aria-label="Cat sleeping on keyboard">
                          🐱💤
                        </span>
                        {"\n\n"}
                      </p>
                      <Link to="/" className="btn btn-success">
                        <i className="mdi mdi-home me-1"></i>Voltar ao Painel
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
              <ChatScript />
            </Container>
          </div>
        </ParticlesAuth>
      </div>
    </React.Fragment>
  );
};

export default Basic404;
