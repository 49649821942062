import React, { useState, useCallback, useEffect, useRef } from "react";
import { Col, Label, Row, TabPane, Form } from "reactstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { Spinner } from "reactstrap";

const TabSaldos = () => {
  const [loading, setLoading] = useState(false);
  const [depositos, setDepositos] = useState([]);
  const [retiradas, setRetiradas] = useState([]);

  const getDepositosRetiradas = useCallback(async () => {
    setLoading(true);
    const userData = JSON.parse(sessionStorage.getItem("authUser") || "{}");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/client/depositos-retiradas/${userData.data._id}`,
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      );

      if (response.status === 1) {
        setDepositos(response.depositos);
        setRetiradas(response.retiradas);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro ao buscar saldos");
      console.log(error);
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    getDepositosRetiradas();
  }, [ getDepositosRetiradas]);

  const formatCurrency = (value) => {
    if (!value) {
      return "R$ 0,00";
    }
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  const formatDate = (dateString) => {
    if (!dateString) {
      return "";
    }

    const [datePart] = dateString.split("T");
    const [year, month, day] = datePart.split("-");

    return `${day}/${month}/${year}`;
  };

  return (
    <TabPane tabId="3">
      {loading ? (
        <Spinner />
      ) : (
        <Row>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label">Depósitos</Label>
              <div className="table-responsive">
                <table className="table table-nowrap table-hover mb-0">
                  <thead className="table-light">
                    <tr>
                      <th className="border-0">Data</th>
                      <th className="border-0">Valor</th>
                      <th className="border-0">Fonte</th>
                    </tr>
                  </thead>
                  <tbody>
                    {depositos.map((deposito) => (
                      <tr key={deposito.id}>
                        <td>{formatDate(deposito.created_at)}</td>
                        <td style={{ color: "darkgreen" }}>
                          +{formatCurrency(deposito.amount)}
                        </td>
                        <td>{deposito.fonte}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label">Retiradas</Label>
              <div className="table-responsive">
                <table className="table table-nowrap table-hover mb-0">
                  <thead className="table-light">
                    <tr>
                      <th className="border-0">Data</th>
                      <th className="border-0">Valor</th>
                      <th className="border-0">Comprovante</th>
                    </tr>
                  </thead>
                  <tbody>
                    {retiradas.map((retirada) => {
                      let url_download =
                        retirada.comprovante !== ""
                          ? `${process.env.REACT_APP_API_URL.replace(
                              "/api",
                              ""
                            )}/storage/${retirada.comprovante}`
                          : "";

                      return (
                        <tr key={retirada.id}>
                          <td>{formatDate(retirada.created_at)}</td>
                          <td style={{ color: "darkred" }}>
                            - {formatCurrency(retirada.amount)}
                          </td>
                          <td>
                            {url_download !== "" && (
                              <a
                                href={url_download}
                                download
                                className="btn btn-sm btn-success"
                              >
                                Baixar
                              </a>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </TabPane>
  );
};

export default TabSaldos;
