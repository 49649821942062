import React, { useState, useCallback, useEffect, useRef } from "react";
import { Col, Label, Row, TabPane, Form, Spinner } from "reactstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { NumericFormat } from "react-number-format";
import ClientesContas from "./ClientesContas";

const TabSaldos = ({ user_id }) => {
  const [loading, setLoading] = useState(false);
  const [depositos, setDepositos] = useState([]);
  const [retiradas, setRetiradas] = useState([]);
  let comprovanteInput = useRef(null);
  const [saldoPrincipal, setSaldoPrincipal] = useState(0);
  const [quantidade, setQuantidade] = useState(0);
  const [tipoOperacao, setTipoOperacao] = useState("deposito");
  const user = JSON.parse(sessionStorage.getItem("authUser"));
  const [contasLista, setContasLista] = useState([]);
  const [conta, setConta] = useState(0);
  const [coins, setCoins] = useState([]);

  const getContas = useCallback(async () => {
    const clientData = JSON.parse(sessionStorage.getItem("authUser") || "{}");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/cripto-td/contas/user/${user_id}`,
        {
          headers: {
            Authorization: `Bearer ${clientData.token}`,
          },
        }
      );

      if (response.status && response.status === 1) {
        setContasLista(response.data);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro ao buscar saldos");
    }
  }, [setContasLista, user_id]);

  const getCoins = useCallback(async () => {
    const clientData = JSON.parse(sessionStorage.getItem("authUser") || "{}");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/moedas/precos`,
        {
          headers: {
            Authorization: `Bearer ${clientData.token}`,
          },
        }
      );

      if (response.status && response.status === 1) {
        setCoins(response.data);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro ao buscar saldos");
      console.log(error);
    }
  }, [setCoins]);

  const getSaldos = useCallback(async () => {
    setLoading(true);
    const userData = JSON.parse(sessionStorage.getItem("authUser") || "{}");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/cripto-td/contas/saldos/${user_id}`,
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      );

      if (response.status === 1) {
        setSaldoPrincipal(response.data.saldo_principal);
      }
    } catch (error) {
      toast.error("Erro ao buscar saldos");
    }

    setLoading(false);
  }, [user_id]);

  const getDepositosRetiradas = useCallback(async () => {
    setLoading(true);
    const userData = JSON.parse(sessionStorage.getItem("authUser") || "{}");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/client/depositos-retiradas/${user_id}`,
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      );

      if (response.status === 1) {
        setDepositos(response.depositos);
        setRetiradas(response.retiradas);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro ao buscar saldos");
      console.log(error);
    }

    setLoading(false);
  }, [user_id]);

  useEffect(() => {
    getSaldos();
    getDepositosRetiradas();
    getCoins();
    getContas();
  }, [getSaldos, getDepositosRetiradas, getCoins, getContas]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const userData = JSON.parse(sessionStorage.getItem("authUser"));

      const formData = new FormData();

      formData.append("quantidade", quantidade);
      formData.append("conta", conta);

      if (
        tipoOperacao === "retirada" &&
        comprovanteInput &&
        comprovanteInput.files[0]
      ) {
        formData.append("comprovante", comprovanteInput.files[0]);
      }

      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/client/${tipoOperacao}/${user_id}`,
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
        data: formData,
      });

      if (response.status && response.status === 1) {
        toast.success(response.mensagem);
        if (tipoOperacao === "deposito") {
          setSaldoPrincipal(saldoPrincipal + quantidade);
        } else {
          setSaldoPrincipal(saldoPrincipal - quantidade);
        }
        setQuantidade(0);
        setConta(0);
        comprovanteInput = null;
        setTipoOperacao("deposito");
        getSaldos();
        getDepositosRetiradas();
        getCoins();
        getContas();
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro na API");
      console.log(error);
    }

    setLoading(false);
  };

  const formatCurrency = (value) => {
    if (!value) {
      return "R$ 0,00";
    }
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  const formatDate = (dateString) => {
    if (!dateString) {
      return "";
    }

    const [datePart] = dateString.split("T");
    const [year, month, day] = datePart.split("-");

    return `${day}/${month}/${year}`;
  };

  return (
    <TabPane tabId="2">
      {user.data.role === "Admin" && (
        <Form>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                padding: "20px",
              }}
            >
              <Spinner height={100} width={100} />
            </div>
          ) : (
            <Row style={{ marginBottom: "40px" }}>
              <Col lg={12}>
                <div className="mb-3">
                  <Label className="form-label">Saldo Principal: </Label>
                  <b style={{ color: "darkgreen", marginLeft: "10px" }}>
                    {formatCurrency(saldoPrincipal)}
                  </b>
                </div>
              </Col>
              <Col lg={3}>
                <div className="mb-3">
                  <Label className="form-label">Valor</Label>
                  <NumericFormat
                    className="form-control"
                    placeholder="0,00"
                    value={quantidade}
                    onValueChange={({ value }) =>
                      setQuantidade(parseFloat(value))
                    }
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    prefix="R$ "
                    fixedDecimalScale={true}
                    allowNegative={false}
                  />
                </div>
              </Col>
              <Col lg={3}>
                <div className="mb-3">
                  <Label className="form-label">Tipo Operação</Label>
                  <select
                    className="form-control"
                    value={tipoOperacao}
                    onChange={(e) => setTipoOperacao(e.target.value)}
                  >
                    <option value="deposito">Depósito</option>
                    <option value="retirada">Retirada</option>
                  </select>
                </div>
              </Col>
              <Col lg={3}>
                <div className="mb-3">
                  <Label className="form-label">Conta</Label>
                  <select
                    className="form-control"
                    value={conta}
                    onChange={(e) => setConta(e.target.value)}
                  >
                    <option value="0">Conta Principal</option>
                    {contasLista.map((conta_td) => (
                      <option value={conta_td.id} key={conta_td.id}>
                        {conta_td.nome}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
              {tipoOperacao === "retirada" && (
                <Col lg={3}>
                  <div className="mb-3">
                    <Label className="form-label">Comprovante (opcional)</Label>
                    <input
                      type="file"
                      className="form-control"
                      accept=".jpg,.jpeg,.png,.pdf"
                      ref={(input) => (comprovanteInput = input)}
                    />
                  </div>
                </Col>
              )}

              <Col lg={12}>
                <div className="text-end">
                  <button className="btn btn-primary" onClick={handleSubmit}>
                    Alterar Saldo
                  </button>
                </div>
              </Col>
            </Row>
          )}
        </Form>
      )}

      <Row style={{ marginBottom: "40px" }}>
        <Col lg={12}>
          <div className="mb-3">
            <Label className="form-label">SubContas</Label>
          </div>
        </Col>
        <Col lg={12}>
          <ClientesContas
            formatCurrency={formatCurrency}
            contasLista={contasLista}
            coins={coins}
            loading={loading}
          />
        </Col>
      </Row>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            padding: "20px",
          }}
        >
          <Spinner height={100} width={100} />
        </div>
      ) : (
        <Row>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label">Depósitos</Label>
              <div className="table-responsive">
                <table className="table table-nowrap table-hover mb-0">
                  <thead className="table-light">
                    <tr>
                      <th className="border-0">Data</th>
                      <th className="border-0">Valor</th>
                      <th className="border-0">Fonte</th>
                    </tr>
                  </thead>
                  <tbody>
                    {depositos.map((deposito) => (
                      <tr key={deposito.id}>
                        <td>{formatDate(deposito.created_at)}</td>
                        <td style={{ color: "darkgreen" }}>
                          +{formatCurrency(deposito.amount)}
                        </td>
                        <td>{deposito.fonte}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label">Retiradas</Label>
              <div className="table-responsive">
                <table className="table table-nowrap table-hover mb-0">
                  <thead className="table-light">
                    <tr>
                      <th className="border-0">Data</th>
                      <th className="border-0">Valor</th>
                      <th className="border-0">Comprovante</th>
                    </tr>
                  </thead>
                  <tbody>
                    {retiradas.map((retirada) => {
                      let url_download =
                        retirada.comprovante !== ""
                          ? `${process.env.REACT_APP_API_URL.replace(
                              "/api",
                              ""
                            )}/storage/${retirada.comprovante}`
                          : "";

                      return (
                        <tr key={retirada.id}>
                          <td>{formatDate(retirada.created_at)}</td>
                          <td style={{ color: "darkred" }}>
                            - {formatCurrency(retirada.amount)}
                          </td>
                          <td>
                            {url_download !== "" && (
                              <a
                                href={url_download}
                                download
                                className="btn btn-sm btn-success"
                              >
                                Baixar
                              </a>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </TabPane>
  );
};

export default TabSaldos;
