import React from "react";
import { Container, Row } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import AllTransactions from "./AllTransactions";
import Widgets from "./Widgets";

const Transactions = () => {
  document.title = "Compra Diária | Painel Fort Exchange";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Compra Diária" pageTitle="DCA Diário" />
          <Row>
            <Widgets />
          </Row>
          <AllTransactions />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Transactions;
