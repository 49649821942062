import React, { useMemo, useCallback, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Spinner,
  Button,
  Badge,
} from "reactstrap";

import TableContainer from "../../../Components/Common/TableContainer";
import ModalMigrar from "./ModalMigrar";
import { toast } from "react-toastify";
import axios from "axios";

const AllOrders = ({ orderList, loading, setShouldFetch }) => {
  const [clienteId, setClienteId] = useState(null);
  const [modalMigrar, setmodalMigrar] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const desativar2fa = async (userId) => {
    const userDados = JSON.parse(sessionStorage.getItem("authUser"));
    const token = userDados?.token;
    if (!token) {
      toast.error("Token não encontrado");
      return;
    }
    try {
      const url = `${process.env.REACT_APP_API_URL}/secrets2fa/${userId}`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status && response.status === 1) {
        toast.success(response.mensagem);
      } else {
        toast.error(response.mensagem);
        console.error(response);
      }
    } catch (error) {
      console.error("Erro ao desativar 2fa:", error);
    }
  };

  const migrarHistorico = useCallback(
    async (userId) => {
      setLoadingButton(true);
      const user = JSON.parse(sessionStorage.getItem("authUser"));
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/migrate-historico/${userId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        if (response.status && response.status === 1) {
          toast.success(response.mensagem);
          setShouldFetch(true);
          console.log(response);
        } else {
          toast.error(response.mensagem);
          console.log(response);
        }
      } catch (error) {
        toast.error("Erro na API.");
        console.log(error);
      } finally {
        setLoadingButton(false);
      }
    },
    [setShouldFetch]
  );

  function formatName(name) {
    let lowerCaseName = name.toLowerCase();
    let properName = lowerCaseName.replace(
      /(^|\s|')(\w)/g,
      function (match, separator, letter, index, fullText) {
        // Encontra a palavra completa a partir do índice
        const matchResult = fullText.slice(index).match(/^[^\s']+|\b\w+/);
        // Se matchResult for null, retorna a letra original
        if (!matchResult) {
          return match;
        }
        const word = matchResult[0];
        const lowerCaseWords = ["de", "da", "do", "dos", "das"];
        if (lowerCaseWords.includes(word)) {
          return separator + letter;
        }
        return separator + letter.toUpperCase();
      }
    );

    return properName;
  }

  const formatCurrency = (value) => {
    if (!value) {
      return "R$ 0,00";
    }
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };
  const columns = useMemo(
    () => [
      {
        Header: "Cliente",
        accessor: "nome_completo",
        filterable: true,
        Cell: (cell) => (
          <>
            <div className="d-flex align-items-center">
              <Link to="#" className="currency_name flex-grow-1 ms-2 text-body">
                <b style={{ color: "darkBlue" }}>
                  {cell.value ? formatName(cell.value) : ""}
                </b>
                <br />
                {/* a badge with the text first letter uppercase */}
                {cell.row.original.metamap_status_verification && (
                  <Badge
                    color={
                      cell.row.original.metamap_status_verification ===
                      "verified"
                        ? "success"
                        : "danger"
                    }
                    className="me-1"
                  >
                    {cell.row.original.metamap_status_verification}
                  </Badge>
                )}
              </Link>
            </div>
          </>
        ),
      },
      {
        Header: "E-mail",
        accessor: "email",
        filterable: true,
        Cell: (cell) => {
          return <span style={{ color: "darkGreen" }}>{cell.value}</span>;
        },
      },
      {
        Header: "CPF",
        accessor: "cpf_cnpj",
        filterable: true,
        Cell: (cell) => {
          return cell.value;
        },
      },
      {
        Header: "Saldo Total",
        accessor: "saldo_brl",
        filterable: false,
        Cell: (order) => {
          return (
            <>
              {formatCurrency(
                parseFloat(order.row.original.saldo_brl ?? 0) +
                  parseFloat(order.row.original.saldo_subcontas ?? 0)
              )}
            </>
          );
        },
      },
      {
        Header: "Limite Mensal",
        accessor: "negociar_mensalmente",
        filterable: false,
        Cell: (cell) => {
          return <>{formatCurrency(cell.value)}</>;
        },
      },
      {
        Header: "Ações",
        accessor: "user_id",
        filterable: false,
        Cell: (order) => {
          return (
            <>
              <Link to={`/clientes/${order.row.original.user_id}`}>
                <Button color="primary" className="btn btn-sm ms-1">
                  Ver Dados
                </Button>
              </Link>
              <Button
                color="info"
                className="btn btn-sm ms-1"
                onClick={() => desativar2fa(order.row.original.user_id)}
              >
                Resetar 2FA
              </Button>
              <Link to={`/logs/${order.row.original.user_id}`}>
                <Button color="secondary" className="btn btn-sm ms-1">
                  Ver Logs
                </Button>
              </Link>
              {!order.row.original.saldo_migrado && (
                <Button
                  color="warning"
                  className="btn btn-sm ms-1"
                  onClick={() => {
                    setClienteId(order.row.original.user_id);
                    setmodalMigrar(true);
                  }}
                  disabled={loadingButton}
                >
                  Migrar Saldos
                </Button>
              )}
              {!order.row.original.historico_migrado && (
                <Button
                  color="danger"
                  className="btn btn-sm ms-1"
                  onClick={() => migrarHistorico(order.row.original.user_id)}
                  disabled={loadingButton}
                >
                  Migrar Histórico
                </Button>
              )}
            </>
          );
        },
      },
    ],
    [migrarHistorico, loadingButton]
  );
  return (
    <React.Fragment>
      <ModalMigrar
        cliente_id={clienteId}
        setmodalMigrar={setmodalMigrar}
        modalMigrar={modalMigrar}
        setShouldFetch={setShouldFetch}
      />
      <Col lg={12}>
        <Card>
          <CardHeader className="d-flex align-items-center border-0">
            <h5 className="card-title mb-0 flex-grow-1">Clientes FORT</h5>
            <div className="flex-shrink-0">
              <div className="flax-shrink-0 hstack gap-2">
                <Link to="/clientes-cadastro" className="btn btn-primary">
                  Cadastrar Cliente
                </Link>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Spinner height={100} width={100} />
              </div>
            ) : (
              <TableContainer
                columns={columns}
                data={orderList || []}
                isGlobalFilter={true}
                isAddUserList={false}
                customPageSize={20}
                className="custom-header-css"
                divClass="table-responsive table-card mb-4"
                tableClass="align-middle table-nowrap mb-0"
                theadClass="table-light table-nowrap"
                thClass="table-light text-muted"
              />
            )}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default AllOrders;
