export const contracts = {
    Arbitrum: {
        send_contract: "0x2ddA2F68F4b17002DC8645134d064Dd40262Af35",
    },
    Mumbai: {
        send_contract: "0xD4dD1C3b8B964157c070f3709a92f211aB692BeD",
    },
    Polygon: {
        send_contract: "0x98e2e5f83a47Ef8a39Fb8501Ea7cC75fFCf9B1a3",
    },
    BSC: {
        send_contract: "0xd13d075ba13f9009452b736b37932E28ba06844f",
    },
}