import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
  Spinner,
} from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { NumericFormat } from "react-number-format";

const ModalNewOrder = ({ lojista_id, setmodalOrder, show, setShouldFetch }) => {
  const [loading, setLoading] = useState(false);
  const [produto, setProduto] = useState("");
  const [brl_amount, setBrlAmount] = useState(0);
  const [btc_amount, setBtcAmount] = useState(0);
  const [btc, setBtc] = useState([]);

  const fetchPrices = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/moedas/BTC`
      );

      if (response && response.status === 1) {
        setBtc(response.data);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      console.error("Houve um erro ao buscar os preços das moedas!", error);
    }
    setLoading(false);
  };

  const createOrder = async (event) => {
    event.preventDefault();
    setLoading(true);
    const clientData = JSON.parse(sessionStorage.getItem("authUser") || "{}");
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/operacoes`,
        headers: {
          Authorization: `Bearer ${clientData.token}`,
        },
        data: {
          user_id: lojista_id,
          produto: produto,
          brl_amount: brl_amount,
          btc_amount: btc_amount,
        },
      });

      if (response && response.status === 1) {
        toast.success(response.mensagem);
        setmodalOrder(!show);
        setShouldFetch(true);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro na edição da conta: " + error);
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (show) {
      fetchPrices();
    }
  }, [show]);

  return (
    <Modal
      isOpen={show}
      backdrop={"static"}
      toggle={() => {
        setmodalOrder(!show);
      }}
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          setmodalOrder(!show);
        }}
      >
        Vender produto em BTC Lightining
      </ModalHeader>
      <ModalBody>
        <form>
          <div className="row g-3">
            <Col md={12}>
              <div>
                <Label className="form-Label">Produto</Label>
                <Input
                  type="text"
                  className="form-control"
                  value={produto}
                  onChange={(e) => setProduto(e.target.value)}
                  maxLength={200}
                />
              </div>
            </Col>
            <Col md={6}>
              <div>
                <Label htmlFor="compraDiaria" className="form-Label">
                  Valor em R$
                </Label>
                <NumericFormat
                  className="form-control"
                  placeholder="R$ 0,00"
                  value={brl_amount}
                  onValueChange={({ value }) => {
                    setBrlAmount(parseFloat(value));
                    const user = JSON.parse(
                      sessionStorage.getItem("authUser") || "{}"
                    );

                    let spread = 0;

                    if (user.data.lojistaData) {
                      spread = user.data.lojistaData.spread_venda;
                    }

                    setBtcAmount(
                      (parseFloat(value) / btc.preco_atual_brl) * (1 + spread)
                    );
                  }}
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix="R$ "
                  allowNegative={false}
                />
              </div>
            </Col>
            <Col md={6}>
              <div>
                <Label htmlFor="compraDiaria" className="form-Label">
                  Valor em BTC
                </Label>
                <NumericFormat
                  className="form-control"
                  placeholder="0.00000000 BTC"
                  value={btc_amount}
                  onValueChange={({ value }) => setBtcAmount(parseFloat(value))}
                  decimalSeparator="."
                  decimalScale={8}
                  suffix=" BTC"
                  fixedDecimalScale={true}
                  allowNegative={false}
                  readOnly={true}
                />
              </div>
            </Col>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Spinner height={100} width={100} />
              </div>
            ) : (
              <Col lg={12}>
                <div className="hstack gap-2 justify-content-end">
                  <Button color="light" onClick={() => setmodalOrder(false)}>
                    Fechar
                  </Button>
                  <Button
                    color="primary"
                    type="button"
                    disabled={loading}
                    onClick={createOrder}
                  >
                    Abrir Ordem
                  </Button>
                </div>
              </Col>
            )}
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};
export default ModalNewOrder;
