import React, { useState, useCallback, useEffect, useRef } from "react";
import { Col, Label, Row, TabPane, Form, Spinner } from "reactstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { NumericFormat } from "react-number-format";

const TabSocios = ({ user_id, lojista, fetchLojistaData }) => {
  const [loading, setLoading] = useState(false);
  const [depositos, setDepositos] = useState([]);
  const [retiradas, setRetiradas] = useState([]);
  const [saldos, setSaldos] = useState([]);
  let comprovanteInput = useRef(null);
  const [saldoPrincipal, setSaldoPrincipal] = useState(lojista.saldo_brl);
  const [quantidade, setQuantidade] = useState(0);
  const [tipoOperacao, setTipoOperacao] = useState("deposito");

  const userData = JSON.parse(sessionStorage.getItem("authUser") || "{}");

  const getDepositosRetiradas = useCallback(async () => {
    setLoading(true);

    const user = JSON.parse(sessionStorage.getItem("authUser") || "{}");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/client/depositos-retiradas/${user_id}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (response && response.status === 1) {
        setDepositos(response.depositos);
        setRetiradas(response.retiradas);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro ao buscar saldos");
      console.log(error);
    }

    setLoading(false);
  }, [user_id]);

  const getSaldos = useCallback(async () => {
    setLoading(true);

    const user = JSON.parse(sessionStorage.getItem("authUser") || "{}");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/saldo/${user_id}/ALL`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (response && response.status === 1) {
        setSaldos(response.data);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro ao buscar saldos");
      console.log(error);
    }

    setLoading(false);
  }, [user_id]);

  useEffect(() => {
    getDepositosRetiradas();
    getSaldos();
  }, [getDepositosRetiradas, getSaldos]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const user = JSON.parse(sessionStorage.getItem("authUser") || "{}");
    try {
      const formData = new FormData();

      formData.append("quantidade", quantidade);
      formData.append("tipoOperacao", tipoOperacao);
      formData.append("conta", 0);
      formData.append("lojista", true);

      if (
        tipoOperacao === "retirada" &&
        comprovanteInput &&
        comprovanteInput.files[0]
      ) {
        formData.append("comprovante", comprovanteInput.files[0]);
      }

      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/client/update-saldo/${user_id}`,
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
        data: formData,
      });

      if (response.status && response.status === 1) {
        toast.success(response.mensagem);
        fetchLojistaData(user_id);
        if (tipoOperacao === "deposito") {
          setSaldoPrincipal(
            parseFloat(saldoPrincipal) + parseFloat(quantidade)
          );
        } else {
          setSaldoPrincipal(
            parseFloat(saldoPrincipal) - parseFloat(quantidade)
          );
        }
        setQuantidade(0);
        comprovanteInput = null;
        setTipoOperacao("deposito");
        getDepositosRetiradas();
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro na API");
      console.log(error);
    }

    setLoading(false);
  };

  const formatCurrency = (value) => {
    if (!value) {
      return "R$ 0,00";
    }
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  const formatDate = (dateString) => {
    if (!dateString) {
      return "";
    }

    const [datePart] = dateString.split("T");
    const [year, month, day] = datePart.split("-");

    return `${day}/${month}/${year}`;
  };

  return (
    <TabPane tabId="2">
      <Form>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              padding: "20px",
            }}
          >
            <Spinner height={100} width={100} />
          </div>
        ) : (
          <>
            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <Label className="form-label">Saldo Principal: </Label>
                  <b style={{ color: "darkgreen", marginLeft: "10px" }}>
                    {formatCurrency(saldoPrincipal)}
                  </b>
                </div>
              </Col>
              {saldos.map((saldo) => {
                return (
                  <Col lg={6} key={saldo.id}>
                    <div className="mb-3">
                      <Label className="form-label">Saldo {saldo.moeda}</Label>
                      <b style={{ color: "darkgreen", marginLeft: "10px" }}>
                        {parseFloat(saldo.quantidade).toFixed(8)}
                      </b>
                    </div>
                  </Col>
                );
              })}
            </Row>
            <Row style={{ marginBottom: "40px" }}>
              {userData.data.role === "Admin" && (
                <>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label className="form-label">Valor</Label>
                      <NumericFormat
                        className="form-control"
                        placeholder="0,00"
                        value={quantidade}
                        onValueChange={({ value }) =>
                          setQuantidade(parseFloat(value))
                        }
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                        prefix="R$ "
                        fixedDecimalScale={true}
                        allowNegative={false}
                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label className="form-label">Tipo Operação</Label>
                      <select
                        className="form-control"
                        value={tipoOperacao}
                        onChange={(e) => setTipoOperacao(e.target.value)}
                      >
                        <option value="deposito">Depósito</option>
                        <option value="retirada">Retirada</option>
                      </select>
                    </div>
                  </Col>
                  {tipoOperacao === "retirada" && (
                    <Col lg={3}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Comprovante (opcional)
                        </Label>
                        <input
                          type="file"
                          className="form-control"
                          accept=".jpg,.jpeg,.png,.pdf"
                          ref={(input) => (comprovanteInput = input)}
                        />
                      </div>
                    </Col>
                  )}

                  <Col lg={12}>
                    <div className="text-end">
                      <button
                        className="btn btn-primary"
                        onClick={handleSubmit}
                      >
                        Alterar Saldo
                      </button>
                    </div>
                  </Col>
                </>
              )}
            </Row>
          </>
        )}
      </Form>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            padding: "20px",
          }}
        >
          <Spinner height={100} width={100} />
        </div>
      ) : (
        <Row>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label">Depósitos</Label>
              <div className="table-responsive">
                <table className="table table-nowrap table-hover mb-0">
                  <thead className="table-light">
                    <tr>
                      <th className="border-0">Data</th>
                      <th className="border-0">Valor</th>
                      <th className="border-0">Fonte</th>
                    </tr>
                  </thead>
                  <tbody>
                    {depositos.map((deposito) => (
                      <tr key={deposito.id}>
                        <td>{formatDate(deposito.created_at)}</td>
                        <td style={{ color: "darkgreen" }}>
                          +{formatCurrency(deposito.amount)}
                        </td>
                        <td>{deposito.fonte}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label">Retiradas</Label>
              <div className="table-responsive">
                <table className="table table-nowrap table-hover mb-0">
                  <thead className="table-light">
                    <tr>
                      <th className="border-0">Data</th>
                      <th className="border-0">Valor</th>
                      <th className="border-0">Comprovante</th>
                    </tr>
                  </thead>
                  <tbody>
                    {retiradas.map((retirada) => {
                      let url_download =
                        retirada.comprovante !== ""
                          ? `${process.env.REACT_APP_API_URL.replace(
                              "/api",
                              ""
                            )}/storage/${retirada.comprovante}`
                          : "";

                      return (
                        <tr key={retirada.id}>
                          <td>{formatDate(retirada.created_at)}</td>
                          <td style={{ color: "darkred" }}>
                            - {formatCurrency(retirada.amount)}
                          </td>
                          <td>
                            {url_download !== "" && (
                              <a
                                href={url_download}
                                download
                                className="btn btn-sm btn-success"
                              >
                                Baixar
                              </a>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </TabPane>
  );
};

export default TabSocios;
