import React from "react";
import { Card,  Spinner } from "reactstrap";

const ClientesContas = ({ formatCurrency, contasLista, coins, loading}) => {
  return (
    <React.Fragment>
      <Card>
        <div className="card-body">
          <div className="table-responsive table-card">
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  padding: "20px",
                }}
              >
                <Spinner height={100} width={100} />
              </div>
            ) : (
              <table className="table table-hover table-borderless table-centered align-middle table-nowrap mb-0">
                <thead className="text-muted bg-soft-light">
                  <tr>
                    <th>Conta</th>
                    <th>Saldo R$</th>
                    <th>Comprado</th>
                  </tr>
                </thead>
                <tbody>
                  {(contasLista || []).map((item, key) => {
                    const moedaConta =
                      coins.find((coin) => coin.moeda === item.moeda) || {};
                    const taxa_envio = moedaConta.taxa_envio / 100;
                    const comprado_moeda =
                      item.comprado_moeda * (1 - taxa_envio);
                    return (
                      <tr key={key}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="me-2">
                              <img
                                src={`https://api.fort.exchange/crypto-icons/${item.moeda.toLowerCase()}.svg`}
                                alt={`${item.moeda} icon`}
                                className="avatar-xxs"
                              />
                            </div>
                            <div>
                              <h6 className="fs-14 mb-0">{item.nome}</h6>
                            </div>
                          </div>
                        </td>
                        <td>{formatCurrency(item.saldo_brl)}</td>
                        <td>{comprado_moeda.toFixed(8)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </Card>
    </React.Fragment>
  );
};

export default ClientesContas;
