import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
} from "reactstrap";
import ParticlesAuth from "../../AuthenticationInner/ParticlesAuth";
import axios from "axios";
import { toast } from "react-toastify";
import ChatScript from "../../../Layouts/ChatScript";

import { Link } from "react-router-dom";
import logoLight from "../../../assets/images/logo-light2.png";

const ResetPassw = () => {
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");
  const [is_reset, setIsReset] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [password2Invalid, setPassword2Invalid] = useState(false);

  const errorsPassword =
    "Senha deve ter no mínimo 8 caracteres, 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial.";
  const errorsPassword2 = "As senhas devem ser iguais.";

  const validatePassword = (pass) => {
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d\s])[\S]{8,}$/;
    return re.test(String(pass));
  };

  const handlePasswordChange = (event) => {
    const val = event.target.value;
    setPassword(val);
    if (!validatePassword(val)) {
      setPasswordInvalid(true);
    } else {
      setPasswordInvalid(false);
    }
  };

  const handlePassword2Change = (event) => {
    const val = event.target.value;
    setPassword2(val);
    if (password !== password2) {
      setPassword2Invalid(true);
    } else {
      setPassword2Invalid(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const postData = {
        token,
        email,
        password,
        password_confirmation: password2,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/guest/reset-password`,
        postData
      );

      if (response.data && response.data.token) {
        const authUser = {
          status: "success",
          token: response.data.token,
          data: {
            _id: response.data.id,
            first_name: response.data.firstName,
            last_name: response.data.lastName,
            email: response.data.email,
            role: response.data.role,
            nivelKyc: response.data.nivelKyc,
            clientData: response.data.clientData,
          },
        };

        // Armazenar o authUser na sessão
        sessionStorage.setItem("authUser", JSON.stringify(authUser));
        const loginTime = new Date();
        sessionStorage.setItem("loginTime", loginTime.getTime().toString());

        authUser.data.role === "Admin"
          ? (window.location.href = "/apps-crypto-transactions")
          : (window.location.href = "/dashboard-crypto");
      } else if (response.mensagem) {
        toast.error(response.mensagem);
        console.log(response);
      } else {
        console.log(response);
        toast.error("Erro ao enviar o e-mail!");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast.error("Ocorreu um erro desconhecido.");
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenFromUrl = urlParams.get("token");
    const emailFromUrl = urlParams.get("email");
    const isReset = urlParams.get("is_reset");

    if (!tokenFromUrl || !emailFromUrl) {
      window.location.href = "/index";
    } else {
      setToken(tokenFromUrl);
      setEmail(emailFromUrl);
      setIsReset(isReset === "true");
    }
  }, []);

  document.title = is_reset
    ? "Reset de Senha | Painel Fort Exchange"
    : "Nova Conta | Painel Fort Exchange";
  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoLight} alt="" height="70" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">
                        {is_reset ? "Resetar Senha" : "Nova Conta"}
                      </h5>
                      <p className="text-muted">
                        {is_reset
                          ? "Digite a nova senha e confirme para alterar."
                          : "Digite a senha e confirme para criar sua conta."}
                      </p>
                    </div>
                    <div className="p-2 mt-4">
                      <Form onSubmit={handleSubmit} action="#">
                        <div className="mb-3">
                          <Label htmlFor="email" className="form-label">
                            {is_reset ? "Nova" : "Sua"} Senha
                          </Label>
                          <Input
                            name="password"
                            className="form-control"
                            placeholder="Digite Sua Senha"
                            type="password"
                            onChange={handlePasswordChange}
                            onBlur={handlePasswordChange}
                            value={password}
                            invalid={passwordInvalid}
                          />
                          {passwordInvalid && (
                            <FormFeedback type="invalid">
                              {errorsPassword}
                            </FormFeedback>
                          )}
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="email" className="form-label">
                            Confirme a Senha
                          </Label>
                          <Input
                            name="password_confirmation"
                            className="form-control"
                            placeholder="Confirme a Senha"
                            type="password"
                            onChange={handlePassword2Change}
                            onBlur={handlePassword2Change}
                            value={password2}
                            invalid={password2Invalid}
                          />
                          {password2Invalid && (
                            <FormFeedback type="invalid">
                              {errorsPassword2}
                            </FormFeedback>
                          )}
                        </div>

                        <div className="mt-4">
                          <Button
                            color="warning"
                            disabled={loading}
                            className="btn btn-warning w-100"
                            type="submit"
                          >
                            {loading
                              ? "Enviando..."
                              : is_reset
                              ? "Resetar Senha"
                              : "Criar Conta"}
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>

                <div className="mt-4 text-center">
                  <p className="mb-0">
                    Voltar para a{" "}
                    <Link
                      to="/auth-signin-basic"
                      className="fw-bold text-primary text-decoration-underline"
                    >
                      {" "}
                      página de Login{" "}
                    </Link>{" "}
                  </p>
                </div>
              </Col>
            </Row>
            <ChatScript />
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default ResetPassw;
