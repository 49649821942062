import React, { useState, useCallback, useEffect } from "react";
import { Col, Input, Label, Row, TabPane, Spinner, Button } from "reactstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { NumericFormat } from "react-number-format";

const TabConfig = () => {
  const [loading, setLoading] = useState(false);
  const [carteiraEther, setCarteiraEther] = useState("");
  const [carteiraBitcoin, setCarteiraBitcoin] = useState("");
  const [taxaCompraVenda, setTaxaCompraVenda] = useState(0);
  const [taxaDCA, setTaxaDCA] = useState(0);
  const [fecthData, setFetchData] = useState(false); 

  const fetchConfigData = useCallback(async () => {
    setLoading(true);
    const token = JSON.parse(sessionStorage.getItem("authUser"))?.token;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/config-system`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status && response.status === 1) {
        response.dados.forEach((item) => {
          switch (item.campo) {
            case "wallet-ether":
              setCarteiraEther(item.value);
              break;
            case "wallet-hybrid":
              setCarteiraBitcoin(item.value);
              break;
            case "taxa_compra_venda":
              setTaxaCompraVenda(parseFloat(item.value));
              break;
            case "taxa_dca":
              setTaxaDCA(parseFloat(item.value));
              break;
            default:
              break;
          }
        });
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro ao buscar dados!");
      console.log(error);
    } finally {
      setLoading(false);
      setFetchData(true);
    }
  }, []);

  useEffect(() => {
    if(!fecthData) fetchConfigData();
  }, [fetchConfigData, fecthData]);

  const handleEdit = async () => {
    const token = JSON.parse(sessionStorage.getItem("authUser"))?.token;
    try {
      const payload = {
        "wallet-ether": carteiraEther,
        "wallet-hybrid": carteiraBitcoin,
        taxa_compra_venda: taxaCompraVenda,
        taxa_dca: taxaDCA,
      };

      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/config-system`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 1) {
        toast.success("Configurações atualizadas com sucesso.");
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro ao atualizar as configurações.");
      console.log(error);
    }
  };

  return (
    <TabPane tabId="1">
      {loading ? (
        <Spinner />
      ) : (
        <Row>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label">Carteira Ethereum</Label>
              <Input
                type="text"
                className="form-control"
                value={carteiraEther}
                onChange={(e) => setCarteiraEther(e.target.value)}
                maxLength={42}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label">Carteira Bitcoin</Label>
              <Input
                type="text"
                className="form-control"
                value={carteiraBitcoin}
                onChange={(e) => setCarteiraBitcoin(e.target.value)}
                maxLength={42}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label">Taxa Compra/Venda</Label>
              <NumericFormat
                className="form-control"
                placeholder="0,00 %"
                value={taxaCompraVenda}
                onValueChange={({ value }) => setTaxaCompraVenda(parseFloat(value))}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                suffix="%"
                fixedDecimalScale={true}
                allowNegative={false}
              />
              <span className="text-muted">
                Taxa cobrada na compra e venda de criptomoedas.
              </span>
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label">Taxa DCA</Label>
              <NumericFormat
                className="form-control"
                placeholder="0,00 %"
                value={taxaDCA}
                onValueChange={({ value }) => setTaxaDCA(parseFloat(value))}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                suffix="%"
                fixedDecimalScale={true}
                allowNegative={false}
              />
              <span className="text-muted">
                Taxa cobrada na compra de criptomoedas via DCA.
              </span>
            </div>
          </Col>
          <Col lg={12}>
            <div className="mb-3">
              <Button
                color="primary"
                className="btn mb-4"
                onClick={handleEdit}
                disabled={loading}
              >
                Editar
              </Button>
            </div>
          </Col>
        </Row>
      )}
    </TabPane>
  );
};

export default TabConfig;
