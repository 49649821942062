import React, { useEffect, useState, useCallback } from "react";
import { Container, Row } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import AllOrders from "./AllOrders";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

const Transacoes = () => {
  const [transacoes, setTransacoes] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchTransactions = useCallback(async (moeda, data_min, data_max) => {
    setLoading(true);
    const user = JSON.parse(sessionStorage.getItem("authUser"));
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/cripto-td/transacoes/0`,
        {
          params: {
            moeda,
            data_min,
            data_max,
          },
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (response.status && response.status === 1) {
        setLoading(false);
        return response.data;
      } else {
        toast.error(response.mensagem, {
          position: "top-right",
          hideProgressBar: true,
          closeOnClick: false,
          className: "bg-danger text-white",
        });
      }

      setLoading(false);
      return [];
    } catch (error) {
      toast.error("Erro ao buscar operações: " + error, {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: false,
        className: "bg-danger text-white",
      });
      setLoading(false);
      return [];
    }
  }, []);

  const location = useLocation();
  const { moeda, data_min, data_max } = queryString.parse(location.search);

  useEffect(() => {
    const fetchData = async () => {
      const retorno = await fetchTransactions(moeda, data_min, data_max);
      setTransacoes(retorno);
    };

    fetchData();
  }, [fetchTransactions, moeda, data_min, data_max]);

  document.title = "Transações | Painel Fort Exchange";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Transações" pageTitle="Transações" />
          <Row>
            <AllOrders orderList={transacoes} loading={loading} />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Transacoes;
