import React, {  useMemo,  } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Spinner } from "reactstrap";
import parse from 'html-react-parser';

import TableContainer from "../../../Components/Common/TableContainer";
import moment from "moment";

const AllOrders = ({ orderList, loading, user_id }) => {
  function formatName(name) {
    let lowerCaseName = name.toLowerCase();
    let properName = lowerCaseName.replace(
      /\b\w/g,
      function (letter, index, fullText) {
        const previousLetter = fullText[index - 1];
        const isWordAfterApostrophe = previousLetter === "'";
        if (isWordAfterApostrophe) {
          return letter;
        }
        const word = fullText.slice(index).match(/^\w+/)[0];
        const lowerCaseWords = ["de", "da", "do", "dos", "das"];
        if (lowerCaseWords.includes(word)) {
          return letter;
        }
        return letter.toUpperCase();
      }
    );

    // Converte a letra após um apóstrofo para maiúscula
    properName = properName.replace(/'\w/g, function (letter) {
      return letter.toUpperCase();
    });

    return properName;
  }
  const columns = useMemo(() => {
    const initialColumns = [
      {
        Header: "Data",
        accessor: "created_at",
        filterable: true,
        Cell: (cell) => {
          const date = moment(cell.value);
          return (
            <>
              <b>{date.format("DD/MM/YYYY")} </b>
              <small className="text-muted">{date.format("HH:mm")}</small>{" "}
            </>
          );
        },
      },
      {
        Header: "Nome",
        accessor: "user.name",
        filterable: true,
        Cell: (cell) => (
          <Link to={`/clientes/${cell.row.original.user_id}`} target="_self">
            <b>{formatName(cell.value)}</b>
          </Link>
        ),
      },
      {
        Header: "Mensagem de log",
        accessor: "mensagem",
        filterable: true,
        Cell: (cell) => (
          <>
            <div>{parse(cell.value)}</div>
          </>
        ),
      },
    ];
    return initialColumns;
  }, []);
  return (
    <React.Fragment>
      <Col lg={12}>
        <Card>
          <CardHeader className="d-flex align-items-center border-0">
            <h5 className="card-title mb-0 flex-grow-1">
              {user_id === 1 ? "Logs do Sistema" : "Logs do Usuário"}
            </h5>
          </CardHeader>
          <CardBody>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Spinner height={100} width={100} />
              </div>
            ) : (
              <TableContainer
                columns={columns}
                data={orderList || []}
                isGlobalFilter={true}
                isAddUserList={false}
                customPageSize={20}
                className="custom-header-css"
                divClass="table-responsive table-card mb-4"
                tableClass="align-middle table-nowrap mb-0"
                theadClass="table-light table-nowrap"
                thClass="table-light text-muted"
              />
            )}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default AllOrders;
