// Lojistas.js
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import TabelaLojistas from "./TabelaLojistas";
import LojistaDados from "./LojistaDados";

const Lojistas = () => {
  const { user_id } = useParams();
  const user = JSON.parse(sessionStorage.getItem("authUser") || "{}");

  return user_id ? (
    <LojistaDados user_id={user_id} />
  ) : user.data.role === "Lojista" ? (
    <LojistaDados user_id={user.data._id} />
  ) : (
    <TabelaLojistas />
  );
};

export default Lojistas;
