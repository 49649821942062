import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import {
  Col,
  Form,
  Input,
  Label,
  Row,
  TabPane,
  FormFeedback,
  Button,
} from "reactstrap";
import FeatherIcon from "feather-icons-react";
import { toast } from "react-toastify";
import "moment/locale/pt-br";

const TabPerfil = () => {
  const [loading, setLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [link, setLink] = useState("");
  const [codigo, setCodigo] = useState("");
  const [devices, setDevices] = useState(null);
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [password2Invalid, setPassword2Invalid] = useState(false);
  const [cliente, setCliente] = useState(null);
  const [mostrarSaldo, setMostrarSaldo] = useState(false);
  const [indicados, setIndicados] = useState(0);
  const [enviarEmail, setEnviarEmail] = useState(false);

  const errorsPassword =
    "Senha deve ter no mínimo 8 caracteres, 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial.";
  const errorsPassword2 = "As senhas devem ser iguais.";

  const validatePassword = (pass) => {
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d\s])[\S]{8,}$/;
    return re.test(String(pass));
  };

  const fetchClienteData = useCallback(async (user_id) => {
    const token = JSON.parse(sessionStorage.getItem("authUser"))?.token;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/client/get-client-data/${user_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status && response.status === 1) {
        setCliente(response.clientData);
        setIndicados(response.indicados);
        setMostrarSaldo(
          response.clientData.visualizar_saldo === 1 ? true : false
        );
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro ao buscar dados.");
      console.log(error);
    }
  }, []);

  const setEnviarEmailsCliente = async (cliente_id) => {
    setLoading(true);

    let clientData = cliente;
    const user = JSON.parse(sessionStorage.getItem("authUser") || "{}");

    console.log(enviarEmail);

    const data = {
      enviar_email: enviarEmail,
    }

    try {
      const response = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_URL}/client/set-enviar-emails/${cliente_id}`,
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
        data: data
      });

      if (response.status && response.status === 1) {
        toast.success(response.mensagem);
        clientData.envio_emails = !clientData.envio_emails;
        setCliente(clientData);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro na API");
      console.log(error);
    }

    setLoading(false);
  };

  const toggleVisualizarSaldo = async (e) => {
    e.preventDefault();
    setLoading(true);

    let clientData = cliente;
    const user = JSON.parse(sessionStorage.getItem("authUser") || "{}");

    try {
      const response = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_URL}/client/toggle-visualizar-saldo/${user.data._id}`,
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (response.status && response.status === 1) {
        toast.success(response.mensagem);
        clientData.visualizar_saldo = !clientData.visualizar_saldo;
        setCliente(clientData);
        setMostrarSaldo(!mostrarSaldo);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro na API");
      console.log(error);
    }

    setLoading(false);
  };

  const handlePasswordChange = (event) => {
    const val = event.target.value;
    setPassword(val);
    if (!validatePassword(val)) {
      setPasswordInvalid(true);
    } else {
      setPasswordInvalid(false);
    }
  };

  const handlePassword2Change = (event) => {
    const val = event.target.value;
    setPassword2(val);
    if (password !== password2) {
      setPassword2Invalid(true);
    } else {
      setPassword2Invalid(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const user = JSON.parse(sessionStorage.getItem("authUser"));

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/guest/change-password`,
        {
          current_password: oldPassword,
          password: password,
          password_confirmation: password2,
        },
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );

      if (response.status && response.status === 1) {
        toast.success(response.mensagem);
        setPassword("");
        setPassword2("");
        setOldPassword("");
      } else if (response.mensagem) {
        toast.error(response.mensagem);
        console.log(response);
      } else {
        toast.error("Erro ao alterar a senha!");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);

      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Ocorreu um erro desconhecido:" + error.message);
      }
    }
  };

  const fetchDevicesLogin = useCallback(async () => {
    setLoading(true);
    const user = JSON.parse(sessionStorage.getItem("authUser"));
    setCodigo(
      user.data.clientData.codigo_parceiro
        ? user.data.clientData.codigo_parceiro
        : ""
    );
    setLink(
      user.data.clientData.codigo_parceiro
        ? process.env.REACT_APP_PAINEL_URL +
            "nova-conta?codigo_parceiro=" +
            user.data.clientData.codigo_parceiro
        : ""
    );
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/logs/login-devices/${user.data._id}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (response.data && response.status && response.status === 1) {
        return response.data;
      } else if (response.mensagem) {
        toast.error(response.mensagem);
        console.log(response);
        return [];
      } else if (response.message) {
        toast.error(response.message);
        console.log(response);
        return [];
      } else {
        toast.error("Erro ao buscar dispositivos");
        console.log(response);
        return [];
      }
    } catch (error) {
      toast.error("Erro ao buscar dispositivos: " + error);
      return [];
    } finally {
      setLoading(false);
    }
  }, []);

  const changeCodigo = async (e) => {
    e.preventDefault();
    setLoading(true);
    const user = JSON.parse(sessionStorage.getItem("authUser"));

    if (codigo === user.data.clientData.codigo_parceiro) {
      toast.error("O código não pode ser igual ao atual!");
      setLoading(false);
      return;
    }
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/client/change-client-code/${user.data._id}`,
        {
          codigo_parceiro: codigo,
        },
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );

      if (response.status && response.status === 1) {
        toast.success(response.mensagem);
        user.data.clientData.codigo_parceiro = codigo;
        sessionStorage.setItem("authUser", JSON.stringify(user));
        setLink(
          process.env.REACT_APP_PAINEL_URL +
            "nova-conta?codigo_parceiro=" +
            codigo
        );
      } else if (response.mensagem) {
        toast.error(response.mensagem);
        console.log(response);
      } else {
        toast.error("Erro ao mudar o código!");
        console.log(response);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
        console.log(error);
      } else {
        toast.error("Ocorreu um erro desconhecido.");
        console.log(error);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const retorno = await fetchDevicesLogin();
      setDevices(retorno);
    };

    const fetchCliente = async () => {
      const user = JSON.parse(sessionStorage.getItem("authUser"));
      await fetchClienteData(user.data._id);
    };

    if (!devices) fetchData();
    if (!cliente) fetchCliente();
  }, [fetchDevicesLogin, fetchClienteData, cliente, devices]);

  return (
    <TabPane tabId="2">
      <div className="mt-4 mb-3 border-bottom pb-2">
        <h5 className="card-title">Link de Afiliado</h5>
      </div>
      <Row className="g-2">
        <Col lg={4}>
          <div>
            <Label htmlFor="link_afiliado" className="form-label">
              Seu Link
            </Label>
            <Input
              type="text"
              className="form-control"
              id="link_afiliado"
              value={link}
              readOnly
            />
          </div>
          <small className="text-muted">
            Você tem {indicados} indicados com seu código.
          </small>
        </Col>
        <Col lg={2}>
          <div style={{ marginTop: "26px" }}>
            <Button
              id="copyTooltip"
              color="primary"
              onClick={() => {
                navigator.clipboard.writeText(link);
                toast.success("QR Code copiado para a área de transferência");
              }}
              className="btn"
            >
              <FeatherIcon
                icon="copy"
                style={{ color: "white", height: "10px" }}
              />
              Copiar Link
            </Button>
          </div>
        </Col>
        <Col lg={4}>
          <div>
            <Label htmlFor="codigo_afiliado" className="form-label">
              Mudar Código
            </Label>
            <Input
              type="text"
              id="codigo_afiliado"
              className="form-control"
              value={codigo}
              onChange={(e) => setCodigo(e.target.value)}
            />
          </div>
        </Col>
        <Col lg={2}>
          <div style={{ marginTop: "26px" }}>
            <Button
              id="codigo"
              color="primary"
              onClick={changeCodigo}
              className="btn"
              disabled={loading}
            >
              {loading ? "Enviando..." : "Mudar"}
            </Button>
          </div>
        </Col>
      </Row>
      {/* <div className="mt-4 mb-3 border-bottom pb-2">
        <h5 className="card-title">Configurações</h5>
      </div>
      <Form>
        <Row className="g-2">
          <Col lg={4}>
            <div className="form-check form-switch mb-3">
              <Input
                className="form-check-input"
                type="checkbox"
                role="switch"
                checked={mostrarSaldo}
                onChange={(e) => toggleVisualizarSaldo(e)}
              />
              <Label className="form-check-label">
                Esconder saldo na Página Principal?
              </Label>
            </div>
          </Col>
        </Row>
      </Form> */}
      <div className="mt-4 mb-3 border-bottom pb-2">
        <h5 className="card-title">Mudar Senha</h5>
      </div>
      <Form>
        <Row className="g-2">
          <Col lg={4}>
            <div>
              <Label htmlFor="oldpasswordInput" className="form-label">
                Senha Antiga*
              </Label>
              <Input
                type="password"
                className="form-control"
                id="oldpasswordInput"
                placeholder="Entre a senha atual"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                disabled={loading}
              />
            </div>
          </Col>

          <Col lg={4}>
            <div>
              <Label htmlFor="newpasswordInput" className="form-label">
                Nova Senha*
              </Label>
              <Input
                name="password"
                className="form-control"
                placeholder="Digite Sua Senha"
                type="password"
                onChange={handlePasswordChange}
                onBlur={handlePasswordChange}
                value={password}
                invalid={passwordInvalid}
              />
              {passwordInvalid && (
                <FormFeedback type="invalid">{errorsPassword}</FormFeedback>
              )}
            </div>
          </Col>

          <Col lg={4}>
            <div>
              <Label htmlFor="confirmpasswordInput" className="form-label">
                Repita Nova Senha*
              </Label>
              <Input
                name="password_confirmation"
                className="form-control"
                placeholder="Confirme a Senha"
                type="password"
                onChange={handlePassword2Change}
                onBlur={handlePassword2Change}
                value={password2}
                invalid={password2Invalid}
              />
              {password2Invalid && (
                <FormFeedback type="invalid">{errorsPassword2}</FormFeedback>
              )}
            </div>
          </Col>

          <Col lg={12}>
            <div className="text-end">
              <button
                type="button"
                className="btn btn-success"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? "Atualizando..." : "Mudar Senha"}
              </button>
            </div>
          </Col>
        </Row>
      </Form>
      <div className="mt-4 mb-3 border-bottom pb-2">
        <h5 className="card-title">Privacidade / E-mails</h5>
      </div>
      <Row className="g-2">
        <Col lg={4}>
          <div className="form-check mb-3">
            <Input
              className="form-check-input"
              type="checkbox"
              checked={enviarEmail}
              onChange={() => setEnviarEmail(!enviarEmail)}
            />
            <Label className="form-check-label">
              Receber e-mails de notificação?&nbsp;&nbsp;&nbsp;&nbsp;<button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={() => setEnviarEmailsCliente(cliente?.id)}
              disabled={loading}
            >
              {loading
                ? "Atualizando..."
                : "Aplicar"}
            </button>
            </Label>
          </div>
        </Col>
      </Row>
      <div className="mt-4 mb-3 border-bottom pb-2">
        <h5 className="card-title">Histórico de Login</h5>
      </div>
      {devices?.map((device, index) => {
        const date = new Date(device.updated_at);
        const formattedDate = `${date.getDate()} de ${date.toLocaleString(
          "pt-BR",
          { month: "long" }
        )} às ${date.getHours()}:${("0" + date.getMinutes()).slice(-2)}`;

        return (
          <div key={index} className="d-flex align-items-center mb-3">
            <div className="flex-shrink-0 avatar-sm">
              <div className="avatar-title bg-light text-primary rounded-3 fs-18">
                <i className="ri-smartphone-line"></i>
              </div>
            </div>
            <div className="flex-grow-1 ms-3">
              <h6>{device.os}</h6>
              <p className="text-muted mb-0">
                {device.browser}, {formattedDate}
              </p>
            </div>
          </div>
        );
      })}
    </TabPane>
  );
};

export default TabPerfil;
