import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Label, Input } from "reactstrap";
import { toast } from "react-toastify";

const GasFeeCalculator = () => {
  const [gasPrice, setGasPrice] = useState(null);
  const [gasCost, setGasCost] = useState("");
  const [ethPriceUSD, setEthPriceUSD] = useState(2300);
  const [error, setError] = useState(null);

  const fetchGasPrice = useCallback(async () => {
    // setLoading(true);
    const user = JSON.parse(sessionStorage.getItem("authUser"));
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/operacao/get-gas-price`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (response.status && response.status === 1) {
        setGasPrice(response.data.ProposeGasPrice);
        const cost = 21000 * response.data.ProposeGasPrice * Math.pow(10, -9); 
        const costUSD = cost * ethPriceUSD;
        setGasCost(costUSD.toFixed(2) + " USD");
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro ao buscar usuários.");
      console.log(error);
    } finally {
      //   setLoading(false);
    }
  }, [ethPriceUSD]);

  useEffect(() => {
    fetchGasPrice();
  }, [fetchGasPrice]);

  return (
    <div>
      {error && <p>Erro: {error}</p>}
      {gasPrice ? (
        <div>
          <p><b>Preço Atual do GAS:</b> {gasPrice} Gwei&nbsp;&nbsp; | &nbsp;&nbsp;<b>Custo de GAS para Transação Simples:</b> {gasCost}</p>
        </div>
      ) : (
        <p>Carregando preço do GAS...</p>
      )}
    </div>
  );
};

export default GasFeeCalculator;
