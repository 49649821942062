import React, { useMemo, useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Spinner } from "reactstrap";
import TableContainer from "../../../Components/Common/TableContainer";
import { Type } from "./OrderCol";
import { format, parseISO, set } from "date-fns";
import { ptBR } from "date-fns/locale";
import axios from "axios";
import { toast } from "react-toastify";
import FeatherIcon from "feather-icons-react";

const AllOrders = ({ orderList, loading }) => {
  const [coins, setCoins] = useState([]);

  const copyToClipboard = async (text) => {
    if (!navigator.clipboard) {
      toast.error("API de Clipboard não suportada no seu navegador.", {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: false,
        className: "bg-danger text-white",
      });
      return;
    }
    try {
      await navigator.clipboard.writeText(text);
      toast.success("Copiado para a área de transferência!", {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: false,
        className: "bg-success text-white",
      });
    } catch (err) {
      toast.error("Falha ao copiar o texto: ", err, {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: false,
        className: "bg-danger text-white",
      });
    }
  };

  const fetchPrices = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/moedas/precos`
      );

      setCoins(response.data);
    } catch (error) {
      toast.error("Houve um erro ao buscar os preços das moedas! " + error);
    }
  }, []);

  useEffect(() => {
    fetchPrices();
  }, [fetchPrices]);

  const formatCurrency = (value) => {
    if (!value) {
      return "R$ 0,00";
    }
    try {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    } catch (error) {
      return "R$ 0,00";
    }
  };

  function splitDateTime(dateTimeStr) {
    const dateTime = parseISO(dateTimeStr);

    const data = format(dateTime, "dd MMM, yyyy", { locale: ptBR });
    const hora = format(dateTime, "hh:mm a");

    return {
      data,
      hora,
    };
  }
  const columns = useMemo(
    () => [
      {
        Header: "Data",
        accessor: "created_at",
        Cell: (cell) => (
          <>
            {splitDateTime(cell.value).data}{" "}
            <small className="text-muted">10:00</small>
          </>
        ),
      },
      {
        Header: "Moeda",
        accessor: "moeda",
        Cell: (cell) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <img
                  src={`https://api.fort.exchange/crypto-icons/${cell.row.original.moeda.toLowerCase()}.svg`}
                  alt=""
                  className="avatar-xxs"
                />
              </div>
              <Link to="#" className="currency_name flex-grow-1 ms-2 text-body">
                {cell.value}
              </Link>
            </div>
            <span className="text-muted">
              <b>Conta: </b>
              {cell.row.original.conta.nome}
            </span>
          </>
        ),
      },
      {
        Header: "Tipo",
        accessor: "tipo_operacao",
        Cell: (cellProps) => {
          return <Type {...cellProps} />;
        },
      },
      {
        Header: "Comprado",
        filterable: false,
        Cell: (cell) => {
          return <>{formatCurrency(cell.row.original.valor_brl_original)}</>;
        },
      },
      {
        Header: "Quantidade",
        accessor: "token_enviado",
        Cell: (cell) => {
          const moeda = coins.find(
            (item) => item.moeda === cell.row.original.moeda
          );
          const comprado_moeda = parseFloat(cell.row.original.token_enviado).toFixed(moeda?.decimais);

          return (
            <>
              <h6
                className={"text-info amount mb-1"}
                style={{ cursor: "pointer" }}
                onClick={() => copyToClipboard(comprado_moeda)}
                title="Clique para copiar o valor"
              >
                {comprado_moeda}{" "}
                <FeatherIcon
                  icon="copy"
                  style={{ color: "#ccc", height: "15px" }}
                />
              </h6>
            </>
          );
        },
      },
      {
        Header: "Cotação no Dia",
        accessor: "cotacao_fort",
        filterable: false,
        Cell: (cell) => {
          return <>{formatCurrency(cell.value)}</>;
        },
      },
    ],
    [coins]
  );
  return (
    <React.Fragment>
      <Col lg={12}>
        <Card>
          <CardHeader className="d-flex align-items-center border-0">
            <h5 className="card-title mb-0 flex-grow-1">Transações</h5>
            <div className="flex-shrink-0">
              <div className="flax-shrink-0 hstack gap-2">
                {/* <button className="btn btn-primary">Nova Transação</button> */}
              </div>
            </div>
          </CardHeader>
          <CardBody>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  padding: "20px",
                }}
              >
                <Spinner height={100} width={100} />
              </div>
            ) : (
              <TableContainer
                columns={columns}
                data={orderList || []}
                isGlobalFilter={true}
                isAddUserList={false}
                customPageSize={10}
                className="custom-header-css"
                divClass="table-responsive table-card mb-1"
                tableClass="align-middle table-nowrap"
                theadClass="table-light text-muted"
              />
            )}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default AllOrders;
