import React, { useEffect, useState, useCallback } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
} from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import platform from "platform";
import axios from "axios";
import { toast } from "react-toastify";
import ChatScript from "../../Layouts/ChatScript";

import { Link } from "react-router-dom";

import logoLight from "../../assets/images/logo-light2.png";

import withRouter from "../../Components/Common/withRouter";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordShow, setPasswordShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [passwordInvalid, setPasswordInvalid] = useState(false);

  const [code, setCode] = useState("");
  const [codeWindow, setCodeWindow] = useState(false);
  const [lembrar, setLembrar] = useState(false);

  const esquecerLembrarSenha = useCallback((lembrarSenha, email, pass) => {
    if (!lembrarSenha) {
      localStorage.removeItem("dadosLogin");
    } else {
      const passBase64 = btoa(pass);
      localStorage.setItem(
        "dadosLogin",
        JSON.stringify({ email: email, password: passBase64 })
      );
    }

    setLembrar(lembrarSenha);
    console.log(lembrarSenha);
  }, []);

  const errorsEmail = "E-mail inválido ou vazio.";
  const errorsPassword = "Senha inválida ou vazia.";

  useEffect(() => {
    const authUser = sessionStorage.getItem("authUser");
    const loginTime = sessionStorage.getItem("loginTime");

    if (authUser && loginTime) {
      const user = JSON.parse(authUser).data;
      const currentTime = new Date();
      const sessionTime = new Date(loginTime);

      if (currentTime.getTime() - sessionTime.getTime() <= 60 * 60 * 1000) {
        user.role === "Admin"
          ? (window.location.href = "/apps-crypto-transactions")
          : (window.location.href = "/dashboard-crypto");
      }
    }
  }, []);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailChange = (event) => {
    const val = event.target.value;
    setEmail(val);
    if (!validateEmail(val)) {
      setEmailInvalid(true);
    } else {
      setEmailInvalid(false);
    }
  };

  const handlePasswordChange = (event) => {
    const val = event.target.value;
    setPassword(val);
    if (val === "") {
      setPasswordInvalid(true);
    } else {
      setPasswordInvalid(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { name: browser, os } = platform.parse(navigator.userAgent);

    try {
      const postData = {
        email,
        password,
        code,
        browser,
        os: os.family,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/guest/login`,
        postData
      );

      if (response.sucesso && response.sucesso === 1) {
        const data = response.data;
        if (data) {
          const authUser = {
            status: "success",
            token: response.data.token,
            data: {
              _id: response.data.id,
              first_name: response.data.firstName,
              last_name: response.data.lastName,
              email: response.data.email,
              role: response.data.role,
              nivelKyc: response.data.nivelKyc,
              clientData: response.data.clientData,
              lojistaData: response.data.lojista,
            },
          };

          // Armazenar o authUser na sessão
          sessionStorage.setItem("authUser", JSON.stringify(authUser));
          const loginTime = new Date();
          sessionStorage.setItem("loginTime", loginTime.getTime().toString());
          sessionStorage.setItem("show_warning", "true");

          authUser.data.role === "Admin"
            ? (window.location.href = "/compra-diaria")
            : (window.location.href = "/dashboard-crypto");
        } else setCodeWindow(true);
      } else if (response.mensagem) {
        toast.error(response.mensagem);
        console.log(response);
      } else {
        toast.error("Erro ao fazer o login!");
        console.log(response);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);

      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMsg(error.response.data.message);
      } else {
        setErrorMsg("Ocorreu um erro desconhecido:" + error.message);
      }
    }
  };

  useEffect(() => {
    const dadosLogin = JSON.parse(localStorage.getItem("dadosLogin"));
    if (
      dadosLogin &&
      !lembrar &&
      dadosLogin.email !== "" &&
      dadosLogin.passBase64 !== ""
    ) {
      setEmail(dadosLogin.email);
      setPassword(atob(dadosLogin.password));
      setLembrar(true);
    }
  }, [lembrar]);

  document.title = "Login | Painel Fort Exchange";
  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoLight} alt="" height="70" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Bem-Vindo!</h5>
                      {codeWindow ? (
                        <p className="text-muted"> Digite o código 2FA.</p>
                      ) : (
                        <p className="text-muted">
                          Faça o login para entrar no painel.
                        </p>
                      )}
                    </div>
                    {errorMsg && <Alert color="danger"> {errorMsg} </Alert>}
                    <div className="p-2 mt-4">
                      <Form onSubmit={handleSubmit} action="#">
                        {codeWindow ? (
                          <div className="mb-3">
                            <Label htmlFor="2fa">
                              Digite o código de verificação
                            </Label>
                            <Input
                              type="text"
                              className="mt-2 text-center"
                              id="2fa"
                              value={code}
                              onChange={(e) =>
                                setCode(e.target.value.replace(/\D/g, ""))
                              }
                              maxLength={6}
                            />
                          </div>
                        ) : (
                          <>
                            <div className="mb-3">
                              <Label htmlFor="email" className="form-label">
                                E-mail
                              </Label>
                              <Input
                                name="email"
                                className="form-control"
                                placeholder="Digite aqui"
                                type="email"
                                onChange={handleEmailChange}
                                onBlur={handleEmailChange}
                                value={email}
                                invalid={emailInvalid}
                              />
                              {emailInvalid && (
                                <FormFeedback type="invalid">
                                  {errorsEmail}
                                </FormFeedback>
                              )}
                            </div>

                            <div className="mb-3">
                              <div className="float-end">
                                <Link
                                  to="/auth-pass-reset-basic"
                                  className="text-muted"
                                >
                                  Esqueceu a senha?
                                </Link>
                              </div>
                              <Label
                                className="form-label"
                                htmlFor="password-input"
                              >
                                Senha
                              </Label>
                              <div className="position-relative auth-pass-inputgroup mb-3">
                                <Input
                                  name="password"
                                  value={password}
                                  type={passwordShow ? "text" : "password"}
                                  className="form-control pe-5"
                                  placeholder="Digite aqui"
                                  onChange={handlePasswordChange}
                                  onBlur={handlePasswordChange}
                                  invalid={passwordInvalid}
                                />
                                {passwordInvalid && (
                                  <FormFeedback type="invalid">
                                    {errorsPassword}
                                  </FormFeedback>
                                )}
                                <button
                                  className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                                  type="button"
                                  id="password-addon"
                                  onClick={() => setPasswordShow(!passwordShow)}
                                >
                                  <i className="ri-eye-fill align-middle"></i>
                                </button>
                              </div>
                            </div>

                            <div className="mb-3">
                              <div className="form-check">
                                <Input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customControlInline"
                                  checked={lembrar}
                                  onChange={(e) =>
                                    esquecerLembrarSenha(
                                      e.target.checked,
                                      email,
                                      password
                                    )
                                  }
                                  disabled={email === "" || password === ""}
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="customControlInline"
                                >
                                  Lembre-se de mim
                                </Label>
                              </div>
                            </div>
                          </>
                        )}

                        <div className="mt-4">
                          <Button
                            color="success"
                            disabled={loading}
                            className="btn btn-success w-100"
                            type="submit"
                          >
                            {loading ? "Carregando..." : "Entrar"}
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-4 text-center">
                  <p className="mb-0">
                    Não é cliente?{" "}
                    <Link
                      to="/nova-conta"
                      className="fw-bold text-primary text-decoration-underline"
                    >
                      {" "}
                      Crie aqui sua conta
                    </Link>{" "}
                  </p>
                </div>
              </Col>
            </Row>
            <ChatScript />
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(Login);
