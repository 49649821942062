import React, { useState, useEffect, useCallback } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  CardHeader,
  Row,
  Button,
  TabPane,
  Form,
  TabContent,
} from "reactstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { NumericFormat } from "react-number-format";
import Select from "react-select";
import GasFeeCalculator from "./GasFeeCalculator";

const Operacoes = () => {
  const [loading, setLoading] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(true);

  const [cliente, setCliente] = useState(null);
  const [fornecedor, setFornecedor] = useState(null);
  const [listaMoedas, setListaMoedas] = useState([
    {
      value: 0,
      label: "BRL",
      preco_atual_brl: 1,
      rede: "BRL",
      decimais: 2,
    },
  ]);
  const [moedas, setMoedas] = useState([]);
  const [moedaSaida, setMoedaSaida] = useState(null);
  const [total, setTotal] = useState(null);
  const [moedaDestino, setMoedaDestino] = useState(null);
  const [quantidade, setQuantidade] = useState(null);
  const [taxaFort, setTaxaFort] = useState(null);
  const [usdBrl, setUsdBrl] = useState(null);
  const [moedaCotacao, setMoedaCotacao] = useState("BRL");
  const [cotacao, setCotacao] = useState(0);
  const [cotacaoOriginal, setCotacaoOriginal] = useState(0);
  const [cotacaoOriginalFort, setCotacaoOriginalFort] = useState(0);
  const [taxaCompraVenda, setTaxaCompraVenda] = useState(1.45);
  const [taxaCompraVendaOriginal, setTaxaCompraVendaOriginal] = useState(1.45);
  const [taxaRede, setTaxaRede] = useState(0);
  const [converter, setConverter] = useState(false);

  const [saldoBtc, setSaldoBtc] = useState(0);
  const [saldoEth, setSaldoEth] = useState(0);
  const [saldoMatic, setSaldoMatic] = useState(0);
  const [saldoUsdt, setSaldoUsdt] = useState(0);

  const [listaClientes, setListaClientes] = useState([
    {
      options: [],
    },
  ]);

  const [listaFornecedores, setListaFornecedores] = useState([
    {
      options: [
        {
          value: 1,
          label: "Fort Exchange",
        },
      ],
    },
  ]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const clienteData = JSON.parse(sessionStorage.getItem("authUser"));

      let valueFields = {
        user_id: cliente.value,
        fornecedor: fornecedor.value,
        moeda_saida: moedas.find((element) => element.id === moedaSaida.value)
          .moeda,
        moeda_destino: moedas.find(
          (element) => element.id === moedaDestino.value
        ).moeda,
        moeda_base: moedaCotacao,
        quantidade: quantidade,
        total: total,
        cotacao: cotacao,
        taxa_fort: taxaFort,
        taxa_rede_brl: taxaRede,
        converter: converter,
        taxa_fort_percentage: taxaCompraVenda,
        rede: moedas.find((element) => element.id === moedaSaida.value).rede,
      };

      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/operacao`,
        headers: {
          Authorization: `Bearer ${clienteData.token}`,
        },
        data: valueFields,
      });

      if (response.status && response.status === 1) {
        toast.success(response.mensagem);
        //zerar os campos input
        setCliente(null);
        setFornecedor(null);
        setMoedaSaida(null);
        setMoedaDestino(null);
        setQuantidade(null);
        setTotal(null);
        setTaxaFort(null);
        setTaxaRede(0);
        setMoedaCotacao("BRL");
        setCotacao(0);

        window.location.href = "/operacoes";
      } else {
        toast.error(response.message || response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro na API");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const formatCurrency = (value) => {
    if (!value) {
      return "R$ 0,00";
    }
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  const calcularCotacao = (tCompraVenda, mSaida, mDestino) => {
    setQuantidade(null);
    setTotal(null);
    setCotacao(0);
    setCotacaoOriginal(0);
    setTaxaCompraVenda(tCompraVenda);

    if (!mSaida || !mDestino) {
      setMoedaCotacao("BRL");
      setCotacao(parseFloat(0));
      return;
    }
    const moedaSaidaObj = moedas.find((moeda) => moeda.id === mSaida.value);
    const moedaDestinoObj = moedas.find((moeda) => moeda.id === mDestino.value);

    if (!moedaSaidaObj || !moedaDestinoObj) {
      setMoedaCotacao("BRL");
      setCotacao(parseFloat(0));
      return;
    }

    let cotacao_original;
    let cotacao_final;
    let moedaBase;
    let tFort;

    // Se a moeda de saída ou de destino for BRL
    if (moedaSaidaObj.moeda === "BRL" || moedaDestinoObj.moeda === "BRL") {
      moedaBase = "BRL";
      cotacao_original =
        moedaSaidaObj.moeda === "BRL"
          ? moedaDestinoObj.preco_atual_brl
          : moedaSaidaObj.preco_atual_brl;
      tFort = (cotacao_original * tCompraVenda) / 100;
      cotacao_final = cotacao_original - tFort;
    } else {
      // Ambas as moedas são estrangeiras, usar USD como base para a cotação
      moedaBase = moedaDestinoObj.moeda;
      cotacao_original = moedaSaidaObj.preco_usd / moedaDestinoObj.preco_usd;
      tFort = (cotacao_original * tCompraVenda) / 100;
      cotacao_final = cotacao_original - tFort;
    }

    setMoedaCotacao(moedaBase);
    setCotacao(parseFloat(cotacao_final));
    setCotacaoOriginal(parseFloat(cotacao_original));
    setCotacaoOriginalFort(parseFloat(cotacao_final));
  };

  const calcularOperacao = (qtd, qtDestino) => {
    if (!qtd || !qtDestino || qtd === 0 || qtDestino === 0) {
      setTaxaFort(0);
      setCotacao(cotacaoOriginal);
      return;
    }

    qtDestino = parseFloat(qtDestino);
    qtd = parseFloat(qtd);
    let novaCotacao;
    let destinoCotacaoOriginal;

    if (moedaDestino?.value === 0) {
      novaCotacao = qtDestino / qtd;
      destinoCotacaoOriginal = parseFloat(qtd * cotacaoOriginal);
    } else {
      novaCotacao = qtDestino / qtd;
      destinoCotacaoOriginal = parseFloat(qtd * cotacaoOriginal);
    }

    const diferenca = destinoCotacaoOriginal - qtDestino;
    const tCompraVenda = (diferenca / destinoCotacaoOriginal) * 100;

    setTaxaFort(diferenca);
    setTaxaCompraVenda(tCompraVenda);
    setCotacao(novaCotacao);
  };

  const formatNome = (name) => {
    let lowerCaseName = name.toLowerCase();
    let properName = lowerCaseName.replace(
      /(^|\s|')(\w)/g,
      function (match, separator, letter, index, fullText) {
        // Encontra a palavra completa a partir do índice
        const matchResult = fullText.slice(index).match(/^[^\s']+|\b\w+/);
        // Se matchResult for null, retorna a letra original
        if (!matchResult) {
          return match;
        }
        const word = matchResult[0];
        const lowerCaseWords = ["de", "da", "do", "dos", "das"];
        if (lowerCaseWords.includes(word)) {
          return separator + letter;
        }
        return separator + letter.toUpperCase();
      }
    );

    return properName;
  };

  const fetchClientes = useCallback(async () => {
    const user = JSON.parse(sessionStorage.getItem("authUser"));
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/client/clientes`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (response.status && response.status === 1) {
        return response.data;
      } else {
        toast.error(response.mensagem);
        console.log(response);
        return [];
      }
    } catch (error) {
      toast.error("Erro ao buscar usuários.");
      console.log(error);
      return [];
    }
  }, []);

  const fetchMoedas = useCallback(async () => {
    const user = JSON.parse(sessionStorage.getItem("authUser"));
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/moedas/precos`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (response.status && response.status === 1) {
        const brl = {
          id: 0,
          moeda: "BRL",
          preco_atual_brl: 1,
          rede: "BRL",
          decimais: 2,
        };
        return response.data.concat(brl);
      } else {
        toast.error(response.mensagem);
        console.log(response);
        return [];
      }
    } catch (error) {
      toast.error("Erro ao buscar moedas.");
      console.log(error);
      return [];
    }
  }, []);

  const fetchConfigData = useCallback(async () => {
    const token = JSON.parse(sessionStorage.getItem("authUser"))?.token;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/config-system`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status && response.status === 1) {
        response.dados.forEach((item) => {
          switch (item.campo) {
            case "taxa_compra_venda":
              setTaxaCompraVenda(parseFloat(item.value));
              setTaxaCompraVendaOriginal(parseFloat(item.value));
              break;
            case "btc_bybit":
              setSaldoBtc(parseFloat(item.value));
              break;
            case "eth_bybit":
              setSaldoEth(parseFloat(item.value));
              break;
            case "matic_bybit":
              setSaldoMatic(parseFloat(item.value));
              break;
            case "usdt_bybit":
              setSaldoUsdt(parseFloat(item.value));
              break;
            default:
              break;
          }
        });
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro ao buscar dados!");
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (shouldFetch) {
      const fetchData = async () => {
        setLoading(true);
        const retorno = await fetchClientes();
        const clientesLista = [
          {
            options: [],
          },
        ];
        const fornecedoresLista = [
          {
            options: [
              {
                value: 1,
                label: "Fort Exchange",
              },
            ],
          },
        ];
        retorno.forEach((element) => {
          if (element.nome_completo !== null) {
            clientesLista[0].options.push({
              value: element.user_id,
              label: formatNome(element.nome_completo),
            });
            fornecedoresLista[0].options.push({
              value: element.user_id,
              label: formatNome(element.nome_completo),
            });
          }
        });

        setListaClientes(clientesLista);
        setListaFornecedores(fornecedoresLista);

        const moedasLista = [
          {
            value: 0,
            label: "BRL",
          },
        ];

        const retornoMoedas = await fetchMoedas();
        setMoedas(retornoMoedas);
        retornoMoedas.forEach((element) => {
          if (element.moeda !== "BRL") {
            moedasLista.push({
              value: element.id,
              label: `${element.moeda} (${element.rede})`,
            });
            if (element.moeda === "USDT") {
              setUsdBrl(element.preco_atual_brl);
            }
          }
        });
        setListaMoedas(moedasLista);

        await fetchConfigData();

        setShouldFetch(false);
        setLoading(false);
      };

      fetchData();
    }
  }, [shouldFetch, fetchClientes, fetchMoedas, fetchConfigData]);

  document.title = "Nova Operação | Painel Fort Exchange";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xxl={12}>
              <Card className="mt-xxl-n5">
                <CardHeader className="d-flex align-items-center border-0">
                  <h5 className="card-title mb-0 mt-5 flex-grow-1">Nova Operação</h5>
                </CardHeader>
                <CardBody className="p-4">
                  <TabContent activeTab="0">
                    <TabPane tabId="0">
                      <Form>
                        <Row>
                          <Col lg={6}>
                            <GasFeeCalculator />
                          </Col>
                          <Col lg={6}>
                            <p>
                              <b>USDT / BRL:</b> {formatCurrency(usdBrl)}
                            </p>
                          </Col>

                          <Col lg={6}>
                            <h6 className="fw-semibold mt-4">Cliente</h6>
                            <Select
                              value={cliente}
                              onChange={(cliente) => {
                                setCliente(cliente);
                              }}
                              options={listaClientes}
                              className="js-example-basic-single mb-0"
                              placeholder={
                                loading
                                  ? "Carregando..."
                                  : "Selecione um cliente"
                              }
                            />
                          </Col>
                          <Col lg={6}>
                            <h6 className="fw-semibold mt-4">Fornecedor</h6>
                            <Select
                              value={fornecedor}
                              onChange={(fornecedor) => {
                                setFornecedor(fornecedor);
                              }}
                              options={listaFornecedores}
                              className="js-example-basic-single mb-0"
                              placeholder={
                                loading
                                  ? "Carregando..."
                                  : "Selecione um fornecedor"
                              }
                            />
                          </Col>
                          <Col lg={4}>
                            <h6 className="fw-semibold mt-4">Moeda Saída</h6>
                            <Select
                              value={moedaSaida}
                              onChange={(moeda) => {
                                setMoedaSaida(moeda);
                                calcularCotacao(
                                  taxaCompraVendaOriginal,
                                  moeda,
                                  moedaDestino
                                );
                              }}
                              options={listaMoedas}
                              className="js-example-basic-single mb-0"
                              placeholder={
                                loading
                                  ? "Carregando..."
                                  : "Selecione uma moeda"
                              }
                            />
                          </Col>
                          <Col lg={4}>
                            <h6 className="fw-semibold mt-4">
                              Quantidade Saída
                            </h6>
                            {moedaSaida?.value === 0 || moedaSaida === null ? (
                              <NumericFormat
                                className="form-control"
                                placeholder="R$ 0,00"
                                value={quantidade ? quantidade : ""}
                                onValueChange={({ value }) => {
                                  setQuantidade(parseFloat(value));
                                  calcularOperacao(value, total);
                                }}
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={true}
                                prefix="R$ "
                                allowNegative={false}
                              />
                            ) : (
                              <NumericFormat
                                className="form-control"
                                placeholder={`0.00 ${
                                  moedas.find(
                                    (element) =>
                                      element.id === moedaSaida?.value
                                  ).moeda
                                }`}
                                value={quantidade ? quantidade : ""}
                                onValueChange={({ value }) => {
                                  setQuantidade(parseFloat(value));
                                  calcularOperacao(value, total);
                                }}
                                thousandSeparator=","
                                decimalSeparator="."
                                decimalScale={
                                  moedas.find(
                                    (element) =>
                                      element.id === moedaSaida?.value
                                  ).decimais
                                }
                                fixedDecimalScale={true}
                                suffix={
                                  moedas.find(
                                    (element) =>
                                      element.id === moedaSaida?.value
                                  ).moeda
                                }
                                allowNegative={false}
                              />
                            )}
                            {quantidade && quantidade !== 0 ? (
                              moedaDestino?.value === 0 ||
                              moedaDestino === null ? (
                                <small className="text-muted">
                                  Cotação: R${" "}
                                  {(quantidade * cotacaoOriginal).toFixed(2)} |
                                  Fort: R${" "}
                                  {(quantidade * cotacaoOriginalFort).toFixed(
                                    2
                                  )}
                                </small>
                              ) : (
                                <small className="text-muted">
                                  Cotação:{" "}
                                  {(quantidade / cotacaoOriginal).toFixed(8)}{" "}
                                  {
                                    moedas.find(
                                      (element) =>
                                        element.id === moedaDestino.value
                                    ).moeda
                                  }{" "}
                                  | Fort:{" "}
                                  {(quantidade / cotacaoOriginalFort).toFixed(
                                    8
                                  )}{" "}
                                  {
                                    moedas.find(
                                      (element) =>
                                        element.id === moedaDestino.value
                                    ).moeda
                                  }
                                </small>
                              )
                            ) : null}
                          </Col>
                          <Col lg={4}>
                            <h6 className="fw-semibold mt-4">Cotação</h6>
                            {moedaCotacao === "BRL" ? (
                              <NumericFormat
                                className="form-control"
                                placeholder="R$ 0,00"
                                value={cotacao ? cotacao : ""}
                                onValueChange={() => {}}
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={true}
                                prefix="R$ "
                                allowNegative={false}
                                disabled
                              />
                            ) : (
                              <NumericFormat
                                className="form-control"
                                placeholder={`0.00 ${moedaCotacao}`}
                                value={cotacao ? cotacao : ""}
                                onValueChange={() => {}}
                                thousandSeparator=","
                                decimalSeparator="."
                                decimalScale={
                                  moedas.find(
                                    (element) =>
                                      element.id === moedaDestino?.value
                                  ).decimais
                                }
                                fixedDecimalScale={true}
                                suffix={moedaCotacao}
                                allowNegative={false}
                                disabled
                              />
                            )}
                            {cotacaoOriginal && cotacaoOriginal !== 0 ? (
                              <small className="text-muted">
                                Cotação:{" "}
                                {moedaCotacao === "BRL"
                                  ? `R$ ${cotacaoOriginal.toFixed(2)}`
                                  : `${cotacaoOriginal.toFixed(
                                      8
                                    )} ${moedaCotacao}`}{" "}
                                | Fort:{" "}
                                {moedaCotacao === "BRL"
                                  ? `R$ ${cotacaoOriginalFort.toFixed(2)}`
                                  : `${cotacaoOriginalFort.toFixed(
                                      8
                                    )} ${moedaCotacao}`}
                              </small>
                            ) : null}
                          </Col>
                          <Col lg={4}>
                            <h6 className="fw-semibold mt-4">Moeda Destino</h6>
                            <Select
                              value={moedaDestino}
                              onChange={(moeda) => {
                                setMoedaDestino(moeda);
                                setQuantidade(null);
                                setTotal(null);
                                calcularCotacao(
                                  taxaCompraVendaOriginal,
                                  moedaSaida,
                                  moeda
                                );
                              }}
                              options={listaMoedas}
                              className="js-example-basic-single mb-0"
                              placeholder={
                                loading
                                  ? "Carregando..."
                                  : "Selecione uma moeda"
                              }
                            />
                          </Col>
                          <Col lg={4}>
                            <h6 className="fw-semibold mt-4">
                              Quantidade Destino
                            </h6>
                            {moedaDestino?.value === 0 ||
                            moedaDestino === null ? (
                              <NumericFormat
                                className="form-control"
                                placeholder="R$ 0,00"
                                value={total ? total : ""}
                                onValueChange={(e) => {
                                  setTotal(parseFloat(e.value));
                                  calcularOperacao(quantidade, e.value);
                                }}
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={true}
                                prefix="R$ "
                                allowNegative={false}
                              />
                            ) : (
                              <NumericFormat
                                className="form-control"
                                placeholder={`0.00 ${
                                  moedas.find(
                                    (element) =>
                                      element.id === moedaDestino?.value
                                  ).moeda
                                }`}
                                value={total ? total : ""}
                                onValueChange={(e) => {
                                  setTotal(parseFloat(e.value));
                                  calcularOperacao(quantidade, e.value);
                                }}
                                thousandSeparator=","
                                decimalSeparator="."
                                decimalScale={
                                  moedas.find(
                                    (element) =>
                                      element.id === moedaDestino?.value
                                  ).decimais
                                }
                                fixedDecimalScale={true}
                                suffix={
                                  moedas.find(
                                    (element) =>
                                      element.id === moedaDestino?.value
                                  ).moeda
                                }
                                allowNegative={false}
                              />
                            )}
                            {total && total !== 0 ? (
                              moedaSaida?.value === 0 || moedaSaida === null ? (
                                <small className="text-muted">
                                  Cotação: R${" "}
                                  {(total * cotacaoOriginal).toFixed(2)} | Fort:
                                  R$ {(total * cotacaoOriginalFort).toFixed(2)}
                                </small>
                              ) : (
                                <small className="text-muted">
                                  Cotação original:{" "}
                                  {(total / cotacaoOriginal).toFixed(8)}{" "}
                                  {
                                    moedas.find(
                                      (element) =>
                                        element.id === moedaSaida.value
                                    ).moeda
                                  }{" "}
                                  | Fort:{" "}
                                  {(total / cotacaoOriginalFort).toFixed(8)}{" "}
                                  {
                                    moedas.find(
                                      (element) =>
                                        element.id === moedaSaida.value
                                    ).moeda
                                  }
                                </small>
                              )
                            ) : null}
                          </Col>

                          <Col lg={4}>
                            <h6 className="fw-semibold mt-4">
                              Taxa de Rede (BRL)
                            </h6>
                            <NumericFormat
                              className="form-control"
                              placeholder="R$ 0,00"
                              value={taxaRede ? taxaRede : 0}
                              onChange={({ value }) => {
                                setTaxaRede(parseFloat(value));
                              }}
                              thousandSeparator="."
                              decimalSeparator=","
                              decimalScale={2}
                              fixedDecimalScale={true}
                              prefix="R$ "
                              allowNegative={false}
                            />
                          </Col>
                          <Row lg={12} className="d-flex justify-content-end">
                            <Col lg={4}>
                              <div className="mt-4 ml-3">
                                <Input
                                  type="checkbox"
                                  className="form-check-input m-2"
                                  checked={converter}
                                  onChange={() => {
                                    setConverter(!converter);
                                  }}
                                />
                                <Label className="form-check-label p-2">
                                  Converter na exchange?
                                </Label>
                              </div>
                              <small className="text-muted">
                                Conversão será desativada caso as duas moedas
                                forem stable ou BRL.
                              </small>
                            </Col>
                            <Col lg={3}>
                              <h6 className="fw-semibold mt-4">
                                Taxa Fort (%)
                              </h6>
                              <NumericFormat
                                className="form-control"
                                placeholder="0.00 %"
                                value={taxaCompraVenda}
                                onValueChange={() => {}}
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={true}
                                suffix="%"
                                allowNegative={true}
                                disabled
                              />
                            </Col>
                            <Col lg={3}>
                              <h6 className="fw-semibold mt-4">
                                Taxa Fort (R$)
                              </h6>
                              {moedaCotacao === "BRL" ? (
                                <NumericFormat
                                  className="form-control"
                                  placeholder="R$ 0,00"
                                  value={taxaFort ? taxaFort : ""}
                                  onValueChange={() => {}}
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  prefix="R$ "
                                  allowNegative={true}
                                  disabled
                                />
                              ) : (
                                <NumericFormat
                                  className="form-control"
                                  placeholder={`0.00 ${moedaCotacao}`}
                                  value={taxaFort ? taxaFort : ""}
                                  onValueChange={() => {}}
                                  thousandSeparator=","
                                  decimalSeparator="."
                                  decimalScale={
                                    moedas.find(
                                      (element) =>
                                        element.id === moedaDestino?.value
                                    ).decimais
                                  }
                                  fixedDecimalScale={true}
                                  suffix={moedaCotacao}
                                  allowNegative={true}
                                  disabled
                                />
                              )}
                              <small className="text-muted">
                                Descontos podem ser aplicados se o cliente for
                                indicado.
                              </small>
                            </Col>

                            <Col lg={2} className="d-flex justify-content-end">
                              <h6> </h6>
                              <Button
                                color="primary"
                                className="btn mt-5 mb-3 px-4"
                                onClick={handleSubmit}
                              >
                                Criar
                              </Button>
                            </Col>
                          </Row>
                        </Row>
                      </Form>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
              <Card className="mt-xxl-n5">
                <CardHeader className="d-flex align-items-center border-0">
                  <h5 className="card-title mb-0 flex-grow-1">
                    Saldos na Exchange
                  </h5>
                </CardHeader>
                <CardBody className="p-4">
                  <Row>
                    <Col lg={3}>
                      <h6 className="fw-semibold mt-4">USDT</h6>
                      <Input
                        type="text"
                        className="form-control"
                        value={saldoUsdt.toFixed(6)}
                        onChange={() => {}}
                        disabled
                      />
                    </Col>
                    <Col lg={3}>
                      <h6 className="fw-semibold mt-4">BTC</h6>
                      <Input
                        type="text"
                        className="form-control"
                        value={saldoBtc.toFixed(8)}
                        onChange={() => {}}
                        disabled
                      />
                    </Col>
                    <Col lg={3}>
                      <h6 className="fw-semibold mt-4">ETH</h6>
                      <Input
                        type="text"
                        className="form-control"
                        value={saldoEth.toFixed(18)}
                        onChange={() => {}}
                        disabled
                      />
                    </Col>
                    <Col lg={3}>
                      <h6 className="fw-semibold mt-4">MATIC</h6>
                      <Input
                        type="text"
                        className="form-control"
                        value={saldoMatic.toFixed(18)}
                        onChange={() => {}}
                        disabled
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Operacoes;
