import React, { useState, useCallback, useEffect } from "react";
import { Container } from "reactstrap";
import Redes from "./Redes";
import Moedas from "./Moedas";
import { toast } from "react-toastify";
import axios from "axios";

const RedesMoedas = () => {
  const [redes, setRedes] = useState(null);
  const [loading, setLoading] = useState(false);
  document.title = "Envio Semanal | Painel Fort Exchange";

  const fetchRedes = useCallback(async () => {
    setLoading(true);
    const token = JSON.parse(sessionStorage.getItem("authUser"))?.token;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/rede`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status && response.status === 1) {
        return response.data;
      } else {
        toast.error(response.mensagem);
        console.log(response);
        return [];
      }
    } catch (error) {
      toast.error("Erro ao buscar dados!");
      console.log(error);
      return [];
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const retorno = await fetchRedes();
      setRedes(retorno);
    };

    if (!redes) fetchData();
  }, [redes, fetchRedes]);

  return (
    <React.Fragment>
      <Container fluid>
        <Redes
          redes={redes}
          fetchRedes={fetchRedes}
          setRedes={setRedes}
          loading={loading}
        />
        <Moedas redes={redes} />
      </Container>
    </React.Fragment>
  );
};

export default RedesMoedas;
