import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
  Spinner,
} from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { NumericFormat } from "react-number-format";
import BigNumber from "bignumber.js";

const ModalNewOrder = ({
  lojista_id,
  setmodalOrder,
  show,
  setShouldFetch,
  coins,
}) => {
  const [loading, setLoading] = useState(false);
  const [moeda, setMoeda] = useState("");
  const [moedaId, setMoedaId] = useState(0);
  const [moedas, setMoedas] = useState([]);
  const [brl_amount, setBrlAmount] = useState(0);
  const [cripto_amount, setCriptoAmount] = useState(0);
  const [moedaQty, setMoedaQty] = useState(0);
  const [tipo, setTipo] = useState("cripto");
  const [destino, setDestino] = useState("");

  const fetchMoedas = useCallback(async () => {
    const user = JSON.parse(sessionStorage.getItem("authUser") || "{}");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/saldo/${user.data._id}/ALL`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (response && response.status === 1) {
        setMoedas(response.data);
        setMoeda(response.data[0].moeda);
        setMoedaId(0);
        setMoedaQty(response.data[0].quantidade);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro ao buscar saldos");
      console.log(error);
    }
  }, []);

  const createRetirada = async (event) => {
    event.preventDefault();
    setLoading(true);
    const clientData = JSON.parse(sessionStorage.getItem("authUser") || "{}");
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/retiradas-cripto/store`,
        headers: {
          Authorization: `Bearer ${clientData.token}`,
        },
        data: {
          user_id: lojista_id,
          moeda: moeda,
          amount: cripto_amount,
          destino: destino,
          tipo: tipo,
        },
      });

      if (response && response.status === 1) {
        toast.success(response.mensagem);
        setmodalOrder(!show);
        setShouldFetch(true);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro ao criar retirada");
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (show) {
      setLoading(true);
      fetchMoedas();
      setLoading(false);
    }
  }, [show, fetchMoedas]);

  return (
    <Modal
      isOpen={show}
      backdrop={"static"}
      toggle={() => {
        setmodalOrder(!show);
      }}
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          setmodalOrder(!show);
        }}
      >
        Retirar Cripto
      </ModalHeader>
      <ModalBody>
        <form>
          <div className="row g-3">
            <Col md={12}>
              <div>
                <Label className="form-Label">Moeda</Label>
                <Input
                  type="select"
                  className="form-control"
                  value={moedaId}
                  onChange={(e) => {
                    setMoedaId(e.target.value);
                    setMoeda(moedas[e.target.value].moeda);
                    setMoedaQty(moedas[e.target.value].quantidade);
                    setCriptoAmount(0);
                    setBrlAmount(0);
                    setDestino("");
                  }}
                >
                  {moedas.map((moeda, index) => (
                    <option key={index} value={index}>
                      {moeda.moeda} (
                      {BigNumber(moeda.quantidade).toFixed(
                        moeda.moeda === "BTC"
                          ? 8
                          : moeda === "USDC" || moeda === "USDT"
                          ? 6
                          : 18
                      )}
                      )
                    </option>
                  ))}
                </Input>
              </div>
            </Col>
            <Col md={6}>
              <div>
                <Label htmlFor="compraDiaria" className="form-Label">
                  Valor
                </Label>
                <NumericFormat
                  className="form-control"
                  placeholder={`0.00000000 ${moeda}`}
                  value={cripto_amount}
                  onChange={(e) => {
                    setCriptoAmount(parseFloat(e.target.value));
                    const user = JSON.parse(
                      sessionStorage.getItem("authUser") || "{}"
                    );

                    let spread = 0;

                    if (user.data.lojistaData) {
                      spread = user.data.lojistaData.spread_venda;
                    }
                    const criptoPreco = coins.find(
                      (coin) => coin?.moeda === moeda
                    )?.preco_conversao;

                    setBrlAmount(
                      parseFloat(e.target.value) *
                        parseFloat(criptoPreco) *
                        (1 + spread)
                    );
                  }}
                  decimalSeparator="."
                  thousandSeparator=","
                  decimalScale={
                    moeda === "BTC"
                      ? 8
                      : moeda === "USDC" || moeda === "USDT"
                      ? 6
                      : 18
                  }
                  suffix={` ${moeda}`}
                  fixedDecimalScale={true}
                  allowNegative={false}
                  disabled={moeda === ""}
                />
                <small className="text-danger">
                  {moedaQty < cripto_amount
                    ? "Saldo insuficiente para essa retirada"
                    : ""}
                </small>
              </div>
            </Col>
            <Col md={6}>
              <div>
                <Label htmlFor="compraDiaria" className="form-Label">
                  Valor R$
                </Label>
                <NumericFormat
                  className="form-control"
                  placeholder="R$ 0,00"
                  value={brl_amount}
                  onValueChange={() => {}}
                  decimalSeparator=","
                  thousandSeparator="."
                  decimalScale={2}
                  prefix="R$ "
                  fixedDecimalScale={true}
                  allowNegative={false}
                  readOnly={true}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="form-check mb-2">
                <Input
                  className="form-check-input"
                  type="radio"
                  name="tipo"
                  checked={tipo === "cripto"}
                  onChange={() => {
                    setTipo("cripto");
                    setDestino("");
                  }}
                  defaultChecked
                />
                <Label className="form-check-label" htmlFor="tipo">
                  Cripto
                </Label>
              </div>
            </Col>
            <Col md={4}>
              <div className="form-check mb-2">
                <Input
                  className="form-check-input"
                  type="radio"
                  name="tipo"
                  checked={tipo === "lightning"}
                  onChange={() => {
                    setTipo("lightning");
                    setDestino("");
                  }}
                  defaultChecked
                />
                <Label className="form-check-label" htmlFor="tipo">
                  BTC Lightning
                </Label>
              </div>
            </Col>
            <Col md={4}>
              <div className="form-check mb-2">
                <Input
                  className="form-check-input"
                  type="radio"
                  name="tipo"
                  checked={tipo === "pix"}
                  onChange={() => {
                    setTipo("pix");
                    setDestino("");
                  }}
                />
                <Label className="form-check-label" htmlFor="tipo">
                  PIX
                </Label>
              </div>
            </Col>
            <Col md={12}>
              <div>
                <Label htmlFor="destino" className="form-Label">
                  Destino
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder={
                    tipo === "pix"
                      ? "Chave PIX"
                      : tipo === "lightning"
                      ? "E-mail Lightning"
                      : "Endereço Cripto"
                  }
                  value={destino}
                  onChange={(e) => setDestino(e.target.value)}
                  maxLength={255}
                />
                {tipo === "pix" && (
                  <span className="text-muted">
                    * Ao selecionar PIX, o valor em cripto será convertido para
                    BRL, aplicando a taxa de conversão de 1,45%.
                  </span>
                )}
              </div>
            </Col>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Spinner height={100} width={100} />
              </div>
            ) : (
              <Col lg={12}>
                <div className="hstack gap-2 justify-content-end">
                  <Button color="light" onClick={() => setmodalOrder(false)}>
                    Fechar
                  </Button>
                  <Button
                    color="primary"
                    type="button"
                    disabled={loading || moedaQty < cripto_amount}
                    onClick={createRetirada}
                  >
                    Abrir Ordem
                  </Button>
                </div>
              </Col>
            )}
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};
export default ModalNewOrder;
