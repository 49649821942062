import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader, Input } from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { BigNumber, ethers } from "ethers";
import axios from "axios";
import multisendAbi from "./multisendAbi";
import erc20Abi from "./erc20Abi";
import { contracts } from "./contracts";
import FeatherIcon from "feather-icons-react";

const ModalEnvio = ({
  show,
  onHide,
  data,
  network,
  setShouldFetch,
  setShowModal,
  coins,
}) => {
  const [carteiras, setCarteiras] = useState([]);
  const [valores, setValores] = useState([]);
  const [taxa_envio, setTaxaEnvio] = useState(0);
  const [moeda, setMoeda] = useState("");
  const [moedaDados, setMoedaDados] = useState({});
  const [buttonText, setButtonText] = useState("Enviar");
  const [transaction_id, setTransactionId] = useState("");
  const [loading, setLoading] = useState(false);

  const atualizarTxId = async (id, txId) => {
    const user = JSON.parse(sessionStorage.getItem("authUser"));

    //tratar txId para que se for um link, pegar apenas o hash (a última parte)
    let txIdTransformed = txId;
    if(txId.includes("/")) txIdTransformed = txId.split("/").pop();
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/cripto-td/atualiza-semanal/${id}`,
        { transaction_id: txIdTransformed },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (response.status && response.status === 1) {
        toast.success(response.mensagem, {
          position: "top-right",
          hideProgressBar: true,
          closeOnClick: false,
          className: "bg-success text-white",
        });
      } else if (response.mensagem) {
        toast.error(response.mensagem, {
          position: "top-right",
          hideProgressBar: true,
          closeOnClick: false,
          className: "bg-danger text-white",
        });
      } else {
        toast.error("Erro na operação!", {
          position: "top-right",
          hideProgressBar: true,
          closeOnClick: false,
          className: "bg-danger text-white",
        });
      }
    } catch (error) {
      toast.error("Erro na operação: " + error, {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: false,
        className: "bg-danger text-white",
      });
    }
  };

  const handleSend = async () => {
    if (moedaDados.rede !== network.chainName) {
      return toast.error("A rede conectada e a rede da moeda são diferentes!");
    }
    setLoading(true);
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      let valoresTemp;
      let total;
      let gasLimit;
      let tx;

      const contract = new ethers.Contract(
        contracts[network.chainName].send_contract,
        multisendAbi,
        signer
      );

      if (moedaDados.contrato === "") {
        valoresTemp = valores.map((v) =>
          ethers.utils.parseEther(v.toFixed(18))
        );
        total = valoresTemp.reduce(
          (a, b) => a.add(b),
          ethers.BigNumber.from(0)
        );
        const estimate = await contract.estimateGas.multisendEther(
          carteiras,
          valoresTemp,
          { value: total }
        );
        gasLimit = Math.round(estimate.toNumber() * 1.3);
        tx = await contract.multisendEther(carteiras, valoresTemp, {
          value: total,
          gasLimit,
        });
      } else {
        valoresTemp = valores.map((v) =>
          ethers.utils.parseUnits(v.toFixed(6), 6)
        );
        total = valoresTemp.reduce(
          (a, b) => a.add(b),
          ethers.BigNumber.from(0)
        );
        const contractToken = new ethers.Contract(
          moedaDados.contrato,
          erc20Abi,
          signer
        );

        setButtonText("Aprovando...");

        console.log("valores", valoresTemp);
        console.log("total", total);

        const estimateApprove = await contractToken.estimateGas.approve(
          contracts[network.chainName].send_contract,
          total
        );
        gasLimit = Math.round(estimateApprove.toNumber() * 1.3);
        const approveTx = await contractToken.approve(
          contracts[network.chainName].send_contract,
          total,
          {
            gasLimit,
          }
        );

        await approveTx.wait(1);

        setButtonText("Enviando...");

        const estimate = await contract.estimateGas.multisendToken(
          moedaDados.contrato,
          carteiras,
          valoresTemp
        );
        gasLimit = Math.round(estimate.toNumber() * 1.3);
        tx = await contract.multisendToken(
          moedaDados.contrato,
          carteiras,
          valoresTemp,
          {
            gasLimit,
          }
        );
      }

      toast.success("Transação enviada com sucesso!");

      if (tx.hash) await atualizarTxId(data.id, tx.hash);
      else toast.error("Erro ao capturar a Hash!");

      setShouldFetch(true);
      setShowModal(false);
    } catch (error) {
      toast.error("Erro ao enviar transação!");
      console.error(error);
    }
    setButtonText("Enviar");
    setLoading(false);
  };

  const formatCurrency = (value) => {
    if (!value) {
      return "";
    }
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  function findPriceByCurrency(currency) {
    const currencyObject = coins.find((item) => item.moeda === currency);

    if (currencyObject) {
      return currencyObject.preco_atual_brl;
    } else {
      return 0;
    }
  }

  useEffect(() => {
    if (!data) {
      return;
    }
    let tempCarteiras = [];
    let tempValores = [];

    try {
      const envio_data = JSON.parse(data.envio_data);
      setTaxaEnvio(data.taxa_envio);
      setMoeda(data.moeda);
      setMoedaDados(coins.find((item) => item.moeda === data.moeda));

      for (let key in envio_data) {
        const carteira = envio_data[key].carteira;
        const valor = envio_data[key].soma_valor_token;
        if (valor > 0) {
          tempCarteiras.push(carteira);
          tempValores.push(valor);
        }
      }
    } catch (error) {
      console.error("Ocorreu um erro ao processar os dados.", error);
    }

    setCarteiras(tempCarteiras);
    setValores(tempValores);
  }, [data, coins]);

  return (
    <Modal
      isOpen={show}
      toggle={onHide}
      backdrop={"static"}
      id="staticBackdrop"
      centered
      className="modal-lg"
    >
      <ModalHeader
        className="modal-title"
        id="staticBackdropLabel"
        toggle={onHide}
      >
        Envio Semanal
      </ModalHeader>
      {moedaDados.rede === "Core" || moedaDados.rede === "Solana" ? (
        <ModalBody className="text-center p-5">
          <lord-icon
            src="https://cdn.lordicon.com/lupuorrc.json"
            trigger="loop"
            colors="primary:#121331,secondary:#08a88a"
            style={{ width: "120px", height: "120px" }}
          ></lord-icon>

          <div className="mt-4">
            <h4 className="mb-3">Envios deste grupo:</h4>
            <p className="text-muted mb-4">
              Número de Carteiras: {carteiras.length}
            </p>

            <textarea
              id="carteiras-valores-textarea"
              rows="10"
              className="form-control"
              readOnly
              value={carteiras
                .map(
                  (carteira, index) =>
                    `${carteira}, ${valores[index].toFixed(8)}`
                )
                .join("\n")}
            />

            <div className="hstack gap-2 justify-content-center mt-3">
              <Input
                type="text"
                className="form-control"
                value={transaction_id}
                onChange={(e) => setTransactionId(e.target.value)}
                placeholder="Transaction ID"
              />
              <Button
                color="primary"
                onClick={async () => {
                  setLoading(true);
                  await atualizarTxId(data.id, transaction_id);
                  setShouldFetch(true);
                  setLoading(false);
                  setShowModal(false);
                }}
                disabled={loading}
              >
                Enviar
              </Button>
            </div>

            <div className="hstack gap-2 justify-content-center mt-3">
              <Link
                to="#"
                className="btn btn-warning"
                onClick={() => {
                  navigator.clipboard.writeText(
                    document.getElementById("carteiras-valores-textarea").value
                  );
                }}
              >
                <FeatherIcon
                  icon="copy"
                  style={{ color: "#fff", height: "15px" }}
                />
                Copiar
              </Link>
              <Button color="light" onClick={() => setShowModal(false)}>
                Fechar
              </Button>
            </div>
          </div>
        </ModalBody>
      ) : (
        <ModalBody className="text-center p-5">
          <lord-icon
            src="https://cdn.lordicon.com/lupuorrc.json"
            trigger="loop"
            colors="primary:#121331,secondary:#08a88a"
            style={{ width: "120px", height: "120px" }}
          ></lord-icon>

          <div className="mt-4">
            <h4 className="mb-3">Envios deste grupo:</h4>
            <p className="text-muted mb-4">
              {" "}
              Número de Carteiras: {carteiras.length} <br />
              Taxa de Envio: {taxa_envio}
              {` (${formatCurrency(taxa_envio * findPriceByCurrency(moeda))})`}
            </p>
            <div className="hstack gap-2 justify-content-center">
              <Link
                to="#"
                className="btn btn-warning"
                onClick={handleSend}
                disabled={loading}
              >
                {buttonText}
              </Link>
              <Button color="light" onClick={() => setShowModal(false)}>
                Fechar
              </Button>
            </div>
            <br />
            <div className="row">
              <div className="col">
                {carteiras.map((carteira, index) => (
                  <p
                    key={index}
                    style={{
                      fontWeight: "bold",
                      textAlign: "left",
                      marginBottom: "3px",
                    }}
                  >
                    {carteira}
                  </p>
                ))}
              </div>
              <div className="col text-end text-success">
                {valores.map((valor, index) => (
                  <p
                    key={index}
                    style={{ textAlign: "right", marginBottom: "3px" }}
                  >
                    {(valor * (1 - moedaDados.taxa_envio / 100)).toFixed(18)}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </ModalBody>
      )}
    </Modal>
  );
};

export default ModalEnvio;
