import React, { useEffect, useState, useCallback } from "react";
import { Container, Row } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import AllOrdersSocios from "./AllOrdersSocios";
import axios from "axios";
import { toast } from "react-toastify";

const TabelaSocios = () => {
  const [loading, setLoading] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [shouldFetch, setShouldFetch] = useState(true); 

  const fecthSocios = useCallback(
    async () => {
      setLoading(true);
      const user = JSON.parse(sessionStorage.getItem("authUser"));
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/lojista/socios/${user.data._id}`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        if (response.status && response.status === 1)  {
          return response.data;
        } else {
          toast.error(response.mensagem);
          console.log(response);
          return [];
        }
      } catch (error) {
        toast.error("Erro ao buscar usuários.");
        console.log(error);
        return [];
      } finally {
        setLoading(false);
      }
    },
    [] 
  );

  useEffect(() => {
    if (shouldFetch) {
      const fetchData = async () => {
        const retorno = await fecthSocios();
        setOrderList(retorno);
        setShouldFetch(false);
      };

      fetchData();
    }
  }, [shouldFetch, fecthSocios]);

  document.title = "Sócios | Painel Fort Exchange";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Sócios"
            pageTitle={"Lojista"}
          />
          <Row>
            <AllOrdersSocios
              orderList={orderList}
              loading={loading}
              setShouldFetch={setShouldFetch}
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TabelaSocios;
