import React, { useEffect, useMemo, useState, useCallback } from "react";
import axios from "axios";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Spinner,
  Button,
  Badge,
} from "reactstrap";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import ModalEditarMoeda from "./ModalEditarMoeda";
import ModalNovaMoeda from "./ModalNovaMoeda";

//redux
import TableContainer from "../../../Components/Common/TableContainer";

const Moedas = ({ redes }) => {
  const [moedas, setMoedas] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [moeda, setRede] = useState({});
  const [loading, setLoading] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);

  const fetchMoedas = useCallback(async () => {
    setLoading(true);
    const token = JSON.parse(sessionStorage.getItem("authUser"))?.token;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/moedas/precos`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status && response.status === 1) {
        return response.data;
      } else {
        toast.error(response.mensagem);
        console.log(response);
        return [];
      }
    } catch (error) {
      toast.error("Erro ao buscar dados!");
      console.log(error);
      return [];
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const retorno = await fetchMoedas();
      setMoedas(retorno);
    };

    if (!moedas) fetchData();
  }, [moedas, fetchMoedas]);

  const columns = useMemo(
    () => [
      {
        Header: "Moeda",
        accessor: "moeda",
        Cell: (cell) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <img
                  src={`https://api.fort.exchange/crypto-icons/${cell.row.original.moeda.toLowerCase()}.svg`}
                  alt=""
                  className="avatar-xxs"
                />
              </div>
              <Link to="#" className="currency_name flex-grow-1 ms-2 text-body">
                {cell.value}
              </Link>
            </div>
          </>
        ),
      },
      {
        Header: "Rede",
        accessor: "rede",
        filterable: true,
        Cell: (cell) => {
          return <b>{cell.value}</b>;
        },
      },
      {
        Header: "Faz DCA?",
        accessor: "cripto_td",
        filterable: true,
        Cell: (cell) => {
          return (
            <>
              {cell.value ? (
                <span className="badge badge-soft-success">Sim</span>
              ) : (
                <span className="badge badge-soft-danger">Não</span>
              )}
            </>
          );
        },
      },
      {
        Header: "Taxa Envio Contrato",
        accessor: "taxa_envio",
        filterable: false,
        Cell: (cell) => {
          return <>{cell.value} %</>;
        },
      },
      {
        Header: "Ações",
        filterable: false,
        Cell: (cell) => {
          return (
            <>
              <Link
                to="#"
                id="edit-tooltip"
                onClick={() => {
                  setRede(cell.row.original);
                  setShowModal(true);
                }}
                title="Editar"
              >
                <FeatherIcon icon="edit-2" className="icons" width="15" />
              </Link>
            </>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      {showModal && (
        <ModalEditarMoeda
          show={showModal}
          moeda={moeda}
          setShowModal={setShowModal}
          fetchMoedas={fetchMoedas}
          redes={redes}
          setMoedas={setMoedas}
        />
      )}
      {showCreateModal && (
        <ModalNovaMoeda
          show={showCreateModal}
          setShowModal={setShowCreateModal}
          fetchMoedas={fetchMoedas}
          redes={redes}
          setMoedas={setMoedas}
        />
      )}
      <Col lg={12}>
        <Card>
          <CardHeader className="d-flex align-items-center border-0">
            <h5 className="card-title mb-0 flex-grow-1">Moedas Cadastradas</h5>
            <div className="flex-shrink-0">
              <div className="flax-shrink-0 hstack gap-2">
                <Button
                  color="primary"
                  onClick={() => setShowCreateModal(true)}
                >
                  Nova Moeda
                </Button>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  padding: "20px",
                }}
              >
                <Spinner height={100} width={100} />
              </div>
            ) : (
              <TableContainer
                columns={columns}
                data={moedas || []}
                isGlobalFilter={false}
                isAddUserList={false}
                customPageSize={10}
                className="custom-header-css"
                divClass="table-responsive table-card mb-1"
                tableClass="align-middle table-nowrap"
                theadClass="table-light text-muted"
              />
            )}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default Moedas;
