import React, { useState, useCallback, useEffect } from "react";
import { Card, CardHeader, Col, Spinner, Button } from "reactstrap";
import ModalConta from "./ModalConta";
import { toast } from "react-toastify";
import axios from "axios";
import FeatherIcon from "feather-icons-react";

const MyCurrencies = ({
  contas,
  coins,
  loading,
  setContas,
  formatCurrency,
  blockedTime,
  verValores,
  setVerValores,
  verValoresAtivado,
}) => {
  const [loadingTable, setLoadingTable] = useState(loading);
  const [modalConta, setmodalConta] = useState(false);
  const [conta, setConta] = useState(null);
  const [arquivadas, setArquivadas] = useState(false);
  const [contasArquivadas, setContasArquivadas] = useState([]);
  const [contasLista, setContasLista] = useState(contas);

  const handleModalOpen = (contaItem) => {
    setConta(contaItem);
    setmodalConta(true);
  };

  const toggleArquivadas = async () => {
    if (!arquivadas) {
      setArquivadas(true);
    } else {
      setArquivadas(false);
    }
    setLoadingTable(true);
  };

  const reloadContas = useCallback(async () => {
    const clientData = JSON.parse(sessionStorage.getItem("authUser") || "{}");
    const userId = clientData.data._id ? clientData.data._id : 0;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/cripto-td/contas/saldos/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${clientData.token}`,
          },
        }
      );

      if (response && response.status === 1) {
        setContas(response.data.contas);
        setContasArquivadas(response.data.contas_arquivadas);
        if (!arquivadas) setContasLista(response.data.contas);
        else setContasLista(response.data.contas_arquivadas);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro ao buscar saldos");
      console.log(error);
    }
    setLoadingTable(false);
  }, [setContas, arquivadas]);

  useEffect(() => {
    if (!loading) {
      reloadContas();
    }
  }, [reloadContas, loadingTable, loading]);

  return (
    <React.Fragment>
      <ModalConta
        show={modalConta}
        conta={conta}
        coins={coins}
        formatCurrency={formatCurrency}
        setmodalConta={setmodalConta}
        setLoadingTable={setLoadingTable}
        toggleArquivadas={toggleArquivadas}
      />
      <Col xl={8}>
        <Card>
          <CardHeader className="align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">
              Minhas Contas{" "}
              {/* {verValoresAtivado && (
                <FeatherIcon
                  icon={!verValores ? "eye-off" : "eye"}
                  size="18"
                  color={verValores ? "green" : "red"}
                  title={verValores ? "Ocultar Valores" : "Ver Valores"}
                  onClick={() => setVerValores(!verValores)}
                />
              )} */}
            </h4>
            <div className="flex-shrink-0">
              <Button
                color={arquivadas ? "primary" : "danger"}
                onClick={toggleArquivadas}
                className="btn btn-sm"
                disabled={loadingTable}
              >
                {arquivadas ? "Ver Ativas" : "Ver Arquivadas"}
              </Button>
            </div>
          </CardHeader>
          <div className="card-body">
            <div className="table-responsive table-card">
              {loadingTable || loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    padding: "20px",
                  }}
                >
                  <Spinner height={100} width={100} />
                </div>
              ) : (
                <table className="table table-hover table-borderless table-centered align-middle table-nowrap mb-0">
                  <thead className="text-muted bg-soft-light">
                    <tr>
                      <th>Conta</th>
                      <th>Preço Médio</th>
                      <th>Lucro</th>
                      <th>Saldo R$</th>
                      <th>Comprado</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody
                    style={{ background: arquivadas ? "#FCEDED" : "white" }}
                  >
                    {(contasLista || []).map((item, key) => {
                      const moedaConta =
                        coins.find((coin) => coin?.moeda === item?.moeda) || {};
                      const taxa_envio = moedaConta?.taxa_envio / 100;
                      const comprado_brl = item?.comprado_brl / 0.9855;
                      const comprado_moeda =
                        item?.comprado_moeda * (1 - taxa_envio);
                      const valor_moeda =
                        comprado_moeda * moedaConta?.preco_atual_brl;
                      const lucro =
                        comprado_brl === 0
                          ? 0
                          : ((valor_moeda - comprado_brl) / comprado_brl) * 100;
                      return (
                        <tr key={key}>
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="me-2">
                                <img
                                  src={`https://api.fort.exchange/crypto-icons/${item?.moeda.toLowerCase()}.svg`}
                                  alt={`${item?.moeda} icon`}
                                  className="avatar-xxs"
                                />
                              </div>
                              <div>
                                <h6 className="fs-14 mb-0">{item?.nome}</h6>
                              </div>
                            </div>
                          </td>
                          <td>
                            {formatCurrency(item?.preco_medio)}
                          </td>
                          <td>
                            {lucro < 0 ? (
                              <h6 className={"fs-13 mb-0 text-danger"}>
                                <i
                                  className={
                                    "align-middle me-1 mdi mdi-trending-down"
                                  }
                                ></i>
                                {lucro.toFixed(2)} %
                              </h6>
                            ) : (
                              <h6 className={"fs-13 mb-0 text-success"}>
                                <i
                                  className={
                                    "align-middle me-1 mdi mdi-trending-up"
                                  }
                                ></i>
                                {lucro.toFixed(2)} %
                              </h6>
                            )}
                          </td>
                          <td>
                            {formatCurrency(item?.saldo_brl)}
                          </td>
                          <td>
                            {comprado_moeda.toFixed(8)}
                          </td>
                          <td>
                            <Button
                              onClick={() => {
                                if (!blockedTime) handleModalOpen(item);
                                else
                                  toast.error(
                                    "As contas não podem ser editadas no momento."
                                  );
                              }}
                              className="btn btn-sm btn-soft-secondary"
                            >
                              Editar
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default MyCurrencies;
