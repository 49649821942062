import React, { useState, useCallback, useEffect } from "react";
import { Col, Input, Label, Row, TabPane, Spinner, Button } from "reactstrap";
import QRCode from "qrcode.react";
import { toast } from "react-toastify";
import axios from "axios";

const Tab2fa = () => {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [qrCode, setQrCode] = useState(null);
  const [formulario2fa, setFormulario2fa] = useState(false);
  const [codigoVerificacao, setCodigoVerificacao] = useState(null);

  const [data2fa, setData2fa] = useState(null);

  const firstVerify = async (userDados) => {
    const token = userDados?.token;
    if (!token) {
      toast.error("Token não encontrado");
      return;
    }

    setLoading(true);
    try {
      const data = {
        code: codigoVerificacao,
      };
      const url = `${process.env.REACT_APP_API_URL}/secrets2fa/first-verify`;
      const response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status && response.status === 1) {
        setData2fa(response.dados);
        setFormulario2fa(false);
        toast.success("2FA ativado com sucesso");
      } else {
        toast.error(response.mensagem);
        console.error(response);
      }
    } catch (error) {
      console.error("Erro ao cadastrar 2fa:", error);
    }

    setLoading(false);
  };

  const cadastrar2fa = async (userDados) => {
    const token = userDados?.token;
    if (!token) {
      toast.error("Token não encontrado");
      return;
    }

    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_API_URL}/secrets2fa`;
      const response = await axios.post(
        url,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status && response.status === 1) {
        setData2fa(response.dados?.secret);
        setQrCode(response.dados?.qr_code);
      } else {
        toast.error(response.mensagem);
        console.error(response);
      }
    } catch (error) {
      console.error("Erro ao cadastrar 2fa:", error);
    }
    setLoading(false);
  };

  const desativar2fa = async (userDados) => {
    const token = userDados?.token;
    if (!token) {
      toast.error("Token não encontrado");
      return;
    }

    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_API_URL}/secrets2fa/${userDados?.data?._id}`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status && response.status === 1) {
        setData2fa(null);
        setQrCode(null);
        setFormulario2fa(false);
        toast.success("2FA desativado com sucesso");
        await get2faStatus(userDados);
      } else {
        toast.error(response.mensagem);
        console.error(response);
      }
    } catch (error) {
      console.error("Erro ao desativar 2fa:", error);
    }
    setLoading(false);
  };

  const get2faStatus = useCallback(async (userDados) => {
    const token = userDados?.token;
    if (!token) {
      toast.error("Token não encontrado");
      return;
    }
    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_API_URL}/secrets2fa`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status && response.status === 1) {
        setData2fa(response.dados);
      } else {
        toast.error(response.mensagem);
        console.error(response);
      }
    } catch (error) {
      console.error("Erro ao buscar status 2fa:", error);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const user = JSON.parse(sessionStorage.getItem("authUser"));
      setUserData(user);
      await get2faStatus(user);
    };
    if (!userData) fetch();
  }, [userData, get2faStatus]);

  return (
    <TabPane tabId="5">
      {loading ? (
        <Spinner />
      ) : (
        <Row>
          <div className="mb-3">
            <h5>Autenticação de 2 Fatores</h5>
          </div>
          {!data2fa?.id ? (
            <Col lg={6}>
              <Row>
                <Col lg={6}>Ative a sua Autenticação de 2 Fatores</Col>
                <Col lg={6}>
                  <Button
                    color="primary"
                    onClick={() => cadastrar2fa(userData)}
                  >
                    Ativar 2FA
                  </Button>
                </Col>
              </Row>
            </Col>
          ) : !data2fa?.secret || data2fa?.secret === "" ? (
            <Col lg={6}>
              <Row>
                <Col lg={6}> O seu 2fa está ativo e funcional</Col>
                <Col lg={6}>
                  <Button
                    disabled={loading}
                    color="danger"
                    onClick={() => desativar2fa(userData)}
                  >
                    Desativar 2FA
                  </Button>
                </Col>
              </Row>
            </Col>
          ) : (!data2fa?.label || data2fa.label === "") && !qrCode ? (
            <Col lg={6}>
              <Row>
                <Col lg={6} className="text-danger">
                  Houve um erro ao gerar o QR Code. Tente novamente
                </Col>
                <Col lg={6}>
                  <Button
                    disabled={loading}
                    color="danger"
                    onClick={() => cadastrar2fa(userData)}
                  >
                    Ativar 2FA
                  </Button>
                </Col>
              </Row>
            </Col>
          ) : (!data2fa?.label || data2fa.label === "") &&
            qrCode &&
            !formulario2fa ? (
            <>
              <Col lg={3}></Col>
              <Col lg={6} className="d-flex flex-column align-items-center">
                <Label htmlFor="2fa">
                  Leia o código QR abaixo e aperte em continuar
                </Label>
                <QRCode value={qrCode} size={256} className="mt-2" />
                <div className="mt-5">
                  <Button
                    color="primary"
                    onClick={() => setFormulario2fa(true)}
                    disabled={loading}
                  >
                    Continuar
                  </Button>
                </div>
              </Col>
              <Col lg={3}></Col>
            </>
          ) : formulario2fa ? (
            <>
              <Col lg={3}></Col>
              <Col lg={6} className="d-flex flex-column align-items-center">
                <Label htmlFor="2fa">Digite o código de verificação</Label>
                <Input
                  type="text"
                  className="mt-2 text-center"
                  id="2fa"
                  value={codigoVerificacao}
                  onChange={(e) =>
                    setCodigoVerificacao(e.target.value.replace(/\D/g, ""))
                  }
                  maxLength={6}
                />
                <div className="mt-5">
                  <Button
                    disabled={
                      !codigoVerificacao ||
                      codigoVerificacao.length !== 6 ||
                      loading
                    }
                    color="success"
                    onClick={() => firstVerify(userData)}
                  >
                    Enviar Código
                  </Button>
                </div>
              </Col>
              <Col lg={3}></Col>
            </>
          ) : (
            <Col lg={6}>
              <Row>
                <Col lg={6}> O seu 2fa está ativo e funcional</Col>
                <Col lg={6}>
                  <Button
                    disabled={loading}
                    color="danger"
                    onClick={() => desativar2fa(userData)}
                  >
                    Desativar 2FA
                  </Button>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      )}
    </TabPane>
  );
};

export default Tab2fa;
