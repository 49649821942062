import React, { useState, useCallback, useEffect } from "react";
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
} from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";

const ModalStatus = ({
  operacao,
  setOperacao,
  setmodalStatus,
  modalStatus,
  setShouldFetch,
  formatCurrency,
}) => {
  const [loading, setLoading] = useState(false);

  const [novoStatus, setNovoStatus] = useState(operacao?.status);
  const [converter, setConverter] = useState(operacao?.converter ?? false);

  const mudarStatus = useCallback(
    async (operacaoId) => {
      setLoading(true);
      const user = JSON.parse(sessionStorage.getItem("authUser"));
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/operacao/status-update/${operacaoId}`,
          {
            status: novoStatus,
            converter: converter,
          },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        if (response.status && response.status === 1) {
          toast.success(response.mensagem);
          const novaOperacao = operacao;
          novaOperacao.status = novoStatus;
          novaOperacao.converter = converter;
          setOperacao(operacao);
          setmodalStatus(false);
          setShouldFetch(true);
        } else {
          toast.error(response.mensagem);
          console.log(response);
        }
      } catch (error) {
        toast.error("Erro na API.");
        console.log(error);
      }
      setLoading(false);
    },
    [
      setShouldFetch,
      novoStatus,
      setmodalStatus,
      converter,
      operacao,
      setOperacao,
    ]
  );

  useEffect(() => {
    if (!operacao) return;
    if (!novoStatus) {
      setNovoStatus(operacao?.status);
      setConverter(operacao?.converter);
    }
  }, [novoStatus, operacao]);

  return (
    <Modal
      size="lg"
      isOpen={modalStatus}
      backdrop={"static"}
      centered
      toggle={() => {
        setmodalStatus(false);
      }}
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          setmodalStatus(false);
        }}
      >
        Dados da Operação
      </ModalHeader>
      <ModalBody>
        <form>
          <div className="row g-3">
            <Col md={6}>
              <Label className="form-Label">Cliente</Label>
              <Input
                type="text"
                className="form-control"
                value={operacao?.user?.name}
                onChange={() => {}}
                disabled
              />
            </Col>
            <Col md={6}>
              <Label className="form-Label">Fornecedor</Label>
              <Input
                type="text"
                className="form-control"
                value={operacao?.fornecedor?.name}
                onChange={() => {}}
                disabled
              />
            </Col>
            <Col md={6}>
              <Label className="form-Label">Moeda Saída</Label>
              <Input
                type="text"
                className="form-control"
                value={
                  operacao?.moeda_saida_dados
                    ? `${operacao?.moeda_saida_dados?.moeda} (${operacao?.moeda_saida_dados?.rede})`
                    : "BRL"
                }
                onChange={() => {}}
                disabled
              />
            </Col>
            <Col md={6}>
              <Label className="form-Label">Quantidade</Label>
              <Input
                type="text"
                className="form-control"
                value={
                  operacao?.moeda_saida === "BRL"
                    ? formatCurrency(operacao?.quantidade)
                    : `${parseFloat(operacao?.quantidade).toFixed(
                        operacao?.moeda_saida_dados?.decimais
                      )} ${operacao?.moeda_saida}`
                }
                onChange={() => {}}
                disabled
              />
            </Col>
            <Col md={6}>
              <Label className="form-Label">Moeda Destino</Label>
              <Input
                type="text"
                className="form-control"
                value={
                  operacao?.moeda_destino_dados
                    ? `${operacao?.moeda_destino_dados?.moeda} (${operacao?.moeda_destino_dados?.rede})`
                    : "BRL"
                }
                onChange={() => {}}
                disabled
              />
            </Col>
            <Col md={6}>
              <Label className="form-Label">Total</Label>
              <Input
                type="text"
                className="form-control"
                value={
                  operacao?.moeda_destino === "BRL"
                    ? formatCurrency(operacao?.total)
                    : `${parseFloat(operacao?.total).toFixed(
                        operacao?.moeda_destino_dados?.decimais
                      )} ${operacao?.moeda_destino}`
                }
                onChange={() => {}}
                disabled
              />
            </Col>
            <Col md={6}>
              <Label className="form-Label">Taxa Fort</Label>
              <Input
                type="text"
                className="form-control"
                value={
                  operacao?.moeda_base === "BRL"
                    ? formatCurrency(operacao?.taxa_fort)
                    : `${parseFloat(operacao?.taxa_fort).toFixed(
                        operacao?.moeda_destino_dados?.decimais
                      )} ${operacao?.moeda_destino}`
                }
                onChange={() => {}}
                disabled
              />
            </Col>
            <Col md={6}>
              <Label className="form-Label">Taxa Indicação</Label>
              <Input
                type="text"
                className="form-control"
                value={formatCurrency(operacao?.taxa_indicacao)}
                onChange={() => {}}
                disabled
              />
            </Col>
            <Col md={6}>
              <Label className="form-Label">Taxa Rede</Label>
              <Input
                type="text"
                className="form-control"
                value={formatCurrency(operacao?.taxa_rede_brl)}
                onChange={() => {}}
                disabled
              />
            </Col>
            <Col md={6}>
              <div className="mt-4 ml-3">
                <Input
                  type="checkbox"
                  className="form-check-input m-2"
                  value={converter}
                  checked={converter}
                  onChange={() => {
                    setConverter(!converter);
                  }}
                  disabled={operacao?.status === "completada"}
                />
                <Label className="form-check-label p-2">
                  Converter na exchange?
                </Label>
              </div>
            </Col>
            <Col md={6}>
              <Label className="form-Label">Status</Label>
              <Input
                type="select"
                className="form-control"
                value={novoStatus}
                onChange={(e) => setNovoStatus(e.target.value)}
                disabled={operacao?.status === "completada"}
              >
                <option value="aguardando">Aguardando</option>
                <option value="completada">Completada</option>
                <option value="estornada">Estornada</option>
                <option value="cancelada">Cancelada</option>
              </Input>
            </Col>
            <Col md={12} className="d-flex justify-content-end">
              <Button
                color="primary"
                className="btn-block mt-4"
                onClick={() => {
                  mudarStatus(operacao?.id);
                }}
                disabled={loading}
              >
                Atualizar
              </Button>
            </Col>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};
export default ModalStatus;
