import React, { useMemo, useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Spinner, Button } from "reactstrap";

import TableContainer from "../../../Components/Common/TableContainer";
import ModalSocio from "./ModalSocio";
import { toast } from "react-toastify";
import axios from "axios";

const AllOrdersSocios = ({ orderList, loading, setShouldFetch }) => {
  const user = JSON.parse(sessionStorage.getItem("authUser"));const [modalStore, setmodalStore] = useState(false);

  const toggleModalCadastro = async () => {
    if (!modalStore) {
      setmodalStore(true);
    } else {
      setmodalStore(false);
    }
  };
  const removerSocio = useCallback(
    async (socio_id) => {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}/lojista/socios/${socio_id}`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        if (response.status && response.status === 1) {
          toast.success(response.mensagem);
          setShouldFetch(true);
        } else {
          toast.error(response.mensagem);
          console.log(response);
        }
      } catch (error) {
        toast.error("Erro na API.");
        console.log(error);
      }
    },
    [setShouldFetch, user]
  );
  
  function formatName(name) {
    let lowerCaseName = name.toLowerCase();
    let properName = lowerCaseName.replace(
      /\b\w/g,
      function (letter, index, fullText) {
        const previousLetter = fullText[index - 1];
        const isWordAfterApostrophe = previousLetter === "'";
        if (isWordAfterApostrophe) {
          return letter;
        }
        const word = fullText.slice(index).match(/^\w+/)[0];
        const lowerCaseWords = ["de", "da", "do", "dos", "das"];
        if (lowerCaseWords.includes(word)) {
          return letter;
        }
        return letter.toUpperCase();
      }
    );

    // Converte a letra após um apóstrofo para maiúscula
    properName = properName.replace(/'\w/g, function (letter) {
      return letter.toUpperCase();
    });

    return properName;
  }

  const columns = useMemo(
    () => [
      {
        Header: "Sócio",
        accessor: "nome_completo",
        filterable: true,
        Cell: (cell) => (
          <>
            <div className="d-flex align-items-center">
              <Link to="#" className="currency_name flex-grow-1 ms-2 text-body">
                <b style={{ color: "darkBlue" }}>
                  {cell.value ? formatName(cell.value) : "SEM KYC"}
                </b>
              </Link>
            </div>
          </>
        ),
      },
      {
        Header: "E-mail",
        accessor: "email",
        filterable: true,
        Cell: (cell) => {
          return <span style={{ color: "darkGreen" }}>{cell.value}</span>;
        },
      },
      {
        Header: "CPF/ CNPJ",
        accessor: "cpf_cnpj",
        filterable: true,
        Cell: (cell) => {
          return cell.value;
        },
      },
      {
        Header: "Status KYC",
        accessor: "nivel_kyc",
        filterable: true,
        Cell: (cell) => {
          return (
            <>
              {cell.value === 0 ? (
                <span className="badge bg-danger">
                  Não Aprovado
                </span>
              ) : (
                <span className="badge bg-success">Aprovado</span>
              )}
            </>
          );
        },
      },
      {
        Header: "Ações",
        accessor: "user_id",
        filterable: false,
        Cell: (order) => {
          return (
            <>
              <Button
                color="danger"
                className="btn btn-sm ms-1"
                onClick={() => removerSocio(order.row.original.id)}
              >
                Remover Sócio
              </Button>
            </>
          );
        },
      },
    ],
    [removerSocio]
  );
  return (
    <React.Fragment>
      <ModalSocio
        show={modalStore}
        toggle={() => {
          toggleModalCadastro();
        }}
        setShouldFetch={setShouldFetch}
        setmodalStore={setmodalStore}
        lojista_id={user.data._id}
      />
      <Col lg={12}>
        <Card>
          <CardHeader className="d-flex align-items-center border-0">
            <h5 className="card-title mb-0 flex-grow-1">Sócios de {user.data.lojistaData.razao_social}</h5>
            <div className="flex-shrink-0">
              <div className="flax-shrink-0 hstack gap-2">
                <Button className="btn btn-info" onClick={toggleModalCadastro}>
                  Novo Sócio
                </Button>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Spinner height={100} width={100} />
              </div>
            ) : (
              <TableContainer
                columns={columns}
                data={orderList || []}
                isGlobalFilter={true}
                isAddUserList={false}
                customPageSize={20}
                className="custom-header-css"
                divClass="table-responsive table-card mb-4"
                tableClass="align-middle table-nowrap mb-0"
                theadClass="table-light table-nowrap"
                thClass="table-light text-muted"
              />
            )}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default AllOrdersSocios;
