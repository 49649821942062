import React, { useMemo, useCallback } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Spinner, Button } from "reactstrap";

import TableContainer from "../../../Components/Common/TableContainer";
import moment from "moment";
import { toast } from "react-toastify";
import axios from "axios";

const AllOrders = ({ isCliente, orderList, loading, setShouldFetch }) => {
  function formatName(name) {
    let lowerCaseName = name.toLowerCase();
    let properName = lowerCaseName.replace(
      /\b\w/g,
      function (letter, index, fullText) {
        const previousLetter = fullText[index - 1];
        const isWordAfterApostrophe = previousLetter === "'";
        if (isWordAfterApostrophe) {
          return letter;
        }
        const word = fullText.slice(index).match(/^\w+/)[0];
        const lowerCaseWords = ["de", "da", "do", "dos", "das"];
        if (lowerCaseWords.includes(word)) {
          return letter;
        }
        return letter.toUpperCase();
      }
    );

    // Converte a letra após um apóstrofo para maiúscula
    properName = properName.replace(/'\w/g, function (letter) {
      return letter.toUpperCase();
    });

    return properName;
  }

  const entregarComissoes = useCallback(
    async (id) => {
      const user = JSON.parse(sessionStorage.getItem("authUser"));
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/comissao-indicacao/entregar/${id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        if (response.status && response.status === 1) {
          toast.success(response.mensagem);
          setShouldFetch(true);
        } else {
          toast.error(response.mensagem);
          console.log(response);
        }
      } catch (error) {
        toast.error("Erro ao buscar operações!");
        console.log(error);
      }
    },
    [setShouldFetch]
  );

  const formatCurrency = (value) => {
    if (!value) {
      return "";
    }
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };
  const columns = useMemo(() => {
    var baseColumns = [
      {
        Header: "Data",
        accessor: "updated_at",
        filterable: true,
        Cell: (cell) => {
          const date = moment(cell.value);
          return (
            <>
              <b>{date.format("DD/MM/YYYY")} </b>
              <small className="text-muted">{date.format("HH:mm")}</small>{" "}
            </>
          );
        },
      },
      {
        Header: "Cliente",
        accessor: "user.nome_completo",
        filterable: true,
        Cell: (cell) => (
          <>
            <div className="d-flex align-items-center">
              <Link to="#" className="currency_name flex-grow-1 ms-2 text-body">
                {cell.value === null ? "" : formatName(cell.value)}
              </Link>
            </div>
          </>
        ),
      },
      {
        Header: "Valor",
        accessor: "comissao_brl",
        filterable: false,
        Cell: (cell) => {
          return <>{formatCurrency(cell.value)}</>;
        },
      },
      {
        Header: "Status",
        accessor: "entregue",
        filterable: true,
        Cell: (cell) => {
          return (
            <>
              {parseInt(cell.value) === 0 ? (
                <span className="badge badge-soft-warning text-uppercase">
                  Pendente
                </span>
              ) : (
                <span className="badge badge-soft-success text-uppercase">
                  Entregue
                </span>
              )}
            </>
          );
        },
      },
    ];
    if (!isCliente) {
      baseColumns.push({
        Header: "Ações",
        filterable: false,
        Cell: (order) => {
          return (
            <>
              {parseInt(order.row.original.entregue) === 0 ? (
                <Button color="success" onClick={() => {entregarComissoes(order.row.original.id);}} className="btn-sm">
                  Entregar
                </Button>
              ) : (
                <Button color="dark" className="btn-sm" disabled>
                  Entregue
                </Button>
              )}
            </>
          );
        },
      });
    }
    return baseColumns;
  }, [isCliente, entregarComissoes]);
  return (
    <React.Fragment>
      <Col lg={12}>
        <Card>
          <CardHeader className="d-flex align-items-center border-0">
            <h5 className="card-title mb-0 flex-grow-1">
              {isCliente ? "Suas Comissões" : "Comissões"}
            </h5>
            {/* {!isCliente && (
              <div className="flex-shrink-0">
                <div className="flax-shrink-0 hstack gap-2">
                  <Button
                    color="primary"
                    onClick={() => {
                      entregarComissoes(0);
                    }}
                  >
                    Entregar Comissões
                  </Button>
                </div>
              </div>
            )} */}
          </CardHeader>
          <CardBody>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Spinner height={100} width={100} />
              </div>
            ) : (
              <TableContainer
                columns={columns}
                data={orderList || []}
                isGlobalFilter={true}
                isAddUserList={false}
                customPageSize={20}
                className="custom-header-css"
                divClass="table-responsive table-card mb-4"
                tableClass="align-middle table-nowrap mb-0"
                theadClass="table-light table-nowrap"
                thClass="table-light text-muted"
              />
            )}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default AllOrders;
