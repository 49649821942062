import React, { useState, useCallback, useEffect } from "react";
import { Col, Input, Label, Row, TabPane, Spinner } from "reactstrap";
import { toast } from "react-toastify";
import axios from "axios";
import MatiButton from "./MatiButton";

const TabPerfil = () => {
  const [loading, setLoading] = useState(false);
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [cpf, setCpf] = useState("");
  const [situacao, setSituacao] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [rg, setRg] = useState("");
  const [telefone, setTelefone] = useState("");
  const [limiteAnual, setLimiteAnual] = useState(0);
  const [verificacao, setVerificacao] = useState("");
  const [indicadoPor, setIndicadoPor] = useState("");
  const [nivelKyc, setNivelKyc] = useState("");
  const [endereco, setEndereco] = useState("");
  const [activateMati, setActivateMati] = useState(false);

  const fetchClientData = useCallback(async (user_id) => {
    setLoading(true);
    const token = JSON.parse(sessionStorage.getItem("authUser"))?.token;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/client/get-client-data/${user_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.clientData) {
        return response;
      } else {
        toast.error(response.message);
        console.log(response);
        return [];
      }
    } catch (error) {
      toast.error("Erro ao buscar dados: " + error, {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: false,
        className: "bg-danger text-white",
      });
      return [];
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const retorno = await fetchClientData(
        JSON.parse(sessionStorage.getItem("authUser"))?.data?._id
      );
      setNome(retorno.clientData.nome_completo);
      setEmail(retorno.clientData.email);
      setCpf(retorno.clientData.cpf_cnpj);
      setSituacao(retorno.clientData.status_cpf);
      setDataNascimento(formatDate(retorno.clientData.nascimento));
      setRg(retorno.clientData.rg);
      setTelefone(formatPhoneNumber(retorno.clientData.telefone));
      setLimiteAnual(retorno.clientData.limite_anual_brl);
      setVerificacao(
        retorno.clientData.metamap_status_verification === "rejected"
          ? "Reprovado"
          : retorno.clientData.metamap_status_verification === "verified"
          ? "Aprovado"
          : retorno.clientData.metamap_status_verification === "reviewNeeded"
          ? "Revisão Pendente"
          : "Aguardando"
      );
      setIndicadoPor(
        retorno.clientData.indicacao === "fort"
          ? "Sem Indicação"
          : retorno.clientData.indicacao
      );
      setNivelKyc(retorno.kycNome);
      setEndereco(retorno.clientData.endereco);
      setActivateMati(
        retorno.clientData.metamap_status_verification !== "rejected" &&
          retorno.clientData.metamap_status_verification !== "verified" &&
          retorno.clientData.metamap_status_verification !== "reviewNeeded"
      );
    };
    fetchData();
  }, [fetchClientData]);

  const getStatusColor = (status) => {
    switch (status) {
      case "Aprovado":
        return "darkgreen";
      case "Revisão Pendente":
        return "darkorange";
      case "Aguardando":
        return "darkblue";
      case "Reprovado":
        return "darkred";
      default:
        return "inherit";
    }
  };

  const formatCurrency = (value) => {
    if (!value) {
      return "R$ 0,00";
    }
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber || !phoneNumber.startsWith("55")) {
      return phoneNumber;
    }

    const formattedNumber = phoneNumber.replace(
      /^(\d{2})(\d{2})(\d{1})(\d{4})(\d{4})$/,
      "+$1 ($2) $3 $4-$5"
    );
    return formattedNumber;
  };

  const formatDate = (dateString) => {
    if (!dateString) {
      return "";
    }

    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
  };

  return (
    <TabPane tabId="1">
      {activateMati ? (
        <Row>
          <Col
            lg={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "20px",
            }}
          >
            <MatiButton email={email} />
          </Col>
        </Row>
      ) : null}
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            padding: "20px",
          }}
        >
          <Spinner height={100} width={100} />
        </div>
      ) : (
        <Row>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label">Nome Completo</Label>
              <Input
                disabled
                readOnly
                type="text"
                className="form-control"
                defaultValue={nome}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label">E-mail</Label>
              <Input
                disabled
                readOnly
                type="email"
                className="form-control"
                defaultValue={email}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label">CPF</Label>
              <Input
                disabled
                readOnly
                type="text"
                className="form-control"
                defaultValue={cpf}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label">Situação do CPF</Label>
              <Input
                disabled
                readOnly
                type="text"
                className="form-control"
                defaultValue={situacao}
              />
            </div>
          </Col>

          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label">RG</Label>
              <Input
                disabled
                readOnly
                type="text"
                className="form-control"
                defaultValue={rg}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label">Nascimento</Label>
              <Input
                disabled
                readOnly
                type="text"
                className="form-control"
                defaultValue={dataNascimento}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label">Telefone</Label>
              <Input
                disabled
                readOnly
                type="text"
                className="form-control"
                defaultValue={telefone}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label">Limite Mensal</Label>
              <Input
                disabled
                readOnly
                type="text"
                className="form-control"
                defaultValue={formatCurrency(limiteAnual / 12)}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label">Limite Anual</Label>
              <Input
                disabled
                readOnly
                type="text"
                className="form-control"
                defaultValue={formatCurrency(limiteAnual)}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label">Verificação KYC</Label>
              <Input
                disabled
                readOnly
                type="text"
                className="form-control"
                style={{ color: getStatusColor(verificacao) }}
                defaultValue={verificacao}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label">Indicado Por</Label>
              <Input
                disabled
                readOnly
                type="text"
                className="form-control"
                defaultValue={indicadoPor}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label">Nível KYC</Label>
              <Input
                disabled
                readOnly
                type="text"
                className="form-control"
                defaultValue={nivelKyc}
              />
            </div>
          </Col>
          <Col lg={12}>
            <div className="mb-3 pb-2">
              <Label className="form-label">Endereço</Label>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea"
                disabled
                readOnly
                rows="3"
                defaultValue={endereco}
              ></textarea>
            </div>
          </Col>
        </Row>
      )}
    </TabPane>
  );
};

export default TabPerfil;
