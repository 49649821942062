import React, { useEffect, useState, useCallback } from "react";
import { useParams } from 'react-router-dom';
import { Container, Row } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import AllOrders from "./AllOrders";
import axios from "axios";
import { toast } from "react-toastify";

const Logs = ({isLojista, isCliente}) => {
  const [loading, setLoading] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [shouldFetch, setShouldFetch] = useState(true);

  const { userId } = useParams();
  const user_id = userId || 1;
  

  const user = JSON.parse(sessionStorage.getItem("authUser"));

  const getLogs = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/logs/system-logs/${user_id}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (response.status && response.status === 1) {
        return response.data;
      } else {
        console.log(response);
        toast.error(response.mensagem);
        return [];
      }
    } catch (error) {
      toast.error("Erro ao buscar operações.");
      console.log(error);
      return [];
    } finally {
      setLoading(false);
    }
  }, [user.token, user_id]);

  useEffect(() => {
    if (shouldFetch && orderList.length === 0) {
      setLoading(true);
      const fetchData = async () => {
        const retorno = await getLogs();
        setOrderList(retorno);
        setShouldFetch(false);
      };

      fetchData();
      setLoading(false);
    }
  }, [shouldFetch, user_id, user.data._id, getLogs, orderList]);

  document.title = "Logs | Painel Fort Exchange";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Logs"
            pageTitle={
              isLojista ? "Lojista" : isCliente ? "Clientes Fort" : "Aplicações"
            }
          />
          <Row>
            <AllOrders
              user_id={user_id}
              orderList={orderList}
              loading={loading}
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Logs;
