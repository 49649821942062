import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Spinner,
  Button,
  Badge,
} from "reactstrap";

import TableContainer from "../../../Components/Common/TableContainer";
import ModalRetirada from "./ModalRetirada";
import ModalProcessar from "./ModalProcessar";
import moment from "moment";
import { toast } from "react-toastify";
import axios from "axios";

import btc from "../../../assets/images/svg/crypto-icons/btc.svg";
import matic from "../../../assets/images/svg/crypto-icons/matic.svg";
import eth from "../../../assets/images/svg/crypto-icons/eth.svg";
import usdc from "../../../assets/images/svg/crypto-icons/usdc.svg";

const AllOrders = ({
  isCliente,
  isLojista,
  user,
  orderList,
  loading,
  setShouldFetch,
}) => {
  const [modalOrder, setmodalOrder] = useState(false);
  const [modalProcessar, setmodalProcessar] = useState(false);
  const [retirada, setRetirada] = useState({});
  const [invoiceId, setInvoiceId] = useState(0);
  const [loadingStatus, setLoading] = useState(false);
  const [coins, setCoins] = useState([]);

  const userData = JSON.parse(sessionStorage.getItem("authUser") || "{}");

  function formatName(name) {
    let lowerCaseName = name.toLowerCase();
    let properName = lowerCaseName.replace(
      /\b\w/g,
      function (letter, index, fullText) {
        const previousLetter = fullText[index - 1];
        const isWordAfterApostrophe = previousLetter === "'";
        if (isWordAfterApostrophe) {
          return letter;
        }
        const word = fullText.slice(index).match(/^\w+/)[0];
        const lowerCaseWords = ["de", "da", "do", "dos", "das"];
        if (lowerCaseWords.includes(word)) {
          return letter;
        }
        return letter.toUpperCase();
      }
    );

    // Converte a letra após um apóstrofo para maiúscula
    properName = properName.replace(/'\w/g, function (letter) {
      return letter.toUpperCase();
    });

    return properName;
  }

  const fetchPrices = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/moedas/precos`
      );

      setCoins(response.data);
    } catch (error) {
      console.error("Houve um erro ao buscar os preços das moedas!", error);
    }
  }, []);

  const getExtensao = useCallback((arquivo) => {
    const arqPartes = arquivo.split(".");
    return arqPartes[arqPartes.length - 1];
  }, []);

  const downloadRecibo = useCallback(async (id, ext) => {
    const user = JSON.parse(sessionStorage.getItem("authUser") || "{}");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/retiradas-cripto/baixar-recibo/${id}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
          responseType: "blob",
        }
      );

      // Verifica se a resposta é um arquivo válido
      if (response.size === 0) {
        toast.error("O arquivo baixado está vazio");
        return;
      }

      if (response.type && response.type === "application/json") {
        const reader = new FileReader();
        reader.onload = () => {
          const result = JSON.parse(reader.result);
          console.log("Erro na API:", result);
          toast.error(`Erro: ${result.mensagem}`);
        };
        reader.readAsText(response);
        return;
      }

      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `recibo_${id}.${ext}`);
      document.body.appendChild(link);
      link.click();

      // Limpa a URL e remove o link do DOM
      link.remove();
      window.URL.revokeObjectURL(url);

      toast.success("Recibo baixado com sucesso");
    } catch (error) {
      console.error("Erro ao baixar o recibo:", error);
      toast.error("Erro ao baixar o recibo.");
    }
  }, []);

  const cancelarRetirada = useCallback(
    async (id) => {
      const user = JSON.parse(sessionStorage.getItem("authUser") || "{}");

      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}/retiradas-cripto/cancelar/${id}`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );

        if (response.status && response.status === 1) {
          toast.success(response.mensagem);
          setShouldFetch(true);
        } else {
          toast.error(response.mensagem);
        }
      } catch (error) {
        toast.error("Erro ao cancelar a retirada");
        console.log(error);
      }
    },
    [setShouldFetch]
  );

  const updateOrder = useCallback(
    async (order_id) => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/operacoes/show/${order_id}`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        if (response.status && response.status === 1) {
          console.log(response);
          setShouldFetch(true);
        } else {
          console.log(response);
          toast.error(response.mensagem);
        }
      } catch (error) {
        toast.error("Erro ao buscar operações.");
        console.log(error);
      }
      setLoading(false);
    },
    [user.token, setShouldFetch]
  );

  useEffect(() => {
    if (invoiceId === 0) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const getInvoiceId = urlParams.get("invoiceId");

      if (getInvoiceId !== null) {
        setInvoiceId(parseInt(getInvoiceId));
        updateOrder(getInvoiceId);
      }
    }
    fetchPrices();
  }, [invoiceId, updateOrder, fetchPrices]);

  const columns = useMemo(() => {
    const formatCurrency = (value) => {
      if (!value) {
        return "";
      }
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    };

    const nomeColumn =
      userData.data.role === "Admin"
        ? [
            {
              Header: "Nome",
              accessor: "user.name",
              filterable: true,
              Cell: (cell) => (
                <Link
                  to={`/clientes/${cell.row.original.user_id}`}
                  target="_self"
                >
                  <b>{formatName(cell.value)}</b>
                </Link>
              ),
            },
          ]
        : [];

    const initialColumns = [
      {
        Header: "Data",
        accessor: "created_at",
        filterable: true,
        Cell: (cell) => {
          const date = moment(cell.value);
          return (
            <>
              <b>{date.format("DD/MM/YYYY")} </b>
              <small className="text-muted">{date.format("HH:mm")}</small>{" "}
            </>
          );
        },
      },
      ...nomeColumn,
      {
        Header: "Moeda",
        accessor: "moeda",
        filterable: true,
        Cell: (cell) => {
          const icons = {
            BTC: btc,
            ETH: eth,
            USDC: usdc,
            MATIC: matic,
          };
          return (
            <>
              <div className="d-flex align-items-center">
                <img src={icons[cell.value]} alt={cell.value} width={20} />
                &nbsp;&nbsp;
                <b>{cell.value}</b>
              </div>
            </>
          );
        },
      },
      {
        Header: "Valor Moeda",
        accessor: "amount",
        filterable: true,
        Cell: (cell) => {
          return (
            <b style={{ color: "darkBlue" }}>
              {parseFloat(cell.value).toFixed(8)}
            </b>
          );
        },
      },
      {
        Header: "Valor R$",
        accessor: "brl_amount",
        filterable: false,
        Cell: (cell) => {
          return <>{formatCurrency(cell.value)}</>;
        },
      },
      {
        Header: "Tipo Retirada",
        accessor: "tipo",
        filterable: false,
        Cell: (cell) => {
          return (
            <>
              {cell.value === "cripto" ? (
                <Badge color="info">Cripto</Badge>
              ) : (
                <Badge color="secondary">PIX</Badge>
              )}
            </>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        Cell: (order) => {
          return (
            <>
              {loadingStatus &&
              parseInt(order.row.original.id) === parseInt(invoiceId) ? (
                <Spinner />
              ) : order.row.original.status === "em_fila" ? (
                <Badge color="primary">Em Fila</Badge>
              ) : order.row.original.status === "processado" ? (
                <Badge color="success">Processada</Badge>
              ) : order.row.original.status === "cancelado" ? (
                <Badge color="dark">Cancelada</Badge>
              ) : (
                <Badge color="danger">Cancelada</Badge>
              )}
            </>
          );
        },
      },
      {
        Header: "Ações",
        filterable: false,
        Cell: (order) => {
          return (
            <>
              {userData.data.role === "Admin" &&
                order.row.original.status === "em_fila" && (
                  <>
                    <Button
                      color="danger"
                      className="btn btn-sm btn-success"
                      onClick={() => cancelarRetirada(order.row.original.id)}
                      disabled={loadingStatus}
                    >
                      Cancelar
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      color="info"
                      className="btn btn-sm btn-success"
                      onClick={() => {
                        setRetirada(order.row.original);
                        setmodalProcessar(true);
                      }}
                      disabled={loadingStatus}
                    >
                      Processar
                    </Button>
                  </>
                )}
              {order.row.original.status === "processado" &&
              order.row.original.tipo === "cripto" ? (
                <Link
                  to={`${order.row.original.txid}`}
                  target="_blank"
                  className="btn btn-sm btn-primary"
                >
                  Ver Transação
                </Link>
              ) : order.row.original.status === "processado" &&
                order.row.original.tipo === "pix" ? (
                <Button
                  color="primary"
                  className="btn btn-sm"
                  onClick={() =>
                    downloadRecibo(
                      order.row.original.id,
                      getExtensao(order.row.original.txid)
                    )
                  }
                >
                  Download Recibo
                </Button>
              ) : null}
            </>
          );
        },
      },
    ];
    return initialColumns;
  }, [
    invoiceId,
    loadingStatus,
    userData?.data?.role,
    getExtensao,
    downloadRecibo,
    cancelarRetirada,
  ]);
  return (
    <React.Fragment>
      <ModalRetirada
        lojista_id={user?.data?._id}
        setmodalOrder={setmodalOrder}
        show={modalOrder}
        setShouldFetch={setShouldFetch}
        coins={coins}
      />
      <ModalProcessar
        lojista_id={user?.data?._id}
        setmodalProcessar={setmodalProcessar}
        show={modalProcessar}
        setShouldFetch={setShouldFetch}
        retirada={retirada}
      />
      <Col lg={12}>
        <Card>
          <CardHeader className="d-flex align-items-center border-0">
            <h5 className="card-title mb-0 flex-grow-1">
              Ordens de Retirada Cripto
            </h5>
            {(isLojista || isCliente) && (
              <div className="flex-shrink-0">
                <div className="flax-shrink-0 hstack gap-2">
                  <Button
                    color="primary"
                    className="btn btn-success"
                    onClick={() => setmodalOrder(true)}
                  >
                    Nova Retirada
                  </Button>
                </div>
              </div>
            )}
          </CardHeader>
          <CardBody>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Spinner height={100} width={100} />
              </div>
            ) : (
              <TableContainer
                columns={columns}
                data={orderList || []}
                isGlobalFilter={true}
                isAddUserList={false}
                customPageSize={20}
                className="custom-header-css"
                divClass="table-responsive table-card mb-4"
                tableClass="align-middle table-nowrap mb-0"
                theadClass="table-light table-nowrap"
                thClass="table-light text-muted"
              />
            )}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default AllOrders;
