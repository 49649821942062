import React, { useState, useEffect } from "react";
import { Button, Col, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { NumericFormat } from "react-number-format";

const ModalConta = ({
  conta,
  setmodalConta,
  show,
  setLoadingTable,
  formatCurrency,
  coins,
  toggleArquivadas,
}) => {
  const [editando, setEditando] = useState(false);
  const [nome, setNome] = useState("");
  const [carteira, setCarteira] = useState("");
  const [compradoBrl, setCompradoBrl] = useState(0);
  const [compradoMoeda, setCompradoMoeda] = useState(0);
  const [compraDiaria, setCompraDiaria] = useState(0);
  const [enviar, setEnviar] = useState(false);
  const [emEspera, setEmEspera] = useState(0);

  const updateConta = async (event) => {
    event.preventDefault(); // Impedir a submissão padrão do formulário
    setEditando(true);
    const clientData = JSON.parse(sessionStorage.getItem("authUser") || "{}");
    const dados = {
      nome,
      carteira,
      compra_diaria: compraDiaria,
      enviar,
    };
    console.log(dados);
    try {
      const response = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_URL}/cripto-td/contas/update/${conta?.id}`,
        headers: {
          Authorization: `Bearer ${clientData.token}`,
        },
        data: dados,
      });

      if (response && response.status === 1) {
        toast.success(response.mensagem);
        setmodalConta(!show);
        setLoadingTable(true);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro na edição da conta: " + error);
      console.log(error);
    }
    setEditando(false);
  };

  const arquivarConta = async (event) => {
    event.preventDefault(); // Impedir a submissão padrão do formulário
    setEditando(true);
    const clientData = JSON.parse(sessionStorage.getItem("authUser") || "{}");
    try {
      const response = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_URL}/cripto-td/contas/arquivar/${conta?.id}`,
        headers: {
          Authorization: `Bearer ${clientData.token}`,
        },
      });

      if (response && response.status === 1) {
        toast.success(response.mensagem);
        setmodalConta(!show);
        setLoadingTable(true);
        await toggleArquivadas();
      } else {
        toast.error(response.mensagem);
      }
    } catch (error) {
      toast.error("Erro na edição da conta: " + error);
    }
    setEditando(false);
  };

  useEffect(() => {
    if (conta) {
      const moedaConta =
        coins.find((coin) => coin.moeda === conta?.moeda) || {};
      const taxa_envio = moedaConta.taxa_envio / 100;
      const comprado_brl = conta?.comprado_brl / 0.9855;
      const comprado_moeda = conta?.comprado_moeda * (1 - taxa_envio);
      setNome(conta?.nome);
      setCarteira(conta?.carteira);
      setCompradoBrl(comprado_brl);
      setCompradoMoeda(comprado_moeda);
      setCompraDiaria(conta?.compra_diaria);
      setEnviar(conta?.enviar);
      setEmEspera(conta?.em_espera);
    }
  }, [conta, coins]);

  return (
    <Modal
      isOpen={show}
      toggle={() => {
        setmodalConta(!show);
      }}
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          setmodalConta(!show);
        }}
      >
        Editar Conta
      </ModalHeader>
      <ModalBody>
        <form>
          <div className="row g-3">
            {emEspera > 0 && (
              <Col lg={12}>
                <p>
                  Você tem aproximadamente{" "}
                  <b>{`${emEspera.toFixed(8)} ${conta?.moeda}`}</b> em espera
                  para serem enviados.
                </p>
              </Col>
            )}
            <Col md={6}>
              <div>
                <label htmlFor="nome" className="form-label">
                  Nome
                </label>
                <Input
                  type="text"
                  className="form-control"
                  id="nome"
                  placeholder="Nome da Conta"
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                />
              </div>
            </Col>
            <Col md={6}>
              <div>
                <label htmlFor="compraDiaria" className="form-label">
                  Compra Diária (R$)
                </label>
                <NumericFormat
                  className="form-control"
                  id="compraDiaria"
                  placeholder="Sua Compra diária"
                  value={Math.ceil(compraDiaria)}
                  onValueChange={({ value }) =>
                    setCompraDiaria(parseFloat(value))
                  }
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={0}
                  suffix=",00"
                  fixedDecimalScale={true}
                  prefix="R$ "
                  allowNegative={false}
                />
              </div>
            </Col>
            <Col md={12}>
              <div>
                <label htmlFor="carteira" className="form-label">
                  Carteira
                </label>
                <Input
                  type="text"
                  className="form-control"
                  id="carteira"
                  placeholder="Sua carteira"
                  value={carteira}
                  onChange={(e) => setCarteira(e.target.value)}
                />
              </div>
            </Col>

            <Col md={6}>
              <div>
                <label htmlFor="moeda" className="form-label">
                  Moeda
                </label>
                <Input
                  type="text"
                  className="form-control"
                  id="moeda"
                  value={conta ? conta?.moeda : ""}
                  readOnly
                  disabled
                />
              </div>
            </Col>
            <Col md={6}>
              <div>
                <label htmlFor="rede" className="form-label">
                  Rede
                </label>
                <Input
                  type="text"
                  className="form-control"
                  id="rede"
                  value={conta ? conta?.rede : ""}
                  readOnly
                  disabled
                />
              </div>
            </Col>
            <Col md={6}>
              <div>
                <label htmlFor="saldo_brl" className="form-label">
                  Saldo Atual
                </label>
                <Input
                  type="text"
                  className="form-control"
                  id="saldo_brl"
                  value={conta ? formatCurrency(conta?.saldo_brl) : ""}
                  readOnly
                  disabled
                />
              </div>
            </Col>
            <Col md={6}>
              <div>
                <label htmlFor="saldo_inicial" className="form-label">
                  Preço Médio
                </label>
                <Input
                  type="text"
                  className="form-control"
                  id="saldo_inicial"
                  value={conta ? formatCurrency(conta?.preco_medio) : ""}
                  readOnly
                  disabled
                />
                <small className="form-text text-muted mt-1">
                  Cotação sem taxas aplicadas
                </small>
              </div>
            </Col>

            <Col md={6}>
              <div>
                <label htmlFor="comprado_brl" className="form-label">
                  Total Comprado + Enviado
                </label>
                <Input
                  type="text"
                  className="form-control"
                  id="comprado_brl"
                  value={conta ? formatCurrency(compradoBrl) : ""}
                  readOnly
                  disabled
                />
              </div>
            </Col>
            <Col md={6}>
              <div>
                <label htmlFor="comprado_moeda" className="form-label">
                  Comprado em {conta ? conta?.moeda : ""}
                </label>
                <Input
                  type="text"
                  className="form-control"
                  id="comprado_moeda"
                  value={conta ? compradoMoeda.toFixed(8) : ""}
                  readOnly
                  disabled
                />
              </div>
            </Col>
            <Col lg={12}>
              <div className="hstack gap-2 justify-content-end">
                <div className="form-check form-switch">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    id="enviar"
                    checked={enviar}
                    onChange={(e) => {
                      setEnviar(e.target.checked);
                    }}
                  />
                  <label className="form-check-label" htmlFor="enviar">
                    Realizar envio semanal?
                  </label>
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className="hstack gap-2 justify-content-end">
                Esta conta tem{" "}
                <b>{conta ? parseInt(conta?.saldo_brl / compraDiaria) : 0}</b>{" "}
                dias de investimento restantes.
              </div>
            </Col>

            <Col lg={12}>
              <div className="hstack gap-2 justify-content-end">
                <Button color="light" onClick={() => setmodalConta(false)}>
                  Fechar
                </Button>
                {conta?.saldo_brl === 0 && (
                  <Button
                    color={conta?.status === 1 ? "danger" : "secondary"}
                    type="button"
                    disabled={editando}
                    onClick={arquivarConta}
                  >
                    {conta?.status === 1 ? "Arquivar" : "Desarquivar"}
                  </Button>
                )}
                <Button
                  color="primary"
                  type="button"
                  disabled={editando}
                  onClick={updateConta}
                >
                  Salvar
                </Button>
              </div>
            </Col>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};
export default ModalConta;
