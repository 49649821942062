import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
  const history = useNavigate();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isApps, setIsApps] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [isPages, setIsPages] = useState(false);
  const [isBaseUi, setIsBaseUi] = useState(false);
  const [isAdvanceUi, setIsAdvanceUi] = useState(false);
  const [isForms, setIsForms] = useState(false);
  const [isTables, setIsTables] = useState(false);
  const [isCharts, setIsCharts] = useState(false);
  const [isIcons, setIsIcons] = useState(false);
  const [isMaps, setIsMaps] = useState(false);
  const [isMultiLevel, setIsMultiLevel] = useState(false);
  const [isCrypto, setIsCrypto] = useState(false);
  const [isLojistas, setIsLojistas] = useState(false);
  const [isLanding, setIsLanding] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  const user = JSON.parse(sessionStorage.getItem("authUser")).data;
  const isAdmin = user.role === "Admin";

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Apps") {
      setIsApps(false);
    }
    if (iscurrentState !== "Auth") {
      setIsAuth(false);
    }
    if (iscurrentState !== "Pages") {
      setIsPages(false);
    }
    if (iscurrentState !== "BaseUi") {
      setIsBaseUi(false);
    }
    if (iscurrentState !== "AdvanceUi") {
      setIsAdvanceUi(false);
    }
    if (iscurrentState !== "Forms") {
      setIsForms(false);
    }
    if (iscurrentState !== "Tables") {
      setIsTables(false);
    }
    if (iscurrentState !== "Charts") {
      setIsCharts(false);
    }
    if (iscurrentState !== "Icons") {
      setIsIcons(false);
    }
    if (iscurrentState !== "Maps") {
      setIsMaps(false);
    }
    if (iscurrentState !== "MuliLevel") {
      setIsMultiLevel(false);
    }
    if (iscurrentState === "Widgets") {
      history("/widgets");
      document.body.classList.add("twocolumn-panel");
    }
    if (iscurrentState !== "Landing") {
      setIsLanding(false);
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isApps,
    isAuth,
    isPages,
    isBaseUi,
    isAdvanceUi,
    isForms,
    isTables,
    isCharts,
    isIcons,
    isMaps,
    isMultiLevel,
  ]);

  const clienteMenuItems = {
    id: "dashboard",
    label: "Clientes Fort",
    icon: "bx bxs-dashboard",
    link: "/#",
    stateVariables: isDashboard,
    click: function (e) {
      e.preventDefault();
      setIsDashboard(!isDashboard);
      setIscurrentState("Dashboard");
      updateIconSidebar(e);
    },
    subItems: [
      {
        id: "crypto",
        label: "Painel",
        link: "/dashboard-crypto",
        parentId: "dashboard",
      },
      {
        id: "envios-semanais",
        label: "Envios Semanais",
        link: "/dashboard-compras-semanais",
        parentId: "dashboard",
      },
      {
        id: "transacoes",
        label: "Transações",
        link: "/dashboard-transacoes",
        parentId: "dashboard",
      },
      {
        id: "pix",
        label: "PIX",
        link: "/dashboard-pix",
        parentId: "dashboard",
      },
      {
        id: "dashboard-comissoes",
        label: "Comissões",
        link: "/dashboard-comissoes",
        parentId: "dashboard",
      },
      {
        id: "logs",
        label: "Logs",
        link: "/logs/" + user._id,
        parentId: "dashboard",
      },

    ],
  };

  const lojistaMenuItems = {
    id: "lojistas-painel",
    label: "Lojista",
    icon: "bx bx-store",
    link: "/#",
    click: function (e) {
      e.preventDefault();
      setIsCharts(!isCharts);
      setIscurrentState("Charts");
      updateIconSidebar(e);
    },
    stateVariables: isCharts,
    subItems: [
      {
        id: "perfil-lojista",
        label: "Perfil",
        link: "/perfil-lojista",
        parentId: "lojistas-client-panel",
      },
      {
        id: "lojista-socios",
        label: "Sócios",
        link: "/lojista-socios",
        parentId: "lojistas-client-panel",
      },
      {
        id: "order-lojista",
        label: "Ordens",
        link: "/order-lojista",
        parentId: "lojistas-client-panel",
      },
      {
        id: "retiradas-lojista",
        label: "Retiradas Cripto",
        link: "/retiradas-lojista",
        parentId: "lojistas-client-panel",
      },
      {
        id: "logs-lojista",
        label: "Logs",
        link: "/logs/" + user._id,
        parentId: "lojistas-client-panel",
      },
    ],
  };

  const menuItems = isAdmin
    ? [
        {
          label: "Menu",
          isHeader: true,
        },
        {
          id: "apps",
          label: "Compras e DCA",
          icon: "bx bx-layer",
          link: "/#",
          click: function (e) {
            e.preventDefault();
            setIsApps(!isApps);
            setIscurrentState("Apps");
            updateIconSidebar(e);
          },
          stateVariables: isApps,
          subItems: [
            {
              id: "compra-diaria-fort",
              label: "Compra Diária",
              link: "/compra-diaria",
              parentId: "apps",
            },
            {
              id: "envio-semanal-fort",
              label: "Envio Semanal",
              link: "/compra-semanal",
              parentId: "apps",
            },
            {
              id: "pix-fort",
              label: "PIX",
              link: "/pix",
              parentId: "apps",
            },
            {
              id: "retiradas-cripto",
              label: "Retiradas Cripto",
              link: "/retiradas-cripto",
              parentId: "apps",
            },
            // {
            //   id: "dashboard-fort",
            //   label: "Dashboard",
            //   link: "/apps-crypto-wallet",
            //   parentId: "apps",
            // },
            {
              id: "comissoes",
              label: "Comissões",
              link: "/comissoes",
              parentId: "apps",
            },
            {
              id: "clientes",
              label: "Clientes",
              link: "/clientes",
              parentId: "apps",
            },
            {
              id: "lojistas",
              label: "Lojistas",
              link: "/lojistas",
              parentId: "apps",
            },{
              id: "operacoes",
              label: "Operações",
              link: "/operacoes",
              parentId: "apps",
            },
          ],
        },
        {
          id: "configuracoes",
          label: "Configurações",
          icon: "bx bx-cog",
          link: "/#",
          click: function (e) {
            e.preventDefault();
            setIsAuth(!isAuth);
            setIscurrentState("Auth");
            updateIconSidebar(e);
          },
          stateVariables: isAuth,
          subItems: [
            {
              id: "config-sistema",
              label: "Sistema",
              link: "/configuracoes",
              parentId: "configuracoes",
            },
            {
              id: "logs",
              label: "Logs Sistema",
              link: "/system-logs",
              parentId: "configuracoes",
            }
          ],
        },
      ]
    : [
        {
          label: "Menu",
          isHeader: true,
        },
      ];
  if (user?.clientData?.nome_completo) {
    menuItems.push(clienteMenuItems);
  }
  if (user?.lojistaData?.razao_social) {
    menuItems.push(lojistaMenuItems);
  }
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
