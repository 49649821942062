// Clientes.js
import React from 'react';
import { useParams } from 'react-router-dom';
import TabelaClientes from './TabelaClientes';
import ClienteDados from './ClienteDados';
import { toast } from 'react-toastify';

const Clientes = () => {
  const { user_id } = useParams();

  const urlParams = new URLSearchParams(window.location.search);
  const cadastrado = urlParams.get('cadastrado');
  if (cadastrado==='true') {
    toast.success('Cliente cadastrado com sucesso!');
  }

  return user_id ? <ClienteDados user_id={user_id} /> : <TabelaClientes />;
};

export default Clientes;
