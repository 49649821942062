import React, { useMemo, useCallback, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Spinner,
  Button,
  Badge,
} from "reactstrap";

import TableContainer from "../../../Components/Common/TableContainer";
import ModalStatus from "./ModalStatus";
import { toast } from "react-toastify";
import axios from "axios";

const AllOrders = ({ orderList, loading, setShouldFetch }) => {
  const [operacao, setOperacao] = useState(null);
  const [modalStatus, setmodalStatus] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  function formatName(name) {
    let lowerCaseName = name.toLowerCase();
    let properName = lowerCaseName.replace(
      /(^|\s|')(\w)/g,
      function (match, separator, letter, index, fullText) {
        // Encontra a palavra completa a partir do índice
        const matchResult = fullText.slice(index).match(/^[^\s']+|\b\w+/);
        // Se matchResult for null, retorna a letra original
        if (!matchResult) {
          return match;
        }
        const word = matchResult[0];
        const lowerCaseWords = ["de", "da", "do", "dos", "das"];
        if (lowerCaseWords.includes(word)) {
          return separator + letter;
        }
        return separator + letter.toUpperCase();
      }
    );

    return properName;
  }

  const formatCurrency = (value) => {
    if (!value) {
      return "R$ 0,00";
    }
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        filterable: true,
        Cell: (order) => (
          <>
            <div className="d-flex align-items-center">
              <Link to="#" className="currency_name flex-grow-1 ms-2 text-body">
                {order.row.original.id}
              </Link>
            </div>
          </>
        ),
      },
      {
        Header: "Cliente",
        accessor: "user.name",
        filterable: true,
        Cell: (cell) => (
          <>
            <div className="d-flex align-items-center">
              <Link to="#" className="currency_name flex-grow-1 ms-2 text-body">
                <b style={{ color: "darkBlue" }}>
                  {cell.value ? formatName(cell.value) : ""}
                </b>
              </Link>
            </div>
          </>
        ),
      },
      {
        Header: "Moeda Saída",
        filterable: true,
        Cell: (cell) => (
          <>
            {!cell.row.original.moeda_saida_dados ? (
              <div className="d-flex align-items-center">
                <img
                  src={`https://api.fort.exchange/crypto-icons/br.svg`}
                  alt=""
                  className="rounded-circle avatar-xxs me-2"
                />
                {"BRL"}
              </div>
            ) : (
              <div className="d-flex align-items-center">
                <img
                  src={`https://api.fort.exchange/crypto-icons/${cell.row.original.moeda_saida_dados.moeda.toLowerCase()}.svg`}
                  alt=""
                  className="avatar-xxs me-2"
                />
                {cell.row.original.moeda_saida_dados.moeda}
              </div>
            )}
          </>
        ),
      },
      {
        Header: "Quantidade",
        accessor: "quantidade",
        filterable: false,
        Cell: (cell) => {
          return (
            <>
              {cell.row.original.moeda_saida === "BRL"
                ? formatCurrency(cell.value)
                : `${parseFloat(cell.value).toFixed(8)} ${
                    cell.row.original.moeda_saida
                  }`}
            </>
          );
        },
      },
      {
        Header: "Moeda Destino",
        filterable: true,
        Cell: (cell) => (
          <>
            {!cell.row.original.moeda_destino_dados ? (
              <div className="d-flex align-items-center">
                <img
                  src={`https://api.fort.exchange/crypto-icons/br.svg`}
                  alt=""
                  className="rounded-circle avatar-xxs me-2"
                />
                {"BRL"}
              </div>
            ) : (
              <div className="d-flex align-items-center">
                <img
                  src={`https://api.fort.exchange/crypto-icons/${cell.row.original.moeda_destino_dados.moeda.toLowerCase()}.svg`}
                  alt=""
                  className="avatar-xxs me-2"
                />
                {cell.row.original.moeda_destino_dados.moeda}
              </div>
            )}
          </>
        ),
      },
      {
        Header: "Total",
        accessor: "total",
        filterable: false,
        Cell: (cell) => {
          return (
            <>
              {cell.row.original.moeda_destino === "BRL"
                ? formatCurrency(cell.value)
                : `${parseFloat(cell.value).toFixed(8)} ${
                    cell.row.original.moeda_destino
                  }`}
            </>
          );
        },
      },
      {
        Header: "Lucro Fort",
        accessor: "taxa_fort",
        filterable: false,
        Cell: (cell) => {
          return (
            <>
              {cell.row.original.moeda_base === "BRL"
                ? formatCurrency(cell.value)
                : `${parseFloat(
                  cell.value
                  ).toFixed(8)} ${cell.row.original.moeda_base}`}
            </>
          );
        },
      },
      {
        Header: "Status / Ações",
        accessor: "id",
        filterable: false,
        Cell: (cell) => {
          return (
            <>
              <Badge
                color={
                  cell.row.original.status === "cancelada"
                    ? "danger"
                    : cell.row.original.status === "aguardando"
                    ? "warning"
                    : "primary"
                }
                className="me-1"
              >
                {cell.row.original.status.charAt(0).toUpperCase() +
                  cell.row.original.status.slice(1)}
              </Badge>
              <Button
                color="success"
                className="btn btn-sm ms-1"
                onClick={() => {
                  setOperacao(cell.row.original);
                  setmodalStatus(true);
                }}
                disabled={loadingButton}
              >
                Ver Operação
              </Button>
            </>
          );
        },
      },
    ],
    [loadingButton]
  );
  return (
    <React.Fragment>
      <ModalStatus
        operacao={operacao}
        setOperacao={setOperacao}
        setmodalStatus={setmodalStatus}
        modalStatus={modalStatus}
        setShouldFetch={setShouldFetch}
        formatCurrency={formatCurrency}
      />
      <Col lg={12}>
        <Card>
          <CardHeader className="d-flex align-items-center border-0">
            <h5 className="card-title mb-0 flex-grow-1">
              Operações de Compra e Venda
            </h5>
            <div className="flex-shrink-0">
              <div className="flax-shrink-0 hstack gap-2">
                <Link to="/nova-operacao" className="btn btn-primary">
                  Nova Operação
                </Link>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Spinner height={100} width={100} />
              </div>
            ) : (
              <TableContainer
                columns={columns}
                data={orderList || []}
                isGlobalFilter={true}
                isAddUserList={false}
                customPageSize={20}
                className="custom-header-css"
                divClass="table-responsive table-card mb-4"
                tableClass="align-middle table-nowrap mb-0"
                theadClass="table-light table-nowrap"
                thClass="table-light text-muted"
              />
            )}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default AllOrders;
