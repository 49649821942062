import React, { useState, useEffect } from "react";
import { Spinner } from "reactstrap";
import axios from "axios";
import FeatherIcon from "feather-icons-react";

//import Images
import btc from "../../assets/images/svg/crypto-icons/btc.svg";
import eth from "../../assets/images/svg/crypto-icons/eth.svg";
import matic from "../../assets/images/svg/crypto-icons/matic.svg";
import usdc from "../../assets/images/svg/crypto-icons/usdc.svg";
import usd from "../../assets/images/svg/crypto-icons/usd.svg";
import bnb from "../../assets/images/svg/crypto-icons/bnb.svg";
import sol from "../../assets/images/svg/crypto-icons/sol.svg";

import { PortfolioCharts } from "./DashboardCryptoCharts";

const MyPortfolio = ({
  contas,
  coins,
  loading,
  formatCurrency,
  saldos,
  verValores,
  setVerValores,
  verValoresAtivado,
}) => {
  const [chartData, setchartData] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [cryptoValue, setcryptoData] = useState([0, 0, 0, 0, 0, 0, 0]);

  useEffect(() => {
    var saldoBTC = contas.reduce((total, conta) => {
      const coin = coins.find((coin) => coin.moeda === "BTC");
      const precoAtual = coin ? coin.preco_atual_brl : 0;
      return !coin
        ? 0
        : conta.moeda === "BTC"
        ? total +
          conta.comprado_moeda * (1 - coin.taxa_envio / 100) * precoAtual
        : total;
    }, 0);

    var saldoSOL = contas.reduce((total, conta) => {
      const coin = coins.find((coin) => coin.moeda === "SOL");
      const precoAtual = coin ? coin.preco_atual_brl : 0;
      return !coin
        ? 0
        : conta.moeda === "SOL"
        ? total +
          conta.comprado_moeda * (1 - coin.taxa_envio / 100) * precoAtual
        : total;
    }, 0);

    var saldoETH = contas.reduce((total, conta) => {
      const coin = coins.find((coin) => coin.moeda === "ETH");
      const precoAtual = coin ? coin.preco_atual_brl : 0;
      return !coin
        ? 0
        : conta.moeda === "ETH"
        ? total +
          conta.comprado_moeda * (1 - coin.taxa_envio / 100) * precoAtual
        : total;
    }, 0);

    var saldoUSDC = contas.reduce((total, conta) => {
      const coin = coins.find((coin) => coin.moeda === "USDC");
      const precoAtual = coin ? coin.preco_atual_brl : 0;
      return !coin
        ? 0
        : conta.moeda === "USDC"
        ? total +
          conta.comprado_moeda * (1 - coin.taxa_envio / 100) * precoAtual
        : total;
    }, 0);

    var saldoMATIC = contas.reduce((total, conta) => {
      const coin = coins.find((coin) => coin.moeda === "MATIC");
      const precoAtual = coin ? coin.preco_atual_brl : 0;
      return !coin
        ? 0
        : conta.moeda === "MATIC"
        ? total +
          conta.comprado_moeda * (1 - coin.taxa_envio / 100) * precoAtual
        : total;
    }, 0);

    var saldoBNB = contas.reduce((total, conta) => {
      const coin = coins.find((coin) => coin.moeda === "BNB");
      const precoAtual = coin ? coin.preco_atual_brl : 0;
      return !coin
        ? 0
        : conta.moeda === "BNB"
        ? total +
          conta.comprado_moeda * (1 - coin.taxa_envio / 100) * precoAtual
        : total;
    }, 0);

    var originalBTC = contas.reduce((total, conta) => {
      const coin = coins.find((coin) => coin.moeda === "BTC");
      return !coin
        ? 0
        : conta.moeda === "BTC"
        ? total + conta.comprado_moeda * (1 - coin.taxa_envio / 100)
        : total;
    }, 0);

    var originalSOL = contas.reduce((total, conta) => {
      const coin = coins.find((coin) => coin.moeda === "SOL");
      return !coin
        ? 0
        : conta.moeda === "SOL"
        ? total + conta.comprado_moeda * (1 - coin.taxa_envio / 100)
        : total;
    }, 0);

    var originalETH = contas.reduce((total, conta) => {
      const coin = coins.find((coin) => coin.moeda === "ETH");
      return !coin
        ? 0
        : conta.moeda === "ETH"
        ? total + conta.comprado_moeda * (1 - coin.taxa_envio / 100)
        : total;
    }, 0);

    var originalUSDC = contas.reduce((total, conta) => {
      const coin = coins.find((coin) => coin.moeda === "USDC");
      return !coin
        ? 0
        : conta.moeda === "USDC"
        ? total + conta.comprado_moeda * (1 - coin.taxa_envio / 100)
        : total;
    }, 0);

    var originalMATIC = contas.reduce((total, conta) => {
      const coin = coins.find((coin) => coin.moeda === "MATIC");
      return !coin
        ? 0
        : conta.moeda === "MATIC"
        ? total + conta.comprado_moeda * (1 - coin.taxa_envio / 100)
        : total;
    }, 0);

    var originalBNB = contas.reduce((total, conta) => {
      const coin = coins.find((coin) => coin.moeda === "BNB");
      return !coin
        ? 0
        : conta.moeda === "BNB"
        ? total + conta.comprado_moeda * (1 - coin.taxa_envio / 100)
        : total;
    }, 0);

    var saldoBRL = parseFloat(saldos[2].counter);

    setchartData([
      saldoBTC,
      saldoETH,
      saldoMATIC,
      saldoUSDC,
      saldoBNB,
      saldoSOL,
      saldoBRL,
    ]);
    setcryptoData([
      originalBTC,
      originalETH,
      originalMATIC,
      originalUSDC,
      originalBNB,
      originalSOL,
      saldoBRL,
    ]);
  }, [contas, coins, saldos]);

  return (
    <React.Fragment>
      <div className="col-xxl-3">
        <div className="card card-height-100">
          <div className="card-header border-0 align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">Portfólio DCA</h4>
          </div>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                padding: "20px",
              }}
            >
              <Spinner height={100} width={100} />
            </div>
          ) : (
            <div className="card-body">
              <PortfolioCharts
                series={chartData}
                dataColors='["--vz-danger", "--vz-info", "--vz-primary", "--vz-secondary", "--vz-warning", "--vz-success", "--vz-success"]'
              />

              <ul className="list-group list-group-flush border-dashed mb-0 mt-3 pt-2">
                <li className="list-group-item px-0">
                  <div className="d-flex">
                    <div className="flex-shrink-0 avatar-xs">
                      <span className="avatar-title bg-light p-1 rounded-circle">
                        <img src={btc} className="img-fluid" alt="" />
                      </span>
                    </div>
                    <div className="flex-grow-1 ms-2">
                      <h6 className="mb-1">Bitcoin</h6>
                      <p className="fs-12 mb-0 text-muted">
                        <i className="mdi mdi-circle fs-10 align-middle text-danger me-1"></i>
                        BTC
                      </p>
                    </div>
                    <div className="flex-shrink-0 text-end">
                      <h6 className="mb-1">
                        {parseFloat(cryptoValue[0]).toFixed(8)}
                      </h6>
                      <p className="text-success fs-12 mb-0">
                        {formatCurrency(chartData[0])}
                      </p>
                    </div>
                  </div>
                </li>
                <li className="list-group-item px-0">
                  <div className="d-flex">
                    <div className="flex-shrink-0 avatar-xs">
                      <span className="avatar-title bg-light p-1 rounded-circle">
                        <img src={eth} className="img-fluid" alt="" />
                      </span>
                    </div>
                    <div className="flex-grow-1 ms-2">
                      <h6 className="mb-1">Ethereum</h6>
                      <p className="fs-12 mb-0 text-muted">
                        <i className="mdi mdi-circle fs-10 align-middle text-info me-1"></i>
                        ETH
                      </p>
                    </div>
                    <div className="flex-shrink-0 text-end">
                      <h6 className="mb-1">{cryptoValue[1].toFixed(8)}</h6>
                      <p className="text-success fs-12 mb-0">
                        {formatCurrency(chartData[1])}
                      </p>
                    </div>
                  </div>
                </li>
                <li className="list-group-item px-0">
                  <div className="d-flex">
                    <div className="flex-shrink-0 avatar-xs">
                      <span className="avatar-title bg-light p-1 rounded-circle">
                        <img src={matic} className="img-fluid" alt="" />
                      </span>
                    </div>
                    <div className="flex-grow-1 ms-2">
                      <h6 className="mb-1">Polygon MATIC</h6>
                      <p className="fs-12 mb-0 text-muted">
                        <i className="mdi mdi-circle fs-10 align-middle text-primary me-1"></i>
                        MATIC
                      </p>
                    </div>
                    <div className="flex-shrink-0 text-end">
                      <h6 className="mb-1">{cryptoValue[2].toFixed(8)}</h6>
                      <p className="text-success fs-12 mb-0">
                        {formatCurrency(chartData[2])}
                      </p>
                    </div>
                  </div>
                </li>
                <li className="list-group-item px-0">
                  <div className="d-flex">
                    <div className="flex-shrink-0 avatar-xs">
                      <span className="avatar-title bg-light p-1 rounded-circle">
                        <img src={usdc} className="img-fluid" alt="" />
                      </span>
                    </div>
                    <div className="flex-grow-1 ms-2">
                      <h6 className="mb-1">Circle Dollar</h6>
                      <p className="fs-12 mb-0 text-muted">
                        <i className="mdi mdi-circle fs-10 align-middle text-secondary me-1"></i>
                        USDC
                      </p>
                    </div>
                    <div className="flex-shrink-0 text-end">
                      <h6 className="mb-1">{cryptoValue[3].toFixed(6)}</h6>
                      <p className="text-success fs-12 mb-0">
                        {formatCurrency(chartData[3])}
                      </p>
                    </div>
                  </div>
                </li>
                <li className="list-group-item px-0">
                  <div className="d-flex">
                    <div className="flex-shrink-0 avatar-xs">
                      <span className="avatar-title bg-light p-1 rounded-circle">
                        <img src={bnb} className="img-fluid" alt="" />
                      </span>
                    </div>
                    <div className="flex-grow-1 ms-2">
                      <h6 className="mb-1">Binance Coin</h6>
                      <p className="fs-12 mb-0 text-muted">
                        <i className="mdi mdi-circle fs-10 align-middle text-warning me-1"></i>
                        BNB
                      </p>
                    </div>
                    <div className="flex-shrink-0 text-end">
                      <h6 className="mb-1">{cryptoValue[4].toFixed(8)}</h6>
                      <p className="text-success fs-12 mb-0">
                        {formatCurrency(chartData[4])}
                      </p>
                    </div>
                  </div>
                </li>

                <li className="list-group-item px-0">
                  <div className="d-flex">
                    <div className="flex-shrink-0 avatar-xs">
                      <span className="avatar-title bg-light p-1 rounded-circle">
                        <img src={sol} className="img-fluid" alt="" />
                      </span>
                    </div>
                    <div className="flex-grow-1 ms-2">
                      <h6 className="mb-1">Solana</h6>
                      <p className="fs-12 mb-0 text-muted">
                        <i className="mdi mdi-circle fs-10 align-middle text-success me-1"></i>
                        SOL
                      </p>
                    </div>
                    <div className="flex-shrink-0 text-end">
                      <h6 className="mb-1">{cryptoValue[5].toFixed(8)}</h6>
                      <p className="text-success fs-12 mb-0">
                        {formatCurrency(chartData[5])}
                      </p>
                    </div>
                  </div>
                </li>
                <li className="list-group-item px-0">
                  <div className="d-flex">
                    <div className="flex-shrink-0 avatar-xs">
                      <span className="avatar-title bg-light p-1 rounded-circle">
                        <img src={usd} className="img-fluid" alt="" />
                      </span>
                    </div>
                    <div className="flex-grow-1 ms-2">
                      <h6 className="mb-1">Real Brasileiro</h6>
                      <p className="fs-12 mb-0 text-muted">
                        <i className="mdi mdi-circle fs-10 align-middle text-success me-1"></i>
                        BRL
                      </p>
                    </div>
                    <div className="flex-shrink-0 text-end">
                      <h6 className="mb-1">{cryptoValue[6].toFixed(2)}</h6>
                      <p className="text-success fs-12 mb-0">
                        {formatCurrency(chartData[6])}
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default MyPortfolio;
