import React, { useState, useCallback, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Spinner,
} from "reactstrap";
import classnames from "classnames";
import TabPerfil from "./TabPerfil";
import TabPerfilCliente from "./TabPerfilCliente";
import TabSaldos from "./TabSaldos";
import TabSocios from "./TabSocios";

import axios from "axios";
import { toast } from "react-toastify";

//import images
import progileBg from "../../../assets/images/profile-bg.jpg";
import TabConfig from "./TabConfig";

const LojistaDados = ({ user_id }) => {
  const [activeTab, setActiveTab] = useState("1");
  const user = JSON.parse(sessionStorage.getItem("authUser") || "{}");

  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const [lojista, setLojista] = useState(null);

  const fetchLojistaData = useCallback(async (user_id) => {
    const token = JSON.parse(sessionStorage.getItem("authUser"))?.token;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/lojista/${user_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status && response.status === 1) {
        setLojista(response.dados);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro ao buscar dados.");
      console.log(error);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      fetchLojistaData(user_id);
    };
    fetchData();
  }, [fetchLojistaData, user_id]);

  document.title = "Perfil do Lojista | Painel Fort Exchange";

  return (
    <React.Fragment>
      {!lojista ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            padding: "20px",
          }}
        >
          <Spinner height={100} width={100} />
        </div>
      ) : (
        <div className="page-content">
          <Container fluid>
            <div className="position-relative mx-n4 mt-n4">
              <div className="profile-wid-bg profile-setting-img">
                <img src={progileBg} className="profile-wid-img" alt="" />
                <div className="overlay-content">
                  <div className="text-end p-3">
                    <div className="p-0 ms-auto rounded-circle profile-photo-edit"></div>
                  </div>
                </div>
              </div>
            </div>
            <Row>
              <Col xxl={12}>
                <Card className="mt-xxl-n5">
                  <CardHeader>
                    <Nav
                      className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                      role="tablist"
                    >
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "1" })}
                          onClick={() => {
                            tabChange("1");
                          }}
                        >
                          <i className="mdi mdi-account-circle"> </i>
                          Perfil
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "2",
                          })}
                          onClick={() => {
                            tabChange("2");
                          }}
                        >
                          <i className="mdi mdi-account-cash"> </i>
                          Saldos
                        </NavLink>
                      </NavItem>
                      {user.data.role === "Admin" ? (
                        <>
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: activeTab === "3",
                              })}
                              onClick={() => {
                                tabChange("3");
                              }}
                            >
                              <i className="mdi mdi-account-group"> </i>
                              Sócios
                            </NavLink>
                          </NavItem>
                        </>
                      ) : (
                        <></>
                      )}
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "4",
                          })}
                          onClick={() => {
                            tabChange("4");
                          }}
                        >
                          <i className="mdi mdi-cog"> </i>
                          Configurações
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </CardHeader>
                  <CardBody className="p-4">
                    <TabContent activeTab={activeTab}>
                      {user.data.role === "Admin" ? (
                        <>
                          <TabPerfil user_id={user_id} lojista={lojista} />
                          <TabSaldos
                            user_id={user_id}
                            lojista={lojista}
                            fetchLojistaData={fetchLojistaData}
                          />
                          <TabSocios user_id={user_id} />
                        </>
                      ) : (
                        <>
                          <TabPerfilCliente
                            user_id={user_id}
                            lojista={lojista}
                          />
                          <TabSaldos
                            user_id={user_id}
                            lojista={lojista}
                            fetchLojistaData={fetchLojistaData}
                          />
                        </>
                      )}
                      <TabConfig
                        user_id={user_id}
                        lojista={lojista}
                        setLojista={setLojista}
                      />
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

export default LojistaDados;
