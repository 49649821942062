import React, { useEffect, useState, useCallback } from "react";
import { Container, Row } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import AllOrders from "./AllOrders";
import axios from "axios";
import { toast } from "react-toastify";

const OrderLojistas = ({ isLojista }) => {
  const [loading, setLoading] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [shouldFetch, setShouldFetch] = useState(true);

  const user = JSON.parse(sessionStorage.getItem("authUser"));

  const fetchBtcTransactions = useCallback(
    async (user_id) => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/operacoes/${user_id}`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        if (response.status && response.status === 1) {
          return response.data;
        } else {
          console.log(response);
          toast.error(response.mensagem);
          return [];
        }
      } catch (error) {
        toast.error("Erro ao buscar operações.");
        console.log(error);
        return [];
      } finally {
        setLoading(false);
      }
    },
    [user.token]
  );

  useEffect(() => {
    if (shouldFetch) {
      const fetchData = async () => {
        const retorno = await fetchBtcTransactions(
          isLojista ? user.data._id : 0
        );
        setOrderList(retorno);
        setShouldFetch(false);
      };

      fetchData();
    }
  }, [shouldFetch, isLojista, user.data._id, fetchBtcTransactions]);

  document.title = "Ordens | Painel Fort Exchange";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Ordens"
            pageTitle={isLojista ? "Lojista" : "Aplicações"}
          />
          <Row>
            <AllOrders
              user={user}
              orderList={orderList}
              loading={loading}
              setShouldFetch={setShouldFetch}
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default OrderLojistas;
