import React, { useState, useEffect, useCallback } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Spinner,
  Input,
  Row,
  TabPane,
  Form,
  Label,
  TabContent,
} from "reactstrap";
import { toast } from "react-toastify";
import axios from "axios";
import InputMask from "react-input-mask";
import { NumericFormat } from "react-number-format";

//import images
import progileBg from "../../../assets/images/profile-bg.jpg";

const ClientesCadastro = () => {
  const [loading, setLoading] = useState(false);
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [tipoCliente, setTipoCliente] = useState("cpf");
  const [cpf, setCpf] = useState("");
  const [situacao, setSituacao] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [rg, setRg] = useState("");
  const [telefone, setTelefone] = useState("");
  const [limiteMensal, setLimiteMensal] = useState(0);
  const [limiteAnual, setLimiteAnual] = useState(0);
  const [verificacao, setVerificacao] = useState("");
  const [indicadoPor, setIndicadoPor] = useState("");
  const [nivelKyc, setNivelKyc] = useState(0);
  const [endereco, setEndereco] = useState("");
  const [userId, setUserId] = useState("0");
  const [limiteMensalDisabled, setLimiteMensalDisabled] = useState(false);

  const [userList, setUserList] = useState([]);
  const [shouldFetch, setShouldFetch] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const clienteData = JSON.parse(sessionStorage.getItem("authUser"));

      let valueFields = {};
      if (nome) valueFields.nome_completo = nome;
      if (email) valueFields.email = email;
      if (cpf) valueFields.cpf_cnpj = cpf;
      if (tipoCliente)
        valueFields.pessoa_fisica = tipoCliente === "cpf" ? true : false;
      if (situacao) valueFields.status_cpf = situacao;
      if (dataNascimento) valueFields.nascimento = dataNascimento;
      if (rg) valueFields.rg = rg;
      if (telefone) valueFields.telefone = telefone;
      if (limiteMensal) valueFields.negociar_mensalmente = limiteMensal;
      if (limiteAnual) valueFields.limite_anual_brl = limiteAnual;
      if (verificacao) valueFields.metamap_status_verification = verificacao;
      if (indicadoPor && indicadoPor !== "Sem Indicação")
        valueFields.indicacao = indicadoPor;
      if (nivelKyc) valueFields.nivel_kyc = nivelKyc;
      if (endereco) valueFields.endereco = endereco;
      valueFields.user_id = userId;

      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/client/create-cliente/`,
        headers: {
          Authorization: `Bearer ${clienteData.token}`,
        },
        data: valueFields,
      });

      if (response.status && response.status === 1) {
        toast.success(response.message);
        window.location.href = `/clientes/${response.clientData.user_id}?cadastrado=true`;
      } else {
        toast.error(response.message || response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro na API");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const formatCurrency = (value) => {
    if (!value) {
      return "R$ 0,00";
    }
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  const formatNome = (event) => {
    let value = event.target.value.toUpperCase();
    value = value.replace(/[^A-Za-z\s]/g, "");
    setNome(value);
  };

  const formatEmail = (event) => {
    let value = event.target.value;
    value = value.replace(/[^a-zA-Z0-9.\-_@]+/g, "");

    const atSymbolCount = value.split("@").length - 1;
    if (atSymbolCount > 1) {
      const index = value.lastIndexOf("@");
      value = value.slice(0, index) + value.slice(index + 1);
    }

    setEmail(value);
  };

  const fetchNonClientes = useCallback(async () => {
    setLoading(true);
    const user = JSON.parse(sessionStorage.getItem("authUser"));
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/client/not-clients`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (response.status && response.status === 1) {
        return response.data;
      } else {
        toast.error(response.mensagem);
        console.log(response);
        return [];
      }
    } catch (error) {
      toast.error("Erro ao buscar usuários.");
      console.log(error);
      return [];
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (shouldFetch) {
      const fetchData = async () => {
        const retorno = await fetchNonClientes();
        setUserList(retorno);
        setShouldFetch(false);
      };

      fetchData();
    }
  }, [shouldFetch, fetchNonClientes]);

  document.title = "Novo Cadastro | Painel Fort Exchange";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="position-relative mx-n4 mt-n4">
            <div className="profile-wid-bg profile-setting-img">
              <img src={progileBg} className="profile-wid-img" alt="" />
              <div className="overlay-content">
                <div className="text-end p-3">
                  <div className="p-0 ms-auto rounded-circle profile-photo-edit"></div>
                </div>
              </div>
            </div>
          </div>
          <Row>
            <Col xxl={12}>
              <Card className="mt-xxl-n5">
                <CardBody className="p-4">
                  <TabContent activeTab="1">
                    <TabPane tabId="1">
                      <Form>
                        <Row>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Usuário vinculado
                              </Label>
                              <select
                                className="form-control"
                                value={situacao ? situacao : ""}
                                onChange={(e) => setUserId(e.target.value)}
                              >
                                <option value="0">
                                  Sem usuário cadastrado
                                </option>
                                {userList.map((user) => (
                                  <option key={user.id} value={user.id}>
                                    {user.name === "Nome Padrão"
                                      ? "Sem Nome"
                                      : user.name.replace(
                                          /[^A-Za-z\s]/g,
                                          ""
                                        )}{" "}
                                    {"( " + user.email.toLowerCase() + " )"}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="radioOptions"
                                className="form-label"
                              >
                                Tipo
                              </Label>
                              <Row id="radioOptions">
                                <Col lg={6}>
                                  <Input
                                    className="form-check-input"
                                    type="radio"
                                    name="radioTipo"
                                    id="radioCPF"
                                    defaultValue="cpf"
                                    checked={tipoCliente === "cpf"}
                                    onChange={(e) =>
                                      setTipoCliente(e.target.value)
                                    }
                                  />
                                  <Label
                                    className="form-check-label"
                                    htmlFor="radioCPF"
                                    style={{ marginLeft: "10px" }}
                                  >
                                    Pessoa Física
                                  </Label>
                                </Col>
                                <Col lg={6}>
                                  <Input
                                    className="form-check-input"
                                    type="radio"
                                    name="radioTipo"
                                    id="radioCNPJ"
                                    defaultValue="cnpj"
                                    checked={tipoCliente === "cnpj"}
                                    onChange={(e) =>
                                      setTipoCliente(e.target.value)
                                    }
                                  />
                                  <Label
                                    className="form-check-label"
                                    htmlFor="radioCNPJ"
                                    style={{ marginLeft: "10px" }}
                                  >
                                    Pessoa Jurídica
                                  </Label>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                {tipoCliente === "cpf"
                                  ? "Nome Completo"
                                  : "Razão Social"}
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                value={nome ? nome : ""}
                                onChange={formatNome}
                                maxLength={250}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label">E-mail</Label>
                              <Input
                                type="email"
                                className="form-control"
                                value={email ? email : ""}
                                onChange={formatEmail}
                                maxLength={150}
                              />
                            </div>
                          </Col>
                          {tipoCliente === "cpf" ? (
                            <>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label className="form-label">CPF</Label>
                                  <InputMask
                                    mask="999.999.999-99"
                                    value={cpf ? cpf : ""}
                                    placeholder="000.000.000-00"
                                    onChange={(e) => setCpf(e.target.value)}
                                  >
                                    {(inputProps) => (
                                      <Input
                                        type="text"
                                        className="form-control"
                                        {...inputProps}
                                      />
                                    )}
                                  </InputMask>
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Situação do CPF
                                  </Label>
                                  <select
                                    className="form-control"
                                    value={situacao ? situacao : ""}
                                    onChange={(e) =>
                                      setSituacao(e.target.value)
                                    }
                                  >
                                    <option value=""></option>
                                    <option value="REGULAR">REGULAR</option>
                                    <option value="IRREGULAR">IRREGULAR</option>
                                  </select>
                                </div>
                              </Col>

                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label className="form-label">RG</Label>
                                  <InputMask
                                    mask="99.999.999-9"
                                    value={rg ? rg : ""}
                                    onChange={(e) => setRg(e.target.value)}
                                  >
                                    {(inputProps) => (
                                      <Input
                                        type="text"
                                        className="form-control"
                                        {...inputProps}
                                      />
                                    )}
                                  </InputMask>
                                </div>
                              </Col>
                            </>
                          ) : (
                            <>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label className="form-label">CNPJ</Label>
                                  <InputMask
                                    mask="99.999.999/9999-99"
                                    placeholder="00.000.000/0000-00"
                                    value={cpf ? cpf : ""}
                                    onChange={(e) => setCpf(e.target.value)}
                                  >
                                    {(inputProps) => (
                                      <Input
                                        type="text"
                                        className="form-control"
                                        {...inputProps}
                                      />
                                    )}
                                  </InputMask>
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Situação do CNPJ
                                  </Label>
                                  <select
                                    className="form-control"
                                    value={situacao ? situacao : ""}
                                    onChange={(e) =>
                                      setSituacao(e.target.value)
                                    }
                                  >
                                    <option value=""></option>
                                    <option value="REGULAR">REGULAR</option>
                                    <option value="IRREGULAR">IRREGULAR</option>
                                  </select>
                                </div>
                              </Col>
                            </>
                          )}

                          <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                {tipoCliente === "cpf"
                                  ? "Nascimento"
                                  : "Início da Empresa"}
                              </Label>
                              <Input
                                type="date"
                                className="form-control"
                                value={dataNascimento ? dataNascimento : ""}
                                onChange={(e) =>
                                  setDataNascimento(e.target.value)
                                }
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label">Telefone</Label>
                              <InputMask
                                mask="(99) 99999-9999"
                                value={telefone ? telefone : ""}
                                onChange={(e) => setTelefone(e.target.value)}
                              >
                                {(inputProps) => (
                                  <Input
                                    type="text"
                                    className="form-control"
                                    {...inputProps}
                                  />
                                )}
                              </InputMask>
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Verificação KYC
                              </Label>
                              <select
                                className="form-control"
                                value={verificacao ? verificacao : "pending"}
                                onChange={(e) => setVerificacao(e.target.value)}
                              >
                                <option
                                  value="rejected"
                                  style={{ color: "darkred" }}
                                >
                                  Reprovado
                                </option>
                                <option
                                  value="verified"
                                  style={{ color: "darkgreen" }}
                                >
                                  Aprovado
                                </option>
                                <option
                                  value="reviewNeeded"
                                  style={{ color: "darkmagenta" }}
                                >
                                  Pendente
                                </option>
                                <option
                                  value="pending"
                                  style={{ color: "darkyellow" }}
                                >
                                  Aguardando
                                </option>
                              </select>
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label">Nível KYC</Label>
                              <select
                                className="form-control"
                                value={nivelKyc ? nivelKyc : "0"}
                                onChange={(e) => {
                                  setNivelKyc(e.target.value);
                                  if (e.target.value === "1") {
                                    setLimiteMensal(500);
                                    setLimiteAnual(6000);
                                    setLimiteMensalDisabled(true);
                                  } else if (e.target.value === "2") {
                                    setLimiteMensal(2000);
                                    setLimiteAnual(24000);
                                    setLimiteMensalDisabled(true);
                                  } else setLimiteMensalDisabled(false);
                                }}
                              >
                                <option value="0">Não realizado</option>
                                <option value="1">Básico</option>
                                <option value="2">Avançado</option>
                                <option value="3">Personalizado</option>
                              </select>
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Limite Mensal
                              </Label>
                              <NumericFormat
                                className="form-control"
                                id="compraDiaria"
                                placeholder="R$ 0,00"
                                value={limiteMensal ? limiteMensal : ""}
                                onValueChange={({ value }) => {
                                  setLimiteMensal(parseFloat(value));
                                  setLimiteAnual(parseFloat(value) * 12);
                                }}
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={true}
                                prefix="R$ "
                                allowNegative={false}
                                disabled={limiteMensalDisabled}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label">Limite Anual</Label>
                              <Input
                                readOnly
                                disabled={limiteMensalDisabled}
                                type="text"
                                className="form-control"
                                value={formatCurrency(limiteAnual)}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label">Indicado Por</Label>
                              <Input
                                type="text"
                                className="form-control"
                                value={
                                  indicadoPor ? indicadoPor : "Sem Indicação"
                                }
                                onChange={(e) => setIndicadoPor(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div className="mb-3 pb-2">
                              <Label className="form-label">Endereço</Label>
                              <textarea
                                className="form-control"
                                id="exampleFormControlTextarea"
                                rows="3"
                                value={endereco ? endereco : ""}
                                onChange={(e) => setEndereco(e.target.value)}
                              ></textarea>
                            </div>
                            <div className="text-end">
                              <button
                                className="btn btn-secondary"
                                onClick={handleSubmit}
                                disabled={loading}
                              >
                                Cadastrar
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ClientesCadastro;
