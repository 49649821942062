import React, { useMemo, useState, useCallback } from "react";
import { Card, CardBody, CardHeader, Col, Spinner, Button } from "reactstrap";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import ModalEditarRede from "./ModalEditarRede";
import ModalNovaRede from "./ModalNovaRede";

//redux
import TableContainer from "../../../Components/Common/TableContainer";

const Redes = ({ redes, fetchRedes, loading, setRedes }) => {
  const [showModal, setShowModal] = useState(false);
  const [rede, setRede] = useState({});
  const [showCreateModal, setShowCreateModal] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: "Rede",
        accessor: "nome",
        filterable: true,
        Cell: (cell) => {
          return <b>{cell.value}</b>;
        },
      },
      {
        Header: "Moeda",
        accessor: "moeda",
        Cell: (cell) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <img
                  src={`https://api.fort.exchange/crypto-icons/${cell.row.original.moeda.toLowerCase()}.svg`}
                  alt=""
                  className="avatar-xxs"
                />
              </div>
              <Link to="#" className="currency_name flex-grow-1 ms-2 text-body">
                {cell.value}
              </Link>
            </div>
          </>
        ),
      },
      {
        Header: "RPC",
        accessor: "rpc",
        filterable: true,
        Cell: (cell) => {
          return <>{cell.value}</>;
        },
      },
      {
        Header: "Taxa Retirada",
        accessor: "taxa_retirada",
        filterable: false,
        Cell: (cell) => {
          return <>{parseFloat(cell.value).toFixed(8)}</>;
        },
      },
      {
        Header: "Ações",
        filterable: false,
        Cell: (cell) => {
          return (
            <>
              <Link
                to="#"
                id="edit-tooltip"
                onClick={() => {
                  setRede(cell.row.original);
                  setShowModal(true);
                }}
                title="Editar"
              >
                <FeatherIcon icon="edit-2" className="icons" width="15" />
              </Link>
            </>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      {showModal && (
        <ModalEditarRede
          show={showModal}
          rede={rede}
          setShowModal={setShowModal}
          fetchRedes={fetchRedes}
          setRedes={setRedes}
        />
      )}
      {showCreateModal && (
        <ModalNovaRede
          show={showCreateModal}
          setShowModal={setShowCreateModal}
          fetchRedes={fetchRedes}
          setRedes={setRedes}
        />
      )}
      <Col lg={12}>
        <Card>
          <CardHeader className="d-flex align-items-center border-0">
            <h5 className="card-title mb-0 flex-grow-1">Redes Cadastradas</h5>
            <div className="flex-shrink-0">
              <div className="flax-shrink-0 hstack gap-2">
                <Button
                  color="primary"
                  onClick={() => setShowCreateModal(true)}
                >
                  Nova Rede
                </Button>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  padding: "20px",
                }}
              >
                <Spinner height={100} width={100} />
              </div>
            ) : (
              <TableContainer
                columns={columns}
                data={redes || []}
                isGlobalFilter={false}
                isAddUserList={false}
                customPageSize={10}
                className="custom-header-css"
                divClass="table-responsive table-card mb-1"
                tableClass="align-middle table-nowrap"
                theadClass="table-light text-muted"
              />
            )}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default Redes;
