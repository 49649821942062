import React, { useState, useCallback, useEffect } from "react";
import {
  Col,
  Input,
  Label,
  Row,
  TabPane,
  Spinner,
  Form,
  Button,
} from "reactstrap";
import { toast } from "react-toastify";
import axios from "axios";
import InputMask from "react-input-mask";
import { NumericFormat } from "react-number-format";

const TabPerfil = ({ user_id }) => {
  const [loading, setLoading] = useState(false);
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [tipoCliente, setTipoCliente] = useState("cpf");
  const [cpf, setCpf] = useState("");
  const [situacao, setSituacao] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [rg, setRg] = useState("");
  const [telefone, setTelefone] = useState("");
  const [limiteMensal, setLimiteMensal] = useState(0);
  const [limiteAnual, setLimiteAnual] = useState(0);
  const [verificacao, setVerificacao] = useState("");
  const [indicadoPor, setIndicadoPor] = useState("");
  const [codigoParceiro, setCodigoParceiro] = useState("");
  const [nivelKyc, setNivelKyc] = useState(0);
  const [endereco, setEndereco] = useState("");
  const [limiteMensalDisabled, setLimiteMensalDisabled] = useState(false);

  const fetchClientData = useCallback(async (user_id) => {
    setLoading(true);
    const token = JSON.parse(sessionStorage.getItem("authUser"))?.token;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/client/get-client-data/${user_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status && response.status === 1) {
        return response;
      } else {
        toast.error(response.mensagem, {
          position: "top-right",
          hideProgressBar: true,
          closeOnClick: false,
          className: "bg-danger text-white",
        });
        return [];
      }
    } catch (error) {
      toast.error("Erro ao buscar dados: " + error, {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: false,
        className: "bg-danger text-white",
      });
      return [];
    } finally {
      setLoading(false);
    }
  }, []);

  const resetKyc = async () => {
    const clienteData = JSON.parse(sessionStorage.getItem("authUser"));
    try {
      const response = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_URL}/client/reset-kyc/${user_id}`,
        headers: {
          Authorization: `Bearer ${clienteData.token}`,
        },
      });

      if (response.status && response.status === 1) {
        toast.success(response.mensagem);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro na API");
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const clienteData = JSON.parse(sessionStorage.getItem("authUser"));

      let updatedFields = {};
      if (nome) updatedFields.nome_completo = nome;
      if (email) updatedFields.email = email;
      if (cpf) updatedFields.cpf_cnpj = cpf;
      if (tipoCliente)
        updatedFields.pessoa_fisica = tipoCliente === "cpf" ? true : false;
      if (situacao) updatedFields.status_cpf = situacao;
      if (dataNascimento) updatedFields.nascimento = dataNascimento;
      if (rg) updatedFields.rg = rg;
      if (telefone) updatedFields.telefone = telefone;
      if (limiteMensal) updatedFields.negociar_mensalmente = limiteMensal;
      if (limiteAnual) updatedFields.limite_anual_brl = limiteAnual;
      if (verificacao) updatedFields.metamap_status_verification = verificacao;
      if (indicadoPor && indicadoPor !== "Sem Indicação")
        updatedFields.indicacao = indicadoPor;
      if (codigoParceiro) updatedFields.codigo_parceiro = codigoParceiro;
      if (nivelKyc) updatedFields.nivel_kyc = nivelKyc;
      if (endereco) updatedFields.endereco = endereco;

      const response = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_URL}/client/clientes/${user_id}`,
        headers: {
          Authorization: `Bearer ${clienteData.token}`,
        },
        data: updatedFields,
      });

      if (response.status && response.status === 1) {
        toast.success(response.message);
      } else {
        toast.error(response.message || response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro na API");
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const retorno = await fetchClientData(user_id);
      setNome(retorno.clientData.nome_completo);
      setEmail(retorno.clientData.email);
      setCpf(retorno.clientData.cpf_cnpj);
      setTipoCliente(retorno.clientData.pessoa_fisica ? "cpf" : "cnpj");
      setSituacao(retorno.clientData.status_cpf);
      setDataNascimento(retorno.clientData.nascimento);
      setRg(retorno.clientData.rg);
      setTelefone(retorno.clientData.telefone);
      setLimiteMensal(parseFloat(retorno.clientData.negociar_mensalmente));
      setLimiteAnual(parseFloat(retorno.clientData.limite_anual_brl));
      setVerificacao(
        retorno.clientData.metamap_status_verification !== "rejected" &&
          retorno.clientData.metamap_status_verification !== "verified" &&
          retorno.clientData.metamap_status_verification !== "reviewNeeded"
          ? "pending"
          : retorno.clientData.metamap_status_verification
      );
      setIndicadoPor(
        retorno.clientData.indicacao === "fort"
          ? "Sem Indicação"
          : retorno.clientData.indicacao
      );
      setCodigoParceiro(retorno.clientData.codigo_parceiro);
      setNivelKyc(retorno.clientData.nivel_kyc);
      setEndereco(retorno.clientData.endereco);
      setLimiteMensalDisabled(retorno.clientData.nivel_kyc < 3);
    };
    fetchData();
  }, [fetchClientData, user_id]);

  const formatCurrency = (value) => {
    if (!value) {
      return "R$ 0,00";
    }
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber || !phoneNumber.startsWith("55")) {
      return phoneNumber;
    }

    const formattedNumber = phoneNumber.replace(
      /^(\d{2})(\d{2})(\d{1})(\d{4})(\d{4})$/,
      "+$1 ($2) $3 $4-$5"
    );
    return formattedNumber;
  };

  const formatDate = (dateString) => {
    if (!dateString) {
      return "";
    }

    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
  };

  const reFormatDate = (dateString) => {
    if (!dateString) {
      return "";
    }

    const [day, month, year] = dateString.split("/");
    return `${year}-${month}-${day}`;
  };

  const formatNome = (event) => {
    let value = event.target.value.toUpperCase();
    value = value.replace(/[^A-Za-z\s]/g, "");
    setNome(value);
  };

  const formatEmail = (event) => {
    let value = event.target.value;
    value = value.replace(/[^a-zA-Z0-9.\-_@]+/g, "");

    const atSymbolCount = value.split("@").length - 1;
    if (atSymbolCount > 1) {
      const index = value.lastIndexOf("@");
      value = value.slice(0, index) + value.slice(index + 1);
    }

    setEmail(value);
  };

  return (
    <TabPane tabId="1">
      {nivelKyc === 0 && (
        <Row style={{ marginBottom: "25px" }}>
          <Col lg={4}>
            <Button
              color="primary"
              onClick={() => {
                resetKyc();
                setVerificacao("pending");
              }}
            >
              Resetar processo KYC
            </Button>
          </Col>
        </Row>
      )}
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            padding: "20px",
          }}
        >
          <Spinner height={100} width={100} />
        </div>
      ) : (
        <Form>
          <Row>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="radioOptions" className="form-label">
                  Tipo
                </Label>
                <Row id="radioOptions">
                  <Col lg={6}>
                    <Input
                      className="form-check-input"
                      type="radio"
                      name="radioTipo"
                      id="radioCPF"
                      defaultValue="cpf"
                      checked={tipoCliente === "cpf"}
                      onChange={(e) => setTipoCliente(e.target.value)}
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="radioCPF"
                      style={{ marginLeft: "10px" }}
                    >
                      Pessoa Física
                    </Label>
                  </Col>
                  <Col lg={6}>
                    <Input
                      className="form-check-input"
                      type="radio"
                      name="radioTipo"
                      id="radioCNPJ"
                      defaultValue="cnpj"
                      checked={tipoCliente === "cnpj"}
                      onChange={(e) => setTipoCliente(e.target.value)}
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="radioCNPJ"
                      style={{ marginLeft: "10px" }}
                    >
                      Pessoa Jurídica
                    </Label>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label className="form-label">
                  {tipoCliente === "cpf" ? "Nome Completo" : "Razão Social"}
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  value={nome ? nome : ""}
                  onChange={formatNome}
                  maxLength={250}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label className="form-label">E-mail</Label>
                <Input
                  type="email"
                  className="form-control"
                  value={email ? email : ""}
                  onChange={formatEmail}
                  maxLength={150}
                />
              </div>
            </Col>
            {tipoCliente === "cpf" ? (
              <>
                <Col lg={6}>
                  <div className="mb-3">
                    <Label className="form-label">CPF</Label>
                    <InputMask
                      mask="999.999.999-99"
                      value={cpf ? cpf : ""}
                      placeholder="000.000.000-00"
                      onChange={(e) => setCpf(e.target.value)}
                    >
                      {(inputProps) => (
                        <Input
                          type="text"
                          className="form-control"
                          {...inputProps}
                        />
                      )}
                    </InputMask>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <Label className="form-label">Situação do CPF</Label>
                    <select
                      className="form-control"
                      value={situacao ? situacao : ""}
                      onChange={(e) => setSituacao(e.target.value)}
                    >
                      <option value=""></option>
                      <option value="REGULAR">REGULAR</option>
                      <option value="IRREGULAR">IRREGULAR</option>
                    </select>
                  </div>
                </Col>

                <Col lg={6}>
                  <div className="mb-3">
                    <Label className="form-label">RG</Label>
                    <InputMask
                      mask="99.999.999-9"
                      value={rg ? rg : ""}
                      onChange={(e) => setRg(e.target.value)}
                    >
                      {(inputProps) => (
                        <Input
                          type="text"
                          className="form-control"
                          {...inputProps}
                        />
                      )}
                    </InputMask>
                  </div>
                </Col>
              </>
            ) : (
              <>
                <Col lg={6}>
                  <div className="mb-3">
                    <Label className="form-label">CNPJ</Label>
                    <InputMask
                      mask="99.999.999/9999-99"
                      placeholder="00.000.000/0000-00"
                      value={cpf ? cpf : ""}
                      onChange={(e) => setCpf(e.target.value)}
                    >
                      {(inputProps) => (
                        <Input
                          type="text"
                          className="form-control"
                          {...inputProps}
                        />
                      )}
                    </InputMask>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <Label className="form-label">Situação do CNPJ</Label>
                    <select
                      className="form-control"
                      value={situacao ? situacao : ""}
                      onChange={(e) => setSituacao(e.target.value)}
                    >
                      <option value=""></option>
                      <option value="REGULAR">REGULAR</option>
                      <option value="IRREGULAR">IRREGULAR</option>
                    </select>
                  </div>
                </Col>
              </>
            )}

            <Col lg={6}>
              <div className="mb-3">
                <Label className="form-label">
                  {tipoCliente === "cpf" ? "Nascimento" : "Início da Empresa"}
                </Label>
                <Input
                  type="date"
                  className="form-control"
                  value={dataNascimento ? dataNascimento : ""}
                  onChange={(e) => setDataNascimento(e.target.value)}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label className="form-label">Telefone</Label>
                <InputMask
                  mask="(99) 99999-9999"
                  value={telefone ? telefone : ""}
                  onChange={(e) => setTelefone(e.target.value)}
                >
                  {(inputProps) => (
                    <Input
                      type="text"
                      className="form-control"
                      {...inputProps}
                    />
                  )}
                </InputMask>
              </div>
            </Col>

            <Col lg={6}>
              <div className="mb-3">
                <Label className="form-label">Verificação KYC</Label>
                <select
                  className="form-control"
                  value={verificacao ? verificacao : "pending"}
                  onChange={(e) => setVerificacao(e.target.value)}
                >
                  <option value="rejected" style={{ color: "darkred" }}>
                    Reprovado
                  </option>
                  <option value="verified" style={{ color: "darkgreen" }}>
                    Aprovado
                  </option>
                  <option value="reviewNeeded" style={{ color: "darkmagenta" }}>
                    Pendente
                  </option>
                  <option value="pending" style={{ color: "darkyellow" }}>
                    Aguardando
                  </option>
                </select>
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label className="form-label">Nível KYC</Label>
                <select
                  className="form-control"
                  value={nivelKyc ? nivelKyc : "0"}
                  onChange={(e) => {
                    setNivelKyc(e.target.value);
                    if (e.target.value === "1") {
                      setLimiteMensal(500);
                      setLimiteAnual(6000);
                      setLimiteMensalDisabled(true);
                    } else if (e.target.value === "2") {
                      setLimiteMensal(2000);
                      setLimiteAnual(24000);
                      setLimiteMensalDisabled(true);
                    } else setLimiteMensalDisabled(false);
                  }}
                >
                  <option value="0">Não realizado</option>
                  <option value="1">Básico</option>
                  <option value="2">Avançado</option>
                  <option value="3">Personalizado</option>
                </select>
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label className="form-label">Limite Mensal</Label>
                <NumericFormat
                  className="form-control"
                  id="compraDiaria"
                  placeholder="R$ 0,00"
                  value={limiteMensal ? limiteMensal : ""}
                  onValueChange={({ value }) => {
                    setLimiteMensal(parseFloat(value));
                    setLimiteAnual(parseFloat(value) * 12);
                  }}
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix="R$ "
                  allowNegative={false}
                  disabled={limiteMensalDisabled}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label className="form-label">Limite Anual</Label>
                <Input
                  readOnly
                  disabled={limiteMensalDisabled}
                  type="text"
                  className="form-control"
                  value={formatCurrency(limiteAnual)}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label className="form-label">Indicado Por</Label>
                <Input
                  type="text"
                  className="form-control"
                  value={indicadoPor ? indicadoPor : "Sem Indicação"}
                  onChange={(e) => setIndicadoPor(e.target.value)}
                />
              </div>
            </Col>

            <Col lg={6}>
              <div className="mb-3">
                <Label className="form-label">Código Parceiro</Label>
                <Input
                  type="text"
                  className="form-control"
                  value={codigoParceiro ? codigoParceiro : ""}
                  onChange={(e) => setCodigoParceiro(e.target.value)}
                />
              </div>
            </Col>
            <Col lg={12}>
              <div className="mb-3 pb-2">
                <Label className="form-label">Endereço</Label>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea"
                  rows="3"
                  value={endereco ? endereco : ""}
                  onChange={(e) => setEndereco(e.target.value)}
                ></textarea>
              </div>
              <div className="text-end">
                <button className="btn btn-primary" onClick={handleSubmit}>
                  Salvar
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </TabPane>
  );
};

export default TabPerfil;
