import React, { useMemo, useCallback, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Spinner, Button } from "reactstrap";

import FeatherIcon from "feather-icons-react";
import TableContainer from "../../../Components/Common/TableContainer";
import moment from "moment";
import { toast } from "react-toastify";
import axios from "axios";

const AllOrders = ({ orderList, loading, setShouldFetch }) => {
  const [loadingButton, setLoadingButton] = useState(false);

  function formatName(name) {
    let lowerCaseName = name.toLowerCase();
    let properName = lowerCaseName.replace(
      /\b\w/g,
      function (letter, index, fullText) {
        const previousLetter = fullText[index - 1];
        const isWordAfterApostrophe = previousLetter === "'";
        if (isWordAfterApostrophe) {
          return letter;
        }
        const word = fullText.slice(index).match(/^\w+/)[0];
        const lowerCaseWords = ["de", "da", "do", "dos", "das"];
        if (lowerCaseWords.includes(word)) {
          return letter;
        }
        return letter.toUpperCase();
      }
    );

    // Converte a letra após um apóstrofo para maiúscula
    properName = properName.replace(/'\w/g, function (letter) {
      return letter.toUpperCase();
    });

    return properName;
  }

  const marcarPago = useCallback(
    async (ordem_id) => {
      setLoadingButton(true);
      const user = JSON.parse(sessionStorage.getItem("authUser"));
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/marcar-pago/${ordem_id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        if (response.status && response.status === 1) {
          toast.success(response.mensagem);
          setShouldFetch(true);
          console.log(response);
        } else {
          toast.error(response.mensagem);
          console.log(response);
        }
      } catch (error) {
        toast.error("Erro na API.");
        console.log(error);
      } finally {
        setLoadingButton(false);
      }
    },
    [setShouldFetch]
  );

  const formatCurrency = (value) => {
    if (!value) {
      return "";
    }
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Data",
        accessor: "created_at",
        filterable: true,
        Cell: (cell) => {
          const date = moment(cell.value);
          return (
            <>
              <b>{date.format("DD/MM/YYYY")} </b>
              <small className="text-muted">{date.format("HH:mm")}</small>{" "}
            </>
          );
        },
      },
      {
        Header: "Cliente",
        accessor: "user.name",
        filterable: true,
        Cell: (cell) => (
          <>
            <div className="d-flex align-items-center">
              <Link to="#" className="currency_name flex-grow-1 ms-2 text-body">
                {cell.value === null ? "" : formatName(cell.value)}
              </Link>
            </div>
          </>
        ),
      },
      {
        Header: "Order ID",
        accessor: "order_id",
        filterable: true,
        Cell: (cell) => {
          return <b style={{ color: "darkBlue" }}>{cell.value}</b>;
        },
      },
      {
        Header: "Valor",
        accessor: "amount",
        filterable: false,
        Cell: (cell) => {
          return <>{formatCurrency(cell.value)}</>;
        },
      },
      {
        Header: "Status",
        accessor: "status_id",
        filterable: true,
        Cell: (cell) => {
          return (
            <>
              {parseInt(cell.value) === 0 ? (
                <span className="badge badge-soft-danger text-uppercase">
                  Falha
                </span>
              ) : parseInt(cell.value) === 1 ? (
                <span className="badge badge-soft-success text-uppercase">
                  Completado
                </span>
              ) : parseInt(cell.value) === -1 ? (
                <span className="badge badge-soft-danger text-uppercase">
                  Inválido
                </span>
              ) : (
                <span className="badge badge-soft-warning text-uppercase">
                  Aguardando
                </span>
              )}
            </>
          );
        },
      },
      {
        Header: "Ações",
        filterable: false,
        Cell: (order) => {
          return (
            <>
              {parseInt(order.row.original.status_id) > 1 ? (
                <div>
                  <Button
                    id={`copyTooltip${order.row.original.order_id}`}
                    color="success"
                    onClick={() => {
                      navigator.clipboard.writeText(order.row.original.qrcode);
                      toast.success(
                        "QR Code copiado para a área de transferência"
                      );
                    }}
                    className="btn-sm"
                  >
                    <FeatherIcon
                      icon="copy"
                      style={{ color: "white", height: "10px" }}
                    />
                    Copiar QR{" "}
                  </Button>
                  {JSON.parse(sessionStorage.getItem("authUser"))?.data
                    ?.role === "Admin" && (
                    <Button
                      color="info"
                      onClick={() => {
                        marcarPago(order.row.original.order_id);
                      }}
                      className="btn-sm"
                      title="Será creditado o valor do depósito na conta do cliente"
                      style={{ marginLeft: "5px" }}
                      disabled={loadingButton}
                    >
                      Marcar Pago
                    </Button>
                  )}
                </div>
              ) : (
                moment().diff(order.row.original.created_at, 'days') < 7 &&
                parseInt(order.row.original.status_id) !== 1 &&
                JSON.parse(sessionStorage.getItem("authUser"))?.data?.role ===
                  "Admin" &&
                  (
                  <Button
                    color="info"
                    onClick={() => {
                      marcarPago(order.row.original.order_id);
                    }}
                    className="btn-sm"
                    title="Será creditado o valor do depósito na conta do cliente"
                    style={{ marginLeft: "5px" }}
                    disabled={loadingButton}
                  >
                    Marcar Pago
                  </Button>
                )
              )}
            </>
          );
        },
      },
    ],
    [marcarPago, loadingButton]
  );

  return (
    <React.Fragment>
      <Col lg={12}>
        <Card>
          <CardHeader className="d-flex align-items-center border-0">
            <h5 className="card-title mb-0 flex-grow-1">Depósitos PIX</h5>
          </CardHeader>
          <CardBody>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Spinner height={100} width={100} />
              </div>
            ) : (
              <TableContainer
                columns={columns}
                data={orderList || []}
                isGlobalFilter={true}
                isAddUserList={false}
                customPageSize={20}
                className="custom-header-css"
                divClass="table-responsive table-card mb-4"
                tableClass="align-middle table-nowrap mb-0"
                theadClass="table-light table-nowrap"
                thClass="table-light text-muted"
              />
            )}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default AllOrders;
