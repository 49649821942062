import { useEffect } from "react";

const ChatScript = ({ userName, userEmail }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.octadesk.com/embed.js";
    script.async = true;

    window.octadesk = window.octadesk || {};
    window.octadesk.chatOptions = {
      subDomain: "fortservicosdigitaisltda",
      showButton: true,
      openOnMessage: true,
      hide: false,
    };

    // Função de fallback para erro de carregamento do script
    const onScriptError = () => {
      console.error('Não foi possível carregar o script do chat.');
      // Aqui você pode chamar alguma lógica de fallback ou simplesmente ignorar o erro.
    };

    script.addEventListener('error', onScriptError);

    document.body.appendChild(script);

    const onChatReady = () => {
      if (window.octadesk && window.octadesk.chat && typeof window.octadesk.chat.login === 'function') {
        window.octadesk.chat.login({
          name: userName,
          email: userEmail,
        });
      }
    };

    window.addEventListener("onOctaChatReady", onChatReady);

    return () => {
      script.removeEventListener('error', onScriptError);
      document.body.removeChild(script);
      window.removeEventListener("onOctaChatReady", onChatReady);
    };
  }, [userName, userEmail]);

  return null;
};

export default ChatScript;
