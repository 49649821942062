import React from "react";
import CountUp from "react-countup";
import { Card, CardBody, Col, Spinner, Button } from "reactstrap";
import FeatherIcon from "feather-icons-react";

const Widgets = ({
  cryptoWidgets,
  loading,
  setFetch,
  // verValores,
  // setVerValores,
  // verValoresAtivado,
}) => {
  return (
    <React.Fragment>
      {(cryptoWidgets || []).map((item, key) => (
        <Col lg={4} md={6} key={key}>
          <Card>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  padding: "20px",
                }}
              >
                <Spinner height={100} width={100} />
              </div>
            ) : (
              <CardBody>
                <div className="d-flex align-items-center">
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title bg-light text-primary rounded-circle fs-3">
                      <i className={"align-middle " + item.icon}></i>
                    </span>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                      {item.label}{" "}
                    </p>
                    <h4 className=" mb-0">
                      {" "}
                      <CountUp
                        start={0}
                        end={item.counter}
                        decimals={item.decimal}
                        separator={item.separator}
                        prefix={item.prefix}
                        duration={3}
                      />
                    </h4>
                  </div>
                  {key === 0 && (
                    <div className="d-flex align-items-right">
                      <Button
                        className="btn btn-sm"
                        color="warning"
                        title="Atualizar"
                        onClick={() => {
                          setFetch(true);
                        }}
                      >
                        <FeatherIcon width="12" icon="refresh-cw" />
                      </Button>
                    </div>
                  )}
                </div>
              </CardBody>
            )}
          </Card>
        </Col>
      ))}
    </React.Fragment>
  );
};

export default Widgets;
