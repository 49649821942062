import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Spinner,
  Button,
  Badge,
} from "reactstrap";

import FeatherIcon from "feather-icons-react";
import TableContainer from "../../../Components/Common/TableContainer";
import ModalNewOrder from "./ModalNewOrder";
import moment from "moment";
import { toast } from "react-toastify";
import axios from "axios";

const AllOrders = ({ user, orderList, loading, setShouldFetch }) => {
  const [modalOrder, setmodalOrder] = useState(false);
  const [invoiceId, setInvoiceId] = useState(0);
  const [loadingStatus, setLoading] = useState(false);

  function formatName(name) {
    let lowerCaseName = name.toLowerCase();
    let properName = lowerCaseName.replace(
      /\b\w/g,
      function (letter, index, fullText) {
        const previousLetter = fullText[index - 1];
        const isWordAfterApostrophe = previousLetter === "'";
        if (isWordAfterApostrophe) {
          return letter;
        }
        const word = fullText.slice(index).match(/^\w+/)[0];
        const lowerCaseWords = ["de", "da", "do", "dos", "das"];
        if (lowerCaseWords.includes(word)) {
          return letter;
        }
        return letter.toUpperCase();
      }
    );

    // Converte a letra após um apóstrofo para maiúscula
    properName = properName.replace(/'\w/g, function (letter) {
      return letter.toUpperCase();
    });

    return properName;
  }

  const formatCurrency = (value) => {
    if (!value) {
      return "";
    }
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  const updateOrder = useCallback(
    async (order_id) => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/operacoes/show/${order_id}`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        if (response.status && response.status === 1) {
          console.log(response);
          setShouldFetch();
        } else {
          console.log(response);
          toast.error(response.mensagem);
        }
      } catch (error) {
        toast.error("Erro ao buscar operações.");
        console.log(error);
      }
      setLoading(false);
    },
    [user.token, setShouldFetch]
  );

  useEffect(() => {
    if (invoiceId === 0) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const getInvoiceId = urlParams.get("invoiceId");
      
      if (getInvoiceId !== null) {
        setInvoiceId(parseInt(getInvoiceId));
        updateOrder(getInvoiceId);
      }
    }
  }, [invoiceId,updateOrder]);  

  const columns = useMemo(
    () => [
      {
        Header: "Data",
        accessor: "created_at",
        filterable: true,
        Cell: (cell) => {
          const date = moment(cell.value);
          return (
            <>
              <b>{date.format("DD/MM/YYYY")} </b>
              <small className="text-muted">{date.format("HH:mm")}</small>{" "}
            </>
          );
        },
      },
      {
        Header: "Produto",
        accessor: "produto",
        filterable: true,
        Cell: (cell) => (
          <>
            <div className="d-flex align-items-center">
              <Link to="#" className="currency_name flex-grow-1 ms-2 text-body">
                {cell.value === null ? "" : formatName(cell.value)}
              </Link>
            </div>
          </>
        ),
      },
      {
        Header: "Order ID",
        accessor: "btc_pay_id",
        filterable: true,
        Cell: (cell) => {
          return <b style={{ color: "darkBlue" }}>{cell.value}</b>;
        },
      },
      {
        Header: "Valor",
        accessor: "brl_amount",
        filterable: false,
        Cell: (cell) => {
          return <>{formatCurrency(cell.value)}</>;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        Cell: (order) => {
          return (
            <>
              {loadingStatus && parseInt(order.row.original.id) === parseInt(invoiceId)  ? (
                <Spinner />
              ) : order.row.original.status === "nova" ? (
                <Badge color="info">Nova</Badge>
              ) : order.row.original.status === "paga" ? (
                <Badge color="success">Paga</Badge>
              ) : order.row.original.status === "expirada" ? (
                <Badge color="dark">Expirada</Badge>
              ) : (
                <Badge color="danger">Cancelada</Badge>
              )}
            </>
          );
        },
      },
      {
        Header: "Ações",
        filterable: false,
        Cell: (order) => {
          const dados_invoice = JSON.parse(order.row.original.dados_invoice);
          return (
            <>
              {loadingStatus && parseInt(order.row.original.id) === parseInt(invoiceId) ? (
                <Spinner />
              ) : (
                order.row.original.status === "nova" && (
                  <Link
                    to={dados_invoice.checkoutLink}
                    className="btn btn-sm btn-primary"
                    target="_self"
                  >
                    Pagar
                  </Link>
                )
              )}
            </>
          );
        },
      },
    ],
    [invoiceId, loadingStatus]
  );
  return (
    <React.Fragment>
      <ModalNewOrder
        lojista_id={user?.data?._id}
        setmodalOrder={setmodalOrder}
        show={modalOrder}
        setShouldFetch={setShouldFetch}
      />
      <Col lg={12}>
        <Card>
          <CardHeader className="d-flex align-items-center border-0">
            <h5 className="card-title mb-0 flex-grow-1">
              Ordens de Venda de Produtos
            </h5>
            <div className="flex-shrink-0">
              <div className="flax-shrink-0 hstack gap-2">
                <Button
                  color="primary"
                  className="btn btn-success"
                  onClick={() => setmodalOrder(true)}
                >
                  Nova Ordem
                </Button>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Spinner height={100} width={100} />
              </div>
            ) : (
              <TableContainer
                columns={columns}
                data={orderList || []}
                isGlobalFilter={true}
                isAddUserList={false}
                customPageSize={20}
                className="custom-header-css"
                divClass="table-responsive table-card mb-4"
                tableClass="align-middle table-nowrap mb-0"
                theadClass="table-light table-nowrap"
                thClass="table-light text-muted"
              />
            )}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default AllOrders;
