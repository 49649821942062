import React, { useState, useRef } from "react";
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
  Spinner,
} from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
import BigNumber from "bignumber.js";

const ModalProcessar = ({
  setmodalProcessar,
  show,
  setShouldFetch,
  retirada,
}) => {
  const [loading, setLoading] = useState(false);
  const [txid, setTxid] = useState("");
  const [arquivo, setArquivo] = useState("");
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setArquivo(file);
    }
  };

  const processarRetirada = async (event) => {
    event.preventDefault();
    setLoading(true);
    const clientData = JSON.parse(sessionStorage.getItem("authUser") || "{}");

    const formData = new FormData();
    if (retirada.tipo === "cripto") {
      formData.append("txid", txid);
    } else if (retirada.tipo === "pix" && arquivo) {
      formData.append("recibo", arquivo);
    }

    console.log("FormData:", formData);
    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/retiradas-cripto/update-txid/${retirada.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${clientData.token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response && response.status === 1) {
        toast.success(response.mensagem);
        setmodalProcessar(!show);
        setShouldFetch(true);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro ao criar retirada");
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <Modal
      isOpen={show}
      backdrop={"static"}
      toggle={() => {
        setmodalProcessar(!show);
      }}
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          setmodalProcessar(!show);
        }}
      >
        Processar Retirada {retirada.tipo === "cripto" ? "Cripto" : "Pix"}
      </ModalHeader>
      <ModalBody>
        <form>
          <div className="row g-3">
            <Col md={4}>
              <div>
                <Label className="form-Label">Moeda</Label>
                <Input
                  type="text"
                  className="form-control"
                  value={retirada.moeda}
                  onChange={() => {}}
                  readOnly={true}
                />
              </div>
            </Col>
            <Col md={8}>
              <div>
                <Label htmlFor="compraDiaria" className="form-Label">
                  Valor
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  value={BigNumber(retirada.amount).toFixed(
                    retirada.moeda === "BTC"
                      ? 8
                      : retirada.moeda === "USDC" || retirada.moeda === "USDT"
                      ? 6
                      : 18
                  )}
                  onChange={() => {}}
                  readOnly={true}
                />
              </div>
            </Col>
            <Col md={12}>
              <div>
                <Label htmlFor="destino" className="form-Label">
                  Destino
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  value={retirada.destino}
                  onChange={() => {}}
                  readOnly={true}
                />
              </div>
            </Col>
            <Col md={12}>
              {retirada.tipo === "cripto" ? (
                <div>
                  <Label htmlFor="destino" className="form-Label">
                    Link da Transação
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    value={txid}
                    onChange={(e) => setTxid(e.target.value)}
                  />
                </div>
              ) : (
                <div>
                  <Label htmlFor="destino" className="form-Label">
                    Arquivo do Recibo
                  </Label>
                  <Input
                    type="file"
                    className="form-control"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                  />
                </div>
              )}
            </Col>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Spinner height={100} width={100} />
              </div>
            ) : (
              <Col lg={12}>
                <div className="hstack gap-2 justify-content-end">
                  <Button
                    color="light"
                    onClick={() => setmodalProcessar(false)}
                  >
                    Fechar
                  </Button>
                  <Button
                    color="primary"
                    type="button"
                    disabled={
                      loading ||
                      (retirada.tipo === "cripto" && !txid) ||
                      (retirada.tipo === "pix" && !arquivo)
                    }
                    onClick={(e) => processarRetirada(e)}
                  >
                    Processar
                  </Button>
                </div>
              </Col>
            )}
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};
export default ModalProcessar;
