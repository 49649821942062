import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
} from "reactstrap";
import ParticlesAuth from "../../AuthenticationInner/ParticlesAuth";
import axios from "axios";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import ChatScript from "../../../Layouts/ChatScript";

import { Link } from "react-router-dom";
import logoLight from "../../../assets/images/logo-light2.png";

const CreatePasswLojista = () => {
  const [email, setEmail] = useState("");
  const [razaoSocial, setRazaoSocial] = useState("");
  const [cep, setCep] = useState("");
  const [endereco, setEndereco] = useState("");
  const [numero, setNumero] = useState("");
  const [complemento, setComplemento] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const [bairro, setBairro] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [inicioEmpresa, setInicioEmpresa] = useState("");
  const [codigoParceiro, setCodigoParceiro] = useState("");

  const [loading, setLoading] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);

  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [password2Invalid, setPassword2Invalid] = useState(false);

  const errorsEmail = "E-mail inválido ou vazio.";
  const errorsPassword =
    "Senha deve ter no mínimo 8 caracteres, 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial.";
  const errorsPassword2 = "As senhas devem ser iguais.";

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const postData = {
        email,
        codigo_parceiro: codigoParceiro,
        password: password,
        password_confirmation: password2,
        razao_social: razaoSocial,
        cep,
        endereco,
        numero,
        complemento,
        cidade,
        estado,
        bairro,
        cnpj,
        inicio_empresa: inicioEmpresa,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/lojista`,
        postData
      );

      if (response.status && response.status === 1) {
        toast.success(response.mensagem);
        setTimeout(() => {
          window.location.href = "/login";
        }, 3000);
      } else if (response.mensagem) {
        toast.error(response.mensagem);
        console.log(response);
      } else {
        console.log(response);
        toast.error("Erro realizar o cadastro!");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast.error("Ocorreu um erro desconhecido.");
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const codigo = urlParams.get("codigo_parceiro");
    if (codigo) {
      setCodigoParceiro(codigo);
    } else {
      setCodigoParceiro("fort");
    }
  }, []);

  const formatEmail = (event) => {
    let value = event.target.value;
    value = value.replace(/[^a-zA-Z0-9.\-_@]+/g, "");

    const atSymbolCount = value.split("@").length - 1;
    if (atSymbolCount > 1) {
      const index = value.lastIndexOf("@");
      value = value.slice(0, index) + value.slice(index + 1);
    }
    if (!validateEmail(value)) {
      setEmailInvalid(true);
    } else {
      setEmailInvalid(false);
    }

    setEmail(value);
  };

  const validatePassword = (pass) => {
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d\s])[\S]{8,}$/;
    return re.test(String(pass));
  };

  const handlePasswordChange = (event) => {
    const val = event.target.value;
    setPassword(val);
    if (!validatePassword(val)) {
      setPasswordInvalid(true);
    } else {
      setPasswordInvalid(false);
    }
  };

  const handleCepBlur = async (cep_) => {
    try {
      const _cep = cep_.replace(/[^0-9]/, "");
      const response = await axios.get(`https://viacep.com.br/ws/${_cep}/json`);

      if (response.logradouro) {
        setEndereco(response.logradouro);
        setCidade(response.localidade);
        setBairro(response.bairro);
        setEstado(response.uf);
      }
    } catch (error) {
      console.log("Erro ao buscar CEP.");
    }
  };

  const handlePassword2Change = (event) => {
    const val = event.target.value;
    setPassword2(val);
    if (password !== password2) {
      setPassword2Invalid(true);
    } else {
      setPassword2Invalid(false);
    }
  };

  document.title = "Criação de conta | Painel Fort Exchange";
  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoLight} alt="" height="70" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={8} xl={8}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Nova Conta de Loja</h5>
                      <p className="text-muted">
                        Preencha os campos abaixo para criar uma nova conta para sua loja.
                      </p>
                    </div>
                    <div className="p-2 mt-4">
                      <Form onSubmit={handleSubmit} action="#">
                        <Row>
                          <Col md={6}>
                            <div>
                              <label className="form-label m-2">Razão Social</label>
                              <Input
                                type="text"
                                className="form-control"
                                value={razaoSocial}
                                onChange={(e) => setRazaoSocial(e.target.value)}
                                disabled={loading}
                              />
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label m-2">E-mail</Label>
                              <Input
                                type="email"
                                className="form-control"
                                value={email}
                                onChange={(event) => formatEmail(event)}
                                maxLength={150}
                                disabled={loading}
                              />
                              {emailInvalid && (
                                <FormFeedback type="invalid">
                                  {errorsEmail}
                                </FormFeedback>
                              )}
                            </div>
                          </Col>
                          <Col md={6}>
                            <div>
                              <label className="form-label m-2">CNPJ</label>
                              <InputMask
                                mask="99.999.999/9999-99"
                                placeholder="00.000.000/0000-00"
                                value={cnpj}
                                disabled={loading}
                                onChange={(e) => {
                                  setCnpj(e.target.value);
                                }}
                              >
                                {(inputProps) => (
                                  <Input
                                    type="text"
                                    className="form-control"
                                    {...inputProps}
                                  />
                                )}
                              </InputMask>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label m-2">Inicio da Empresa</Label>
                              <Input
                                type="date"
                                className="form-control"
                                value={inicioEmpresa}
                                onChange={(e) => setInicioEmpresa(e.target.value)}
                                disabled={loading}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div>
                              <label className="form-label m-2">CEP</label>
                              <InputMask
                                mask="99999-999"
                                placeholder="00000-000"
                                value={cep}
                                disabled={loading}
                                onBlur={(e) => {
                                  handleCepBlur(e.target.value);
                                }}
                                onChange={(e) => {
                                  setCep(e.target.value);
                                }}
                              >
                                {(inputProps) => (
                                  <Input
                                    type="text"
                                    className="form-control"
                                    {...inputProps}
                                  />
                                )}
                              </InputMask>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div>
                              <label className="form-label m-2">Cidade</label>
                              <Input
                                maxLength={255}
                                type="text"
                                className="form-control"
                                value={cidade}
                                onChange={(e) => setCidade(e.target.value)}
                                disabled={loading}
                              />
                            </div>
                          </Col>
                          <Col md={12}>
                            <div>
                              <label className="form-label m-2">Endereço</label>
                              <Input
                                maxLength={255}
                                type="text"
                                className="form-control"
                                value={endereco}
                                onChange={(e) => setEndereco(e.target.value)}
                                disabled={loading}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div>
                              <label className="form-label m-2">Número</label>
                              <Input
                                type="number"
                                className="form-control"
                                value={numero}
                                onChange={(e) => setNumero(e.target.value)}
                                disabled={loading}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div>
                              <label className="form-label m-2">Complemento</label>
                              <Input
                                maxLength={255}
                                type="text"
                                className="form-control"
                                value={complemento}
                                onChange={(e) => setComplemento(e.target.value)}
                                disabled={loading}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div>
                              <label className="form-label m-2">Bairro</label>
                              <Input
                                maxLength={255}
                                type="text"
                                className="form-control"
                                value={bairro}
                                onChange={(e) => setBairro(e.target.value)}
                                disabled={loading}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div>
                              <label className="form-label m-2">Estado</label>
                              <Input
                                type="select"
                                className="form-control"
                                value={estado}
                                onChange={(e) => setEstado(e.target.value)}
                                disabled={loading}
                              >
                                <option value="AC">Acre</option>
                                <option value="AL">Alagoas</option>
                                <option value="AP">Amapá</option>
                                <option value="AM">Amazonas</option>
                                <option value="BA">Bahia</option>
                                <option value="CE">Ceará</option>
                                <option value="DF">Distrito Federal</option>
                                <option value="ES">Espírito Santo</option>
                                <option value="GO">Goiás</option>
                                <option value="MA">Maranhão</option>
                                <option value="MT">Mato Grosso</option>
                                <option value="MS">Mato Grosso do Sul</option>
                                <option value="MG">Minas Gerais</option>
                                <option value="PA">Pará</option>
                                <option value="PB">Paraíba</option>
                                <option value="PR">Paraná</option>
                                <option value="PE">Pernambuco</option>
                                <option value="PI">Piauí</option>
                                <option value="RJ">Rio de Janeiro</option>
                                <option value="RN">Rio Grande do Norte</option>
                                <option value="RS">Rio Grande do Sul</option>
                                <option value="RO">Rondônia</option>
                                <option value="RR">Roraima</option>
                                <option value="SC">Santa Catarina</option>
                                <option value="SP">São Paulo</option>
                                <option value="SE">Sergipe</option>
                                <option value="TO">Tocantins</option>
                              </Input>
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="newpasswordInput"
                                className="form-label m-2"
                              >
                                Senha*
                              </Label>
                              <Input
                                name="password"
                                className="form-control"
                                type="password"
                                onChange={handlePasswordChange}
                                onBlur={handlePasswordChange}
                                value={password}
                                invalid={passwordInvalid}
                                disabled={loading}
                              />
                              {passwordInvalid && (
                                <FormFeedback type="invalid">
                                  {errorsPassword}
                                </FormFeedback>
                              )}
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="confirmpasswordInput"
                                className="form-label m-2"
                              >
                                Confirmação de Senha*
                              </Label>
                              <Input
                                name="password_confirmation"
                                className="form-control"
                                type="password"
                                onChange={handlePassword2Change}
                                onBlur={handlePassword2Change}
                                value={password2}
                                invalid={password2Invalid}
                                disabled={loading}
                              />
                              {password2Invalid && (
                                <FormFeedback type="invalid">
                                  {errorsPassword2}
                                </FormFeedback>
                              )}
                            </div>
                          </Col>
                          <div className="mt-4">
                            <Button
                              color="success"
                              disabled={loading}
                              className="btn btn-success w-100"
                              type="submit"
                            >
                              {loading ? "Enviando..." : "Enviar"}
                            </Button>
                          </div>
                        </Row>
                      </Form>
                    </div>
                  </CardBody>
                </Card>

                <div className="mt-4 text-center">
                  <p className="mb-0">
                    Voltar para a{" "}
                    <Link
                      to="/auth-signin-basic"
                      className="fw-bold text-primary text-decoration-underline"
                    >
                      {" "}
                      página de Login{" "}
                    </Link>{" "}
                  </p>
                </div>
              </Col>
            </Row>
            <ChatScript />
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default CreatePasswLojista;
