import React, { useState, useCallback, useEffect, useRef } from "react";
import { Col, Label, Row, TabPane, Input, Spinner } from "reactstrap";
import { toast } from "react-toastify";
import axios from "axios";

const TabConfig = ({ user_id, lojista, setLojista }) => {
  const [loading, setLoading] = useState(false);

  const toggleConversao = async (e) => {
    e.preventDefault();
    setLoading(true);

    const user = JSON.parse(sessionStorage.getItem("authUser") || "{}");
    try {
      const response = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_URL}/lojista/toggle-auto-convert/${user_id}`,
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (response.status && response.status === 1) {
        toast.success(response.mensagem);
        lojista.auto_converter = !lojista.auto_converter;
        setLojista(lojista);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro na API");
      console.log(error);
    }

    setLoading(false);
  };

  return (
    <TabPane tabId="4">
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            padding: "20px",
          }}
        >
          <Spinner height={100} width={100} />
        </div>
      ) : (
        <>
          <Row>
            <Col lg={4}>
              <div className="form-check form-switch mb-3">
                <Input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  defaultChecked={lojista.auto_converter}
                  onChange={toggleConversao}
                />
                <Label className="form-check-label" htmlFor="SwitchCheck1">
                  Auto Converter Ordens de vendas para R$?
                </Label>
              </div>
            </Col>
          </Row>
        </>
      )}
    </TabPane>
  );
};

export default TabConfig;
