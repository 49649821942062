import React, { useEffect, useState, useCallback } from "react";
import { Container, Row } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import AllOrders from "./AllOrders";
import axios from "axios";
import { toast } from "react-toastify";

const Transacoes = () => {
  const [transacoes, setTransacoes] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchTransactions = useCallback(async () => {
    setLoading(true);
    const user = JSON.parse(sessionStorage.getItem("authUser"));
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/cripto-td/transacoes/${user.data._id}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (response?.status === 1) {
        setLoading(false);
        return response.data;
      } else {
        toast.error(response.mensagem, {
          position: "top-right",
          hideProgressBar: true,
          closeOnClick: false,
          className: "bg-danger text-white",
        });

        setLoading(false);
        return [];
      }
    } catch (error) {
      toast.error("Erro ao buscar operações: " + error, {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: false,
        className: "bg-danger text-white",
      });
      setLoading(false);
      return [];
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const retorno = await fetchTransactions();
      setTransacoes(retorno);
    };

    fetchData();
  }, [fetchTransactions]);

  document.title = "Transações | Painel Fort Exchange";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Transações" pageTitle="Transações" />
          <Row>
            <AllOrders orderList={transacoes} loading={loading} />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Transacoes;
