import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
} from "reactstrap";
import classnames from "classnames";
import TabPerfil from "./TabPerfil";
import TabSenha from "./TabSenha";
import TabSaldos from "./TabSaldos";
import TabRelatorio from "./TabRelatorio";
import Tab2fa from "./Tab2fa";

//import images
import progileBg from "../../../../assets/images/profile-bg.jpg";

const Settings = () => {
  const [activeTab, setActiveTab] = useState("1");

  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  document.title = "Perfil do Cliente | Painel Fort Exchange";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="position-relative mx-n4 mt-n4">
            <div className="profile-wid-bg profile-setting-img">
              <img src={progileBg} className="profile-wid-img" alt="" />
              <div className="overlay-content">
                <div className="text-end p-3">
                  <div className="p-0 ms-auto rounded-circle profile-photo-edit"></div>
                </div>
              </div>
            </div>
          </div>
          <Row>
            <Col xxl={12}>
              <Card className="mt-xxl-n5">
                <CardHeader>
                  <Nav
                    className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          tabChange("1");
                        }}
                      >
                        <i className="mdi mdi-account-circle"> </i>
                        Perfil
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        to="#"
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          tabChange("2");
                        }}
                        type="button"
                      >
                        <i className="ri ri-settings-5-fill"> </i>
                        Configurações
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "3" })}
                        onClick={() => {
                          tabChange("3");
                        }}
                      >
                        <i className="mdi mdi-account-cash"> </i>
                        Depósito e Retiradas
                      </NavLink>
                    </NavItem>
                    
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "4" })}
                        onClick={() => {
                          tabChange("4");
                        }}
                      >
                        <i className="ri ri-file-list-3-line"> </i>
                        Relatórios
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "5" })}
                        onClick={() => {
                          tabChange("5");
                        }}
                      >
                        <i className="ri ri-lock-2-fill"> </i>
                        Autenticação 2FA
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>
                <CardBody className="p-4">
                  <TabContent activeTab={activeTab}>
                    <TabPerfil />
                    <TabSenha />
                    <TabSaldos />
                    <TabRelatorio />
                    <Tab2fa />
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Settings;
