import React, { useEffect, useState, useCallback } from "react";
import { Container, Row } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import AllOrders from "./AllOrders";
import axios from "axios";
import { toast } from "react-toastify";

const Retiradas = ({ isLojista, isCliente, isAdmin }) => {
  const [loading, setLoading] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [shouldFetch, setShouldFetch] = useState(true);
  const [saldos, setSaldos] = useState([]);

  const user = JSON.parse(sessionStorage.getItem("authUser"));

  const getSaldos = useCallback(async () => {
    setLoading(true);

    const userData = JSON.parse(sessionStorage.getItem("authUser") || "{}");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/saldo/${userData.data._id}/ALL`,
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      );

      if (response && response.status === 1) {
        setSaldos(response.data);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro ao buscar saldos");
      console.log(error);
    }

    setLoading(false);
  }, []);

  const fetchBtcTransactions = useCallback(
    async (user_id) => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/retiradas-cripto/${user_id}/ALL`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        if (response.status && response.status === 1) {
          return response.data;
        } else {
          console.log(response);
          toast.error(response.mensagem);
          return [];
        }
      } catch (error) {
        toast.error("Erro ao buscar operações.");
        console.log(error);
        return [];
      } finally {
        setLoading(false);
      }
    },
    [user.token]
  );

  useEffect(() => {
    if (shouldFetch) {
      setLoading(true);
      const fetchData = async () => {
        const retorno = await fetchBtcTransactions(
          isLojista || isCliente ? user.data._id : 0
        );
        setOrderList(retorno);
        setShouldFetch(false);
        getSaldos();
      };

      fetchData();
      setLoading(false);
    }
  }, [
    shouldFetch,
    isLojista,
    isCliente,
    user.data._id,
    fetchBtcTransactions,
    getSaldos,
  ]);

  document.title = "Retiradas | Painel Fort Exchange";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Retiradas Cripto"
            pageTitle={
              isLojista ? "Lojista" : isCliente ? "Clientes Fort" : "Aplicações"
            }
          />
          <Row>
            <AllOrders
              isCliente={isCliente}
              isLojista={isLojista}
              user={user}
              orderList={orderList}
              loading={loading}
              setShouldFetch={setShouldFetch}
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Retiradas;
