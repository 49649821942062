import React, { useState, useCallback } from "react";
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
} from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";

const ModalNewOrder = ({ cliente_id, setmodalMigrar, modalMigrar, setShouldFetch }) => {
  const [loading, setLoading] = useState(false);

  const [buscaOpcional, setBuscaOpcional] = useState("");

  const migrarDados = useCallback(
    async (userId) => {
      setLoading(true);
      const user = JSON.parse(sessionStorage.getItem("authUser"));
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/migrate-client/${userId}`,
          {
            busca_opcional: buscaOpcional,
          },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        if (response.status && response.status === 1) {
          toast.success(response.mensagem);
          setmodalMigrar(false);
          setShouldFetch(true);
          console.log(response);
        } else {
          toast.error(response.mensagem);
          console.log(response);
        }
      } catch (error) {
        toast.error("Erro na API.");
        console.log(error);
      }
      setLoading(false);
    },
    [setShouldFetch, buscaOpcional, setmodalMigrar]
  );

  return (
    <Modal
      isOpen={modalMigrar}
      backdrop={"static"}
      toggle={() => {
        setmodalMigrar(false);
      }}
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          setmodalMigrar(false);
        }}
      >
        Migrar Dados
      </ModalHeader>
      <ModalBody>
        <form>
          <div className="row g-3">
            <Col md={8}>
              <Label className="form-Label">Campo de Busca Opcional</Label>
              <Input
                type="text"
                className="form-control"
                value={buscaOpcional}
                onChange={(e) => setBuscaOpcional(e.target.value)}
                maxLength={30}
              />
              <small className="text-muted">
                Use este campo para buscar por um documento em formato diferente
                do padrão.
              </small>
            </Col>
            <Col md={4}>
              <Button
                color="primary"
                className="btn-block mt-4"
                onClick={() => {
                  migrarDados(cliente_id);
                }}
                disabled={loading}
              >
                Migrar
              </Button>
            </Col>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};
export default ModalNewOrder;
