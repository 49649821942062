import React, { useEffect, useMemo, useState, useCallback } from "react";
import axios from "axios";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Spinner,
  Button,
} from "reactstrap";
import { toast } from "react-toastify";

//redux
import TableContainer from "../../../Components/Common/TableContainer";

const EnviosPendentes = ({ onTransactionSuccess }) => {
  const [transation, setTransation] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchTransations = async () => {
    setLoading(true);
    const user = JSON.parse(sessionStorage.getItem("authUser"));
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/cripto-td/compra-semanal`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (response.data && response.status && response.status === 1) {
        setLoading(false);
        return response.data;
      } else {
        toast.error(response.mensagem, {
          position: "top-right",
          hideProgressBar: true,
          closeOnClick: false,
          className: "bg-danger text-white",
        });

        setLoading(false);
        return [];
      }
    } catch (error) {
      toast.error("Erro ao buscar operações: " + error, {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: false,
        className: "bg-danger text-white",
      });
      setLoading(false);
      return [];
    }
  };

  const handleButtonClick = useCallback(
    async (moeda) => {
      setLoading(true);
      const user = JSON.parse(sessionStorage.getItem("authUser"));
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/cripto-td/prepara-semanal`,
          { moeda: moeda },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        if (response.status && response.status === 1) {
          toast.success(response.mensagem);
          const retorno = await fetchTransations();
          setTransation(retorno);
          onTransactionSuccess(moeda);
        } else if (response.mensagem) {
          toast.error(response.mensagem);
          console.log(response);
        } else {
          toast.error("Erro na operação!");
          console.log(response);
        }
      } catch (error) {
        toast.error("Erro na operação: " + error, {
          position: "top-right",
          hideProgressBar: true,
          closeOnClick: false,
          className: "bg-danger text-white",
        });
      }
      setLoading(false);
    },
    [onTransactionSuccess]
  );

  useEffect(() => {
    const fetchData = async () => {
      const retorno = await fetchTransations();
      setTransation(retorno);
    };

    fetchData();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Moeda",
        accessor: "moeda",
        Cell: (cell) => (
          <>
            <div className="d-flex align-items-center">
              <img
                src={`https://api.fort.exchange/crypto-icons/${cell.row.original.moeda.toLowerCase()}.svg`}
                alt=""
                className="avatar-xxs me-2"
              />
              {cell.value}
            </div>
          </>
        ),
      },
      {
        Header: "Quantidade",
        accessor: "valor_moeda",
        Cell: (cell) => (
          <>
            <h6
              className={"text-success amount mb-1"}
              style={{ cursor: "pointer" }}
            >
              {parseFloat(cell.value).toFixed(8)}{" "}
            </h6>
          </>
        ),
      },
      {
        Header: "Preparar Transação",
        Cell: (cell) => (
          <Button
            className="btn btn-warning"
            onClick={() => handleButtonClick(cell.row.original.moeda)}
          >
            Preparar
          </Button>
        ),
      },
    ],
    [handleButtonClick]
  );

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <Row className="align-items-center g-3">
            <Col md={3}>
              <h5 className="card-title mb-0">Envios não preparados</h5>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Spinner height={100} width={100} />
            </div>
          ) : (
            <TableContainer
              columns={columns}
              data={transation || []}
              isGlobalFilter={false}
              isAddUserList={false}
              customPageSize={10}
              className="custom-header-css"
              divClass="table-responsive table-card mb-4"
              tableClass="align-middle table-nowrap mb-0"
              theadClass="table-light table-nowrap"
              thClass="table-light text-muted"
            />
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default EnviosPendentes;
