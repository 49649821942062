import React, { useState, useCallback, useEffect } from "react";
import {
  Col,
  TabPane,
  Input,
  Label,
  Row,
  Spinner,
  Form,
  Button,
} from "reactstrap";
import InputMask from "react-input-mask";
import { NumericFormat } from "react-number-format";

import { toast } from "react-toastify";
import axios from "axios";

//import images

const TabPerfilCliente = ({ lojista }) => {
  const user = JSON.parse(sessionStorage.getItem("authUser")).data;

  const [loading, setLoading] = useState(false);
  const [editando, setEditando] = useState(false);
  const [email, setEmail] = useState("");
  const [razaoSocial, setRazaoSocial] = useState(lojista.razao_social);
  const [cnpj, setCnpj] = useState(lojista.cnpj);
  const [inicioEmpresa, setInicioEmpresa] = useState(lojista.inicio_empresa);
  const [nivelKyc, setNivelKyc] = useState(lojista.nivel_kyc);
  const [endereco, setEndereco] = useState(lojista.endereco);
  const [cep, setCep] = useState(lojista.cep);
  const [numero, setNumero] = useState(lojista.numero);
  const [complemento, setComplemento] = useState(lojista.complemento);
  const [cidade, setCidade] = useState(lojista.cidade);
  const [bairro, setBairro] = useState(lojista.bairro);
  const [estado, setEstado] = useState(lojista.estado);
  const [codigoParceiro, setCodigoParceiro] = useState(lojista.codigo_parceiro);
  const [indicacao, setIndicacao] = useState(lojista.indicacao);
  const [saldoBrl, setSaldoBrl] = useState(lojista.saldo_brl);
  const [negociarMensalmente, setNegociarMensalmente] = useState(
    lojista.negociar_mensalmente
  );

  const handleCepBlur = async (cep_) => {
    try {
      const _cep = cep_.replace(/[^0-9]/, "");
      const response = await axios.get(`https://viacep.com.br/ws/${_cep}/json`);

      if (response.logradouro) {
        setEndereco(response.logradouro);
        setCidade(response.localidade);
        setBairro(response.bairro);
        setEstado(response.uf);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateConta = async (event) => {
    event.preventDefault(); // Impedir a submissão padrão do formulário
    setEditando(true);
    const clientData = JSON.parse(sessionStorage.getItem("authUser") || "{}");
    try {
      const response = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_URL}/lojista/${user._id}`,
        headers: {
          Authorization: `Bearer ${clientData.token}`,
        },
        data: {
          razao_social: razaoSocial,
          email: email,
          inicio_empresa: inicioEmpresa,
          endereco: endereco,
          cep: cep,
          numero: numero,
          complemento: complemento,
          cidade: cidade,
          bairro: bairro,
          estado: estado,
          codigo_parceiro: codigoParceiro,
        },
      });

      if (response && response.status === 1) {
        toast.success(response.mensagem);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro na edição da conta: " + error);
      console.log(error);
    }
    setEditando(false);
  };

  useEffect(() => {
    setRazaoSocial(lojista.razao_social);
    setCnpj(lojista.cnpj);
    setEmail(lojista.user?.email);
    setInicioEmpresa(lojista.inicio_empresa);
    setNivelKyc(lojista.nivel_kyc);
    setEndereco(lojista.endereco);
    setCep(lojista.cep);
    setNumero(lojista.numero);
    setComplemento(lojista.complemento);
    setCidade(lojista.cidade);
    setBairro(lojista.bairro);
    setEstado(lojista.estado);
    setCodigoParceiro(lojista.codigo_parceiro);
    setIndicacao(lojista.indicacao);
    setSaldoBrl(lojista.saldo_brl);
    setNegociarMensalmente(lojista.negociar_mensalmente);
  }, [lojista]);

  const formatEmail = (event) => {
    let value = event.target.value;
    value = value.replace(/[^a-zA-Z0-9.\-_@]+/g, "");

    const atSymbolCount = value.split("@").length - 1;
    if (atSymbolCount > 1) {
      const index = value.lastIndexOf("@");
      value = value.slice(0, index) + value.slice(index + 1);
    }

    setEmail(value);
  };

  document.title = "Perfil do Lojista | Painel Fort Exchange";

  return (
    <TabPane tabId="1">
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            padding: "20px",
          }}
        >
          <Spinner height={100} width={100} />
        </div>
      ) : (
        <Form>
          <Row>
            <Col md={6}>
              <div>
                <label className="form-label m-2">CNPJ</label>
                <Input
                  type="text"
                  className="form-control"
                  value={cnpj}
                  onChange={() => {}}
                  disabled
                />
              </div>
            </Col>
            <Col md={6}>
              <div>
                <label className="form-label m-2">Início da Empresa</label>
                <Input
                  type="date"
                  className="form-control"
                  value={inicioEmpresa}
                  onChange={() => {}}
                  disabled
                />
              </div>
            </Col>
            <Col md={6}>
              <div>
                <label className="form-label m-2">Razão Social</label>
                <Input
                  type="text"
                  className="form-control"
                  value={razaoSocial}
                  onChange={(e) => setRazaoSocial(e.target.value)}
                />
              </div>
            </Col>
            <Col md={6}>
              <div>
                <label className="form-label m-2">E-mail</label>
                <Input
                  type="email"
                  className="form-control"
                  value={email}
                  onChange={(e) => formatEmail(e.target.value)}
                />
              </div>
            </Col>
            <Col md={6}>
              <div>
                <label className="form-label m-2">CEP</label>
                <InputMask
                  mask="99999-999"
                  placeholder="00000-000"
                  value={cep}
                  onBlur={() => {
                    handleCepBlur(cep);
                  }}
                  onChange={(e) => {
                    setCep(e.target.value);
                  }}
                >
                  {(inputProps) => (
                    <Input
                      type="text"
                      className="form-control"
                      {...inputProps}
                    />
                  )}
                </InputMask>
              </div>
            </Col>

            <Col md={6}>
              <div>
                <label className="form-label m-2">Cidade</label>
                <Input
                  maxLength={255}
                  type="text"
                  className="form-control"
                  value={cidade}
                  onChange={(e) => setCidade(e.target.value)}
                />
              </div>
            </Col>
            <Col md={6}>
              <div>
                <label className="form-label m-2">Endereço</label>
                <Input
                  maxLength={255}
                  type="text"
                  className="form-control"
                  value={endereco}
                  onChange={(e) => setEndereco(e.target.value)}
                />
              </div>
            </Col>
            <Col md={6}>
              <div>
                <label className="form-label m-2">Número</label>
                <Input
                  type="number"
                  className="form-control"
                  value={numero}
                  onChange={(e) => setNumero(e.target.value)}
                />
              </div>
            </Col>
            <Col md={6}>
              <div>
                <label className="form-label m-2">Complemento</label>
                <Input
                  maxLength={255}
                  type="text"
                  className="form-control"
                  value={complemento}
                  onChange={(e) => setComplemento(e.target.value)}
                />
              </div>
            </Col>
            <Col md={6}>
              <div>
                <label className="form-label m-2">Bairro</label>
                <Input
                  maxLength={255}
                  type="text"
                  className="form-control"
                  value={bairro}
                  onChange={(e) => setBairro(e.target.value)}
                />
              </div>
            </Col>
            <Col md={6}>
              <div>
                <label className="form-label m-2">Estado</label>
                <Input
                  type="select"
                  className="form-control"
                  value={estado}
                  onChange={(e) => setEstado(e.target.value)}
                >
                  <option value="AC">Acre</option>
                  <option value="AL">Alagoas</option>
                  <option value="AP">Amapá</option>
                  <option value="AM">Amazonas</option>
                  <option value="BA">Bahia</option>
                  <option value="CE">Ceará</option>
                  <option value="DF">Distrito Federal</option>
                  <option value="ES">Espírito Santo</option>
                  <option value="GO">Goiás</option>
                  <option value="MA">Maranhão</option>
                  <option value="MT">Mato Grosso</option>
                  <option value="MS">Mato Grosso do Sul</option>
                  <option value="MG">Minas Gerais</option>
                  <option value="PA">Pará</option>
                  <option value="PB">Paraíba</option>
                  <option value="PR">Paraná</option>
                  <option value="PE">Pernambuco</option>
                  <option value="PI">Piauí</option>
                  <option value="RJ">Rio de Janeiro</option>
                  <option value="RN">Rio Grande do Norte</option>
                  <option value="RS">Rio Grande do Sul</option>
                  <option value="RO">Rondônia</option>
                  <option value="RR">Roraima</option>
                  <option value="SC">Santa Catarina</option>
                  <option value="SP">São Paulo</option>
                  <option value="SE">Sergipe</option>
                  <option value="TO">Tocantins</option>
                </Input>
              </div>
            </Col>
            <Col md={6}>
              <div>
                <label className="form-label m-2">Código Parceiro</label>
                <Input
                  maxLength={255}
                  type="text"
                  className="form-control"
                  value={codigoParceiro}
                  onChange={(e) => setCodigoParceiro(e.target.value)}
                />
              </div>
            </Col>
            <Col md={6}>
              <div>
                <label className="form-label m-2">Indicação</label>
                <Input
                  maxLength={255}
                  type="text"
                  className="form-control"
                  value={indicacao}
                  onChange={() => {}}
                  disabled
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label className="form-label m-2">Nível KYC</Label>
                <select
                  className="form-control"
                  value={nivelKyc ? nivelKyc : "0"}
                  onChange={() => {}}
                  disabled
                >
                  <option value="0">Não realizado</option>
                  <option value="1">Básico</option>
                  <option value="2">Avançado</option>
                  <option value="3">Personalizado</option>
                </select>
              </div>
            </Col>

            <Col md={6}>
              <div>
                <label htmlFor="compraDiaria" className="form-label m-2">
                  Saldo BRL (R$)
                </label>
                <NumericFormat
                  className="form-control"
                  placeholder="R$ 0,00"
                  value={saldoBrl}
                  onValueChange={() => {}}
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={0}
                  suffix=",00"
                  fixedDecimalScale={true}
                  prefix="R$ "
                  allowNegative={false}
                  disabled
                />
              </div>
            </Col>

            <Col md={6}>
              <div>
                <label htmlFor="compraDiaria" className="form-label m-2">
                  Negociar Mensalmente (R$)
                </label>
                <NumericFormat
                  className="form-control"
                  placeholder="R$ 0,00"
                  value={negociarMensalmente}
                  onValueChange={() => {}}
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={0}
                  suffix=",00"
                  fixedDecimalScale={true}
                  prefix="R$ "
                  allowNegative={false}
                  disabled
                />
              </div>
            </Col>
            <Col md={12}>
              <div className="mt-4">
                <Button
                  type="submit"
                  color="primary"
                  className="btn btn-primary me-1"
                  onClick={updateConta}
                  disabled={editando}
                >
                  {editando ? "Editando..." : "Editar"}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </TabPane>
  );
};

export default TabPerfilCliente;
