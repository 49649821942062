export const mumbai = {
  chainId: `0x${(80001).toString(16)}`,
  chainName: "Mumbai",
  nativeCurrency: {
    name: "MATIC",
    symbol: "MATIC",
    decimals: 18,
  },
  rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
  blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
};

export const arbitrum = {
  chainId: `0x${(42161).toString(16)}`,
  chainName: "Arbitrum",
  nativeCurrency: {
    name: "ETH",
    symbol: "ETH",
    decimals: 18,
  },
  rpcUrls: ["https://arb1.arbitrum.io/rpc"],
  blockExplorerUrls: ["https://arbiscan.io/"],
};

export const polygon = {
  chainId: `0x${(137).toString(16)}`,
  chainName: "Polygon",
  nativeCurrency: {
    name: "MATIC",
    symbol: "MATIC",
    decimals: 18,
  },
  rpcUrls: ["https://rpc.ankr.com/polygon"],
  blockExplorerUrls: ["https://polygonscan.com/"],
};


export const bsc = {
  chainId: `0x${(56).toString(16)}`,
  chainName: "BSC",
  nativeCurrency: {
    name: "BSC",
    symbol: "BNB",
    decimals: 18,
  },
  rpcUrls: ["https://bsc-dataseed1.bnbchain.org"],
  blockExplorerUrls: ["https://bscscan.com/"],
};
