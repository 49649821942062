import React, { useState } from "react";
import { Row, TabPane, Spinner } from "reactstrap";
import RedesMoedas from "../../../Crypto/RedesMoedas";

const TabRedesMoeda = () => {
  const [loading, setLoading] = useState(false);

  return (
    <TabPane tabId="3">
      <Row>
        {loading ? (
          <Spinner />
        ) : (
          <Row>
            <RedesMoedas loading={loading} setLoading={setLoading} />
          </Row>
        )}
      </Row>
    </TabPane>
  );
};

export default TabRedesMoeda;
