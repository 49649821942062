import React, { useState, useCallback, useEffect } from "react";
import { Col, Input, Label, Row, TabPane, Spinner, Button } from "reactstrap";
import { toast } from "react-toastify";
import axios from "axios";

const TabRelatorio = () => {
  const [loading, setLoading] = useState(false);

  const [dataInicio, setDataInicio] = useState("");
  const [dataFim, setDataFim] = useState("");

  const handleExportCSV = async () => {
    const token = JSON.parse(sessionStorage.getItem("authUser"))?.token;
    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_API_URL}/cripto-td/export-csv`;
      const params = {};

      if (dataInicio) params.data_inicio = dataInicio;
      if (dataFim) params.data_fim = dataFim;

      const response = await axios.get(url, {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status && response.status === 1) {
        // Obter o conteúdo CSV como string do campo 'dados'
        const csvContent = response.dados;

        // Criar um objeto Blob e uma URL para ele
        const blob = new Blob([csvContent], { type: "text/csv" });
        const blobURL = window.URL.createObjectURL(blob);

        // Criar e configurar um elemento de link para iniciar o download
        const fileName = `compras-diarias_${dataInicio}_${dataFim}.csv`;
        const link = document.createElement("a");
        link.href = blobURL;
        link.download = fileName;
        link.click();

        // Limpar a URL do blob
        window.URL.revokeObjectURL(blobURL);
        setDataFim("");
        setDataInicio("");
      } else {
        toast.error(response.mensagem);
        console.error(response);
      }
    } catch (error) {
      console.error("Erro ao exportar CSV:", error);
    }
    setLoading(false);
  };

  return (
    <TabPane tabId="2">
      {loading ? (
        <Spinner />
      ) : (
        <Row>
          <div className="mb-3">
            <h5>Compras Diárias</h5>
          </div>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label">Data Início</Label>
              <Input
                type="date"
                value={dataInicio}
                onChange={(e) => setDataInicio(e.target.value)}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label">Data Fim</Label>
              <Input
                type="date"
                value={dataFim}
                onChange={(e) => setDataFim(e.target.value)}
              />
            </div>
          </Col>
          <Col lg={12}>
            <div className="mb-3">
              <Button
                color="primary"
                className="btn mb-4"
                disabled={loading}
                onClick={handleExportCSV}
              >
                Exportar CSV
              </Button>
            </div>
          </Col>
        </Row>
      )}
    </TabPane>
  );
};

export default TabRelatorio;
