import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Input,
  Row,
  Label,
  Col,
} from "reactstrap";
import { toast } from "react-toastify";
import axios from "axios";

const ModalNovaRede = ({ show, fetchRedes, setShowModal, setRedes }) => {
  const [loading, setLoading] = useState(false);
  const [nome, setNome] = useState("");
  const [moeda, setMoeda] = useState("");
  const [networkId, setNetworkId] = useState(0);
  const [decimals, setDecimals] = useState(0);
  const [rpc, setRpc] = useState("");
  const [blockExplorer, setBlockExplorer] = useState("");
  const [taxaRetirada, setTaxaRetirada] = useState(0);

  const toggle = () => setShowModal(!show);

  const cadastrarRede = async () => {
    setLoading(true);
    const user = JSON.parse(sessionStorage.getItem("authUser"));
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/rede`,
        {
          nome,
          moeda,
          decimals,
          rpc,
          network_id: networkId,
          block_explorer: blockExplorer,
          taxa_retirada: taxaRetirada,
        },

        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (response.status && response.status === 1) {
        toast.success("Rede cadastrada com sucesso!");
        setRedes(await fetchRedes());
        setShowModal(false);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro ao cadastrar rede!");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        isOpen={show}
        toggle={toggle}
        backdrop={"static"}
        id="staticBackdrop"
        centered
        className="modal-lg"
      >
        <ModalHeader
          className="modal-title"
          id="staticBackdropLabel"
          toggle={toggle}
        >
          Dados da Rede
        </ModalHeader>
        <ModalBody className="text-left p-5">
          <Row className="mb-3">
            <Col md="6" className="mb-3">
              <Label>Nome da rede:</Label>
              <Input
                type="text"
                placeholder="Nome da rede"
                value={nome}
                onChange={(e) => {
                  setNome(e.target.value);
                }}
              />
            </Col>
            <Col md="6" className="mb-3">
              <Label>Moeda:</Label>
              <Input
                type="text"
                placeholder="Moeda"
                value={moeda}
                onChange={(e) => {
                  setMoeda(e.target.value.toUpperCase());
                }}
                maxLength={10}
              />
              <small className="text-muted">Somente as iniciais da moeda</small>
            </Col>

            <Col md="6" className="mb-3">
              <Label>Decimais:</Label>
              <Input
                type="number"
                placeholder="Decimais"
                value={decimals}
                onChange={(e) => {
                  setDecimals(e.target.value);
                }}
                maxLength={2}
              />
            </Col>
            <Col md="6" className="mb-3">
              <Label>RPC:</Label>
              <Input
                type="text"
                placeholder="RPC"
                value={rpc}
                onChange={(e) => {
                  setRpc(e.target.value);
                }}
                maxLength={255}
              />
            </Col>
            <Col md="6" className="mb-3">
              <Label>Network ID</Label>
              <Input
                type="number"
                placeholder="Network ID"
                value={networkId}
                onChange={(e) => {
                  setNetworkId(e.target.value);
                }}
                maxLength={7}
              />
            </Col>
            <Col md="6" className="mb-3">
              <Label>Block Explorer:</Label>
              <Input
                type="text"
                placeholder="Block Explorer"
                value={blockExplorer}
                onChange={(e) => {
                  setBlockExplorer(e.target.value);
                }}
                maxLength={255}
              />
            </Col>
            <Col md="6" className="mb-3">
              <Label>Taxa de retirada:</Label>
              <Input
                type="number"
                placeholder="Taxa de retirada"
                value={taxaRetirada}
                onChange={(e) => {
                  setTaxaRetirada(e.target.value);
                }}
                maxLength={30}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row className="mb-3">
            <div className="hstack gap-3 justify-content-right mt-3">
              <Button
                color="primary"
                onClick={() => cadastrarRede()}
                disabled={loading}
              >
                Cadastrar
              </Button>
              <Button color="light" onClick={() => setShowModal(false)}>
                Fechar
              </Button>
            </div>
          </Row>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default ModalNovaRede;
