import React, { useMemo, useCallback } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Spinner, Button } from "reactstrap";

import TableContainer from "../../../Components/Common/TableContainer";
import { toast } from "react-toastify";
import axios from "axios";

const AllOrders = ({ orderList, loading, setShouldFetch }) => {

  

  const formatCurrency = (value) => {
    if (!value) {
      return "R$ 0,00";
    }
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };
  const columns = useMemo(
    () => [
      {
        Header: "Lojista",
        accessor: "razao_social",
        filterable: true,
        Cell: (cell) => (
          <>
            <div className="d-flex align-items-center">
              <Link to="#" className="currency_name flex-grow-1 ms-2 text-body">
                <b style={{ color: "darkBlue" }}>
                  {cell.value}
                </b>
              </Link>
            </div>
          </>
        ),
      },
      {
        Header: "E-mail",
        accessor: "user.email",
        filterable: true,
        Cell: (cell) => {
          return <span style={{ color: "darkGreen" }}>{cell.value}</span>;
        },
      },
      {
        Header: "CNPJ",
        accessor: "cnpj",
        filterable: true,
        Cell: (cell) => {
          return cell.value;
        },
      },
      {
        Header: "Saldo Total",
        accessor: "saldo_brl",
        filterable: false,
        Cell: (order) => {
          return (
            <>
              {formatCurrency(
                parseFloat(order.row.original.saldo_brl ?? 0) +
                  parseFloat(order.row.original.saldo_subcontas ?? 0)
              )}
            </>
          );
        },
      },
      {
        Header: "Limite Mensal",
        accessor: "negociar_mensalmente",
        filterable: false,
        Cell: (cell) => {
          return <>{formatCurrency(cell.value)}</>;
        },
      },
      {
        Header: "Ações",
        accessor: "user_id",
        filterable: false,
        Cell: (order) => {
          return (
            <>
              <Link to={`/lojistas/${order.row.original.user_id}`}>
                <Button color="primary" className="btn btn-sm ms-1">
                  Ver Dados
                </Button>
              </Link>
            </>
          );
        },
      },
    ],
    []
  );
  return (
    <React.Fragment>
      <Col lg={12}>
        <Card>
          <CardHeader className="d-flex align-items-center border-0">
            <h5 className="card-title mb-0 flex-grow-1">Todos os Lojistas</h5>
          </CardHeader>
          <CardBody>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Spinner height={100} width={100} />
              </div>
            ) : (
              <TableContainer
                columns={columns}
                data={orderList || []}
                isGlobalFilter={true}
                isAddUserList={false}
                customPageSize={20}
                className="custom-header-css"
                divClass="table-responsive table-card mb-4"
                tableClass="align-middle table-nowrap mb-0"
                theadClass="table-light table-nowrap"
                thClass="table-light text-muted"
              />
            )}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default AllOrders;
