import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
} from "reactstrap";
import ParticlesAuth from "../../AuthenticationInner/ParticlesAuth";
import axios from "axios";
import { toast } from "react-toastify";
import ChatScript from "../../../Layouts/ChatScript";
import InputMask from "react-input-mask";

import { Link } from "react-router-dom";
import logoLight from "../../../assets/images/logo-light2.png";

const CreatePassw = () => {
  const [email, setEmail] = useState("");
  const [nome, setNome] = useState("");
  const [tipoCliente, setTipoCliente] = useState("cpf");
  const [cpf, setCpf] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [codigoParceiro, setCodigoParceiro] = useState("");

  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [password2Invalid, setPassword2Invalid] = useState(false);

  const errorsEmail = "E-mail inválido ou vazio.";
  const errorsPassword =
    "Senha deve ter no mínimo 8 caracteres, 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial.";
  const errorsPassword2 = "As senhas devem ser iguais.";

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const postData = {
        email,
        codigo_parceiro: codigoParceiro,
        cpf_cnpj: cpf,
        password: password,
        password_confirmation: password2,
        nome_completo: nome,
      };
      console.log(postData);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/client/cadastro`,
        postData
      );

      if (response.status && response.status === 1) {
        toast.success(response.mensagem);
        window.location.href = "/login";
      } else if (response.mensagem) {
        toast.error(response.mensagem);
        console.log(response);
      } else {
        console.log(response);
        toast.error("Erro ao enviar o e-mail!");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast.error("Ocorreu um erro desconhecido.");
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const codigo = urlParams.get("codigo_parceiro");
    if (codigo) {
      setCodigoParceiro(codigo);
    } else {
      setCodigoParceiro("fort");
    }
  }, []);

  const formatNome = (event) => {
    let value = event.target.value.toUpperCase();
    value = value.replace(/[^A-Za-z\s]/g, "");
    setNome(value);
  };

  const formatEmail = (event) => {
    let value = event.target.value;
    value = value.replace(/[^a-zA-Z0-9.\-_@]+/g, "");

    const atSymbolCount = value.split("@").length - 1;
    if (atSymbolCount > 1) {
      const index = value.lastIndexOf("@");
      value = value.slice(0, index) + value.slice(index + 1);
    }
    if (!validateEmail(value)) {
      setEmailInvalid(true);
    } else {
      setEmailInvalid(false);
    }

    setEmail(value);
  };

  const validatePassword = (pass) => {
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d\s])[\S]{8,}$/;
    return re.test(String(pass));
  };

  const handlePasswordChange = (event) => {
    const val = event.target.value;
    setPassword(val);
    if (!validatePassword(val)) {
      setPasswordInvalid(true);
    } else {
      setPasswordInvalid(false);
    }
  };

  const handlePassword2Change = (event) => {
    const val = event.target.value;
    setPassword2(val);
    if (password !== password2) {
      setPassword2Invalid(true);
    } else {
      setPassword2Invalid(false);
    }
  };

  document.title = "Criação de conta | Painel Fort Exchange";
  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoLight} alt="" height="70" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={8} xl={8}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Nova Conta</h5>
                      <p className="text-muted">
                        Preencha os campos abaixo para criar uma nova conta.
                      </p>
                    </div>
                    <div className="p-2 mt-4">
                      <Form onSubmit={handleSubmit} action="#">
                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Nome Completo
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                value={nome}
                                onChange={formatNome}
                                maxLength={250}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">E-mail</Label>
                              <Input
                                type="email"
                                className="form-control"
                                value={email}
                                onChange={(event) => formatEmail(event)}
                                maxLength={150}
                              />
                              {emailInvalid && (
                                <FormFeedback type="invalid">
                                  {errorsEmail}
                                </FormFeedback>
                              )}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="radioOptions"
                                className="form-label"
                              >
                                Tipo
                              </Label>
                              <Row id="radioOptions">
                                <Col lg={6}>
                                  <Input
                                    className="form-check-input"
                                    type="radio"
                                    name="radioTipo"
                                    id="radioCPF"
                                    defaultValue="cpf"
                                    checked={tipoCliente === "cpf"}
                                    onChange={(e) =>
                                      setTipoCliente(e.target.value)
                                    }
                                  />
                                  <Label
                                    className="form-check-label"
                                    htmlFor="radioCPF"
                                    style={{ marginLeft: "10px" }}
                                  >
                                    Pessoa Física
                                  </Label>
                                </Col>
                                <Col lg={6}>
                                  <Input
                                    className="form-check-input"
                                    type="radio"
                                    name="radioTipo"
                                    id="radioCNPJ"
                                    defaultValue="cnpj"
                                    checked={tipoCliente === "cnpj"}
                                    onChange={(e) =>
                                      setTipoCliente(e.target.value)
                                    }
                                  />
                                  <Label
                                    className="form-check-label"
                                    htmlFor="radioCNPJ"
                                    style={{ marginLeft: "10px" }}
                                  >
                                    Pessoa Jurídica
                                  </Label>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          {tipoCliente === "cpf" ? (
                            <>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label className="form-label">CPF</Label>
                                  <InputMask
                                    mask="999.999.999-99"
                                    value={cpf ? cpf : ""}
                                    placeholder="000.000.000-00"
                                    onChange={(e) => setCpf(e.target.value)}
                                  >
                                    {(inputProps) => (
                                      <Input
                                        type="text"
                                        className="form-control"
                                        {...inputProps}
                                      />
                                    )}
                                  </InputMask>
                                </div>
                              </Col>
                            </>
                          ) : (
                            <>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label className="form-label">CNPJ</Label>
                                  <InputMask
                                    mask="99.999.999/9999-99"
                                    placeholder="00.000.000/0000-00"
                                    value={cpf ? cpf : ""}
                                    onChange={(e) => setCpf(e.target.value)}
                                  >
                                    {(inputProps) => (
                                      <Input
                                        type="text"
                                        className="form-control"
                                        {...inputProps}
                                      />
                                    )}
                                  </InputMask>
                                </div>
                              </Col>
                            </>
                          )}
                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="newpasswordInput"
                                className="form-label"
                              >
                                Senha*
                              </Label>
                              <Input
                                name="password"
                                className="form-control"
                                type="password"
                                onChange={handlePasswordChange}
                                onBlur={handlePasswordChange}
                                value={password}
                                invalid={passwordInvalid}
                              />
                              {passwordInvalid && (
                                <FormFeedback type="invalid">
                                  {errorsPassword}
                                </FormFeedback>
                              )}
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="confirmpasswordInput"
                                className="form-label"
                              >
                                Confirmação de Senha*
                              </Label>
                              <Input
                                name="password_confirmation"
                                className="form-control"
                                type="password"
                                onChange={handlePassword2Change}
                                onBlur={handlePassword2Change}
                                value={password2}
                                invalid={password2Invalid}
                              />
                              {password2Invalid && (
                                <FormFeedback type="invalid">
                                  {errorsPassword2}
                                </FormFeedback>
                              )}
                            </div>
                          </Col>
                          <div className="mt-4">
                            <Button
                              color="success"
                              disabled={loading}
                              className="btn btn-success w-100"
                              type="submit"
                            >
                              {loading ? "Enviando..." : "Enviar"}
                            </Button>
                          </div>
                        </Row>
                      </Form>
                    </div>
                  </CardBody>
                </Card>

                <div className="mt-4 text-center">
                  <p className="mb-0">
                    Voltar para a{" "}
                    <Link
                      to="/auth-signin-basic"
                      className="fw-bold text-primary text-decoration-underline"
                    >
                      {" "}
                      página de Login{" "}
                    </Link>{" "}
                  </p>
                </div>
              </Col>
            </Row>
            <ChatScript />
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default CreatePassw;
