import React from "react";
import { Navigate } from "react-router-dom";

//Transactions
import Transactions from "../pages/Crypto/CompraDiaria";
import TransactionsWeek from "../pages/Crypto/TransactionsWeek";
import Transacoes from "../pages/Crypto/Transacoes";
import TransacoesCompras from "../pages/Crypto/TransacoesCompras";
import PixClientes from "../pages/Crypto/PixClientes";
import OrderLojistas from "../pages/Crypto/OrderLojistas";
import Comissoes from "../pages/Crypto/Comissoes";
import MyWallet from "../pages/Crypto/MyWallet";

// Clientes
import DashboardCrypto from "../pages/DashboardCrypto";
import Clientes from "../pages/Crypto/Clientes";
import ClientesCadastro from "../pages/Crypto/ClientesCadastro";
import Operacoes from "../pages/Crypto/Operacoes";
import TabelaOperacoes from "../pages/Crypto/Operacoes/TabelaOperacoes";
import TabelaClientes from "../pages/Crypto/Clientes/TabelaClientes";
import Lojistas from "../pages/Crypto/Lojistas";
import TabelaSocios from "../pages/Crypto/Lojistas/TabelaSocios";
import TabelaLojistas from "../pages/Crypto/Lojistas/TabelaLojistas";
import Retiradas from "../pages/Crypto/Retiradas";
import Logs from "../pages/Crypto/Logs";

//AuthenticationInner pages
import BasicPasswReset from "../pages/AuthenticationInner/PasswordReset/BasicPasswReset";
import CreatePassw from "../pages/AuthenticationInner/PasswordReset/CreatePassw";
import CreatePasswLojista from "../pages/AuthenticationInner/PasswordReset/CreatePasswLojista";
import ResetPassw from "../pages/AuthenticationInner/PasswordReset/ResetPassw";
//pages
import Basic404 from "../pages/AuthenticationInner/Errors/Basic404";

//login
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

// User Profile
import Settings from "../pages/Pages/Profile/Settings/Settings";

//System Settings
import SystemSettings from "../pages/Pages/Profile/ConfigPanel/SystemSettings";

const userData = JSON.parse(sessionStorage.getItem("authUser"));

const authProtectedRoutes = [
  //transactions
  {
    path: "/compra-diaria",
    component:
      userData?.data?.role !== "Admin" ? <Basic404 /> : <Transactions />,
  },
  {
    path: "/compra-diaria-transacoes",
    component:
      userData?.data?.role !== "Admin" ? <Basic404 /> : <TransacoesCompras />,
  },
  {
    path: "/compra-semanal",
    component:
      userData?.data?.role !== "Admin" ? (
        <Basic404 />
      ) : (
        <TransactionsWeek isCliente={false} />
      ),
  },
  {
    path: "/pix",
    component:
      userData?.data?.role !== "Admin" ? (
        <Basic404 />
      ) : (
        <PixClientes isCliente={false} />
      ),
  },
  {
    path: "/comissoes",
    component:
      userData?.data?.role !== "Admin" ? (
        <Basic404 />
      ) : (
        <Comissoes isCliente={false} />
      ),
  },
  {
    path: "/configuracoes",
    component:
      userData?.data?.role !== "Admin" ? <Basic404 /> : <SystemSettings />,
  },
  {
    path: "/apps-crypto-wallet",
    component: userData?.data?.role !== "Admin" ? <Basic404 /> : <MyWallet />,
  },
  {
    path: "/clientes/:user_id",
    component: userData?.data?.role !== "Admin" ? <Basic404 /> : <Clientes />,
  },
  {
    path: "/clientes-cadastro",
    component: userData?.data?.role !== "Admin" ? <Basic404 /> : <ClientesCadastro />,
  },
  {
    path: "/nova-operacao",
    component: userData?.data?.role !== "Admin" ? <Basic404 /> : <Operacoes />,
  },
  
  {
    path: "/operacoes",
    component: userData?.data?.role !== "Admin" ? <Basic404 /> : <TabelaOperacoes />,
  },
  {
    path: "/system-logs/",
    component: userData?.data?.role !== "Admin" ? <Basic404 /> : <Logs />,
  },
  {
    path: "/clientes",
    component:
      userData?.data?.role !== "Admin" ? <Basic404 /> : <TabelaClientes />,
  },
  
  {
    path: "/retiradas-cripto",
    component:
      userData?.data?.role !== "Admin" ? (
        <Basic404 />
      ) : (
        <Retiradas isAdmin={true} />
      ),
  },

  //Lojistas
  {
    path: "/lojistas/:user_id",
    component: userData?.data?.role !== "Admin" ? <Basic404 /> : <Lojistas />,
  },
  {
    path: "/lojistas",
    component:
      userData?.data?.role !== "Admin" ? <Basic404 /> : <TabelaLojistas />,
  },
  {
    path: "/lojista-socios",
    component:
      userData?.data?.role !== "Lojista" ? <Basic404 /> : <TabelaSocios />,
  },
  {
    path: "/perfil-lojista",
    component:
      userData?.data?.role !== "Lojista" ? (
        <Basic404 />
      ) : (
        <Lojistas />
      ),
  },

  {
    path: "/order-lojista",
    component:
      userData?.data?.role !== "Lojista" ? (
        <Basic404 />
      ) : (
        <OrderLojistas isLojista={true} />
      ),
  },
  {
    path: "/retiradas-lojista",
    component:
      userData?.data?.role === "Lojista" ? (
        <Basic404 />
      ) : (
        <Retiradas isLojista={true} />
      ),
  },
  //Clientes

  { path: "/dashboard-crypto", component: <DashboardCrypto /> },
  { path: "/dashboard-transacoes", component: <Transacoes /> },
  { path: "/dashboard-pix", component: <PixClientes isCliente={true} /> },
  { path: "/dashboard-comissoes", component: <Comissoes isCliente={true} /> },
  {
    path: "/dashboard-compras-semanais",
    component: <TransactionsWeek isCliente={true} />,
  },
  { path: "/profile", component: <Settings /> },
  {
    path: "/logs/:userId",
    component: <Logs />,
  },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component:
      userData?.data?.role !== "Admin" ? (
        <Navigate to="/dashboard-crypto" />
      ) : (
        <Navigate to="/compra-diaria" />
      ),
  },
  {
    path: "*",
    component:
      userData?.data?.role !== "Admin" ? (
        <Navigate to="/dashboard-crypto" />
      ) : (
        <Navigate to="/compra-diaria" />
      ),
  },
  {
    path: "/retiradas-clientes",
    component:
      userData?.data?.role !== "Cliente" ? (
        <Basic404 />
      ) : (
        <Retiradas isCliente={true} />
      ),
  },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },

  //AuthenticationInner pages
  { path: "/auth-pass-reset-basic", component: <BasicPasswReset /> },
  { path: "/nova-conta", component: <CreatePassw /> },
  { path: "/nova-conta-lojista", component: <CreatePasswLojista /> },
  { path: "/nova-senha", component: <ResetPassw /> },
  { path: "/auth-404-basic", component: <Basic404 /> },
];

export { authProtectedRoutes, publicRoutes };
