import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
} from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";

const ModalSocio = ({ lojista_id, setmodalStore, show, setShouldFetch }) => {
  const [loading, setLoading] = useState(false);
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [cpf, setCpf] = useState("");

  const createSocio = async (event) => {
    event.preventDefault();
    setLoading(true);
    const clientData = JSON.parse(sessionStorage.getItem("authUser") || "{}");
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/lojista/socios/${lojista_id}`,
        headers: {
          Authorization: `Bearer ${clientData.token}`,
        },
        data: {
          cpf: cpf,
          email: email,
          nome: nome,
        },
      });

      if (response && response.status === 1) {
        toast.success(response.mensagem);
        setmodalStore(!show);
        setShouldFetch(true);
        setNome("");
        setEmail("");
        setCpf("");
      } else {
        toast.error(response.mensagem);
        if (response.mensagem === "Sócio já cadastrado") {
          setmodalStore(!show);
          setShouldFetch(true);
          setNome("");
          setEmail("");
          setCpf("");
        } else {
          console.log(response);
        }
      }
    } catch (error) {
      toast.error("Erro na edição da conta: " + error);
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <Modal
      isOpen={show}
      toggle={() => {
        setmodalStore(!show);
      }}
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          setmodalStore(!show);
        }}
      >
        Cadastrar Sócio
      </ModalHeader>
      <ModalBody>
        <form>
          <div className="row g-3">
            <Col md={12}>
              <div>
                <label className="form-label">Nome Completo</label>
                <Input
                  type="text"
                  className="form-control"
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                />
              </div>
            </Col>
            <Col md={6}>
              <div>
                <label className="form-label">E-mail</label>
                <Input
                  type="email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </Col>
            <Col md={6}>
              <div>
                <label className="form-label">CPF</label>
                <InputMask
                  mask="999.999.999-99"
                  placeholder="000.000.000-00"
                  defaultValue={cpf}
                  onChange={(e) => {
                    setCpf(e.target.value);
                  }}
                >
                  {(inputProps) => (
                    <Input
                      type="text"
                      className="form-control"
                      {...inputProps}
                    />
                  )}
                </InputMask>
              </div>
            </Col>
            <Col lg={12}>
              <div className="hstack gap-2 justify-content-end">
                <Button color="light" onClick={() => setmodalStore(false)}>
                  Fechar
                </Button>
                <Button
                  color="primary"
                  type="button"
                  disabled={loading}
                  onClick={createSocio}
                >
                  Cadastrar
                </Button>
              </div>
            </Col>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};
export default ModalSocio;
