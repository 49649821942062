import React, { useState, useCallback } from "react";
import { Container, Row } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import EnviosPendentes from "./EnviosPendentes";
import EnviosSemanais from "./EnviosSemanais";

const Transactions = ({ isCliente }) => {
  const [updateKey, setUpdateKey] = useState(0);
  document.title = "Envio Semanal | Painel Fort Exchange";

  const handleButtonClick = useCallback(async (moeda) => {
    setUpdateKey((prevKey) => prevKey + 1);
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Envio Semanal"
            pageTitle={isCliente ? "Clientes Fort" : "Aplicações"}
          />
          {!isCliente ? (
            <EnviosPendentes onTransactionSuccess={handleButtonClick} />
          ) : null}
          <EnviosSemanais key={updateKey} isCliente={isCliente} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Transactions;
