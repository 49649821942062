import React, { useState, useCallback, useEffect } from "react";
import { Col, Label, Row, TabPane, Form, Spinner, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

const TabSaldos = ({ user_id }) => {
  const [loading, setLoading] = useState(false);
  const [sociosList, setSociosList] = useState([]);
  const [shouldFetch, setShouldFetch] = useState(true);

  function formatName(name) {
    let lowerCaseName = name.toLowerCase();
    let properName = lowerCaseName.replace(
      /\b\w/g,
      function (letter, index, fullText) {
        const previousLetter = fullText[index - 1];
        const isWordAfterApostrophe = previousLetter === "'";
        if (isWordAfterApostrophe) {
          return letter;
        }
        const word = fullText.slice(index).match(/^\w+/)[0];
        const lowerCaseWords = ["de", "da", "do", "dos", "das"];
        if (lowerCaseWords.includes(word)) {
          return letter;
        }
        return letter.toUpperCase();
      }
    );

    // Converte a letra após um apóstrofo para maiúscula
    properName = properName.replace(/'\w/g, function (letter) {
      return letter.toUpperCase();
    });

    return properName;
  }

  const fecthSocios = useCallback(async () => {
    setLoading(true);
    const user = JSON.parse(sessionStorage.getItem("authUser"));
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/lojista/socios/${user_id}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (response.status && response.status === 1) {
        return response.data;
      } else {
        toast.error(response.mensagem);
        console.log(response);
        return [];
      }
    } catch (error) {
      toast.error("Erro ao buscar usuários.");
      console.log(error);
      return [];
    } finally {
      setLoading(false);
    }
  }, [user_id]);

  useEffect(() => {
    if (shouldFetch) {
      const fetchData = async () => {
        const retorno = await fecthSocios();
        setSociosList(retorno);
        setShouldFetch(false);
      };

      fetchData();
    }
  }, [shouldFetch, fecthSocios]);

  const formatDate = (dateString) => {
    if (!dateString) {
      return "";
    }

    const [datePart] = dateString.split("T");
    const [year, month, day] = datePart.split("-");

    return `${day}/${month}/${year}`;
  };

  return (
    <TabPane tabId="3">
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            padding: "20px",
          }}
        >
          <Spinner height={100} width={100} />
        </div>
      ) : (
        <Row>
          <Col lg={12}>
            <div className="mb-3">
              <div className="table-responsive">
                <table className="table table-nowrap table-hover mb-0">
                  <thead className="table-light">
                    <tr>
                      <th className="border-0">Nome</th>
                      <th className="border-0">CPF</th>
                      <th className="border-0">KYC</th>
                      <th className="border-0">Ver</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sociosList.map((socio) => (
                      <tr key={socio.id}>
                        <td>{formatName(socio.nome_completo)}</td>
                        <td style={{ color: "darkgreen" }}>{socio.cpf_cnpj}</td>
                        <td>
                          {socio.nivel_kyc === 0 ? (
                            <span className="badge bg-danger">
                              Não Aprovado
                            </span>
                          ) : (
                            <span className="badge bg-success">Aprovado</span>
                          )}
                        </td>
                        <td>
                          <Link to={`/clientes/${socio.id}`}>
                            <Button color="primary" className="btn btn-sm ms-1">
                              Ver Dados
                            </Button>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </TabPane>
  );
};

export default TabSaldos;
