import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { Card, CardBody, CardHeader, Col, Row, Spinner } from "reactstrap";
import { format, parseISO, subDays } from "date-fns";
import { ptBR } from "date-fns/locale";
import FeatherIcon from "feather-icons-react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

//redux
import TableContainer from "../../../Components/Common/TableContainer";

const AllTransactions = () => {
  const [transation, setTransation] = useState([]);
  const [loading, setLoading] = useState(false);

  const copyToClipboard = async (text) => {
    if (!navigator.clipboard) {
      toast.error("API de Clipboard não suportada no seu navegador.", {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: false,
        className: "bg-danger text-white",
      });
      return;
    }
    try {
      await navigator.clipboard.writeText(text);
      toast.success("Copiado para a área de transferência!", {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: false,
        className: "bg-success text-white",
      });
    } catch (err) {
      toast.error("Falha ao copiar o texto: ", err, {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: false,
        className: "bg-danger text-white",
      });
    }
  };

  function splitDateTime(dateTimeStr) {
    const dateTime = parseISO(dateTimeStr);

    const data = format(dateTime, "dd MMM, yyyy", { locale: ptBR });
    const hora = format(dateTime, "hh:mm a");

    return {
      data,
      hora,
    };
  }

  const formatCurrency = (value) => {
    if (!value) {
      return "";
    }
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  const fetchTransations = async () => {
    const user = JSON.parse(sessionStorage.getItem("authUser"));
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/cripto-td/compras-diarias`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      return response;
    } catch (error) {
      toast.error("Erro ao buscar operações: " + error, {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: false,
        className: "bg-danger text-white",
      });
      return [];
    }
  };

  const handleButtonClick = async () => {
    setLoading(true);
    const user = JSON.parse(sessionStorage.getItem("authUser"));
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/cripto-td/compra-diaria`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (response.status && response.status === 1) {
        toast.success(response.mensagem);
        const retorno = await fetchTransations();
        setTransation(retorno);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro na operação: " + error, {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: false,
        className: "bg-danger text-white",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const retorno = await fetchTransations();
      setTransation(retorno);
    };

    fetchData();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Moeda",
        accessor: "moeda",
        Cell: (cell) => (
          <>
            <div className="d-flex align-items-center">
              <img
                src={`https://api.fort.exchange/crypto-icons/${cell.row.original.moeda.toLowerCase()}.svg`}
                alt=""
                className="avatar-xxs me-2"
              />
              {cell.value}
            </div>
          </>
        ),
      },
      {
        Header: "Data",
        Cell: (cell) => (
          <>
            {splitDateTime(cell.row.original.created_at).data}{" "}
            <small className="text-muted">
              {splitDateTime(cell.row.original.created_at).hora}
            </small>
          </>
        ),
      },
      {
        Header: "Preço",
        accessor: "cotacao_brl",
        Cell: (cell) => <>{formatCurrency(cell.value)}</>,
      },
      {
        Header: "Quantidade",
        accessor: "quantidade_moeda",
        Cell: (cell) => (
          <>
            <h6
              className={"text-success amount mb-1"}
              style={{ cursor: "pointer" }}
              onClick={() =>
                copyToClipboard(cell.row.original.quantidade_moeda)
              }
              title="Clique para copiar o valor"
            >
              {parseFloat(cell.row.original.quantidade_moeda).toFixed(4)}{" "}
              {cell.row.original.moeda}{" "}
              <FeatherIcon
                icon="copy"
                style={{ color: "#ccc", height: "15px" }}
              />
            </h6>
            <p className="text-muted mb-0">
              {formatCurrency(cell.row.original.quantidade_brl)}
            </p>
          </>
        ),
      },
      {
        Header: "Lucro",
        accessor: "lucro",
        Cell: (cell) => <>{formatCurrency(cell.value)}</>,
      },
      {
        Header: "Ações",
        Cell: (cell) => {
          const moeda = cell.row.original.moeda;
          const data_max_raw = new Date(cell.row.original.created_at);
          const data_max = format(
            new Date(data_max_raw.setDate(data_max_raw.getDate() + 1)),
            "yyyy-MM-dd"
          );
          const data_min = format(subDays(new Date(data_max), 7), 'yyyy-MM-dd');
          return (
            <Link
              to={`/compra-diaria-transacoes?moeda=${moeda}&data_min=${data_min}&data_max=${data_max}`}
              className="btn btn-sm btn-primary"
            >
              Ver Transações
            </Link>
          );
        },
      },
    ],
    []
  );
  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <Row className="align-items-center g-3">
            <Col md={3}>
              <h5 className="card-title mb-0">Últimas Compras Diárias</h5>
            </Col>
            <div className="col-md-auto ms-auto">
              <div className="d-flex gap-2">
                <button
                  className="btn btn-success"
                  onClick={handleButtonClick}
                  disabled={loading}
                >
                  <i className="ri-bit-coin-fill align-bottom me-1"></i>
                  Nova Compra
                </button>
              </div>
            </div>
          </Row>
        </CardHeader>
        <CardBody>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Spinner height={100} width={100} />
            </div>
          ) : (
            <TableContainer
              columns={columns}
              data={transation || []}
              isGlobalFilter={false}
              isAddUserList={false}
              customPageSize={10}
              className="custom-header-css"
              divClass="table-responsive table-card mb-4"
              tableClass="align-middle table-nowrap mb-0"
              theadClass="table-light table-nowrap"
              thClass="table-light text-muted"
            />
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default AllTransactions;
