import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
} from "reactstrap";
import ParticlesAuth from "../../AuthenticationInner/ParticlesAuth";
import axios from "axios";
import { toast } from "react-toastify";
import ChatScript from "../../../Layouts/ChatScript";

import { Link } from "react-router-dom";
import logoLight from "../../../assets/images/logo-light2.png";

const BasicPasswReset = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [codigoParceiro, setCodigoParceiro] = useState("");

  const errorsEmail = "E-mail inválido ou vazio.";

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailChange = (event) => {
    const val = event.target.value;
    setEmail(val);
    if (!validateEmail(val)) {
      setEmailInvalid(true);
    } else {
      setEmailInvalid(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const postData = {
        email,
        codigo_parceiro: codigoParceiro,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/client/check-email`,
        postData
      );

      if (response.status && response.status === 1) {
        toast.success(response.mensagem);
      } else if (response.mensagem) {
        toast.error(response.mensagem);
        console.log(response);
      } else {
        console.log(response);
        toast.error("Erro ao enviar o e-mail!");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast.error("Ocorreu um erro desconhecido.");
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const codigo = urlParams.get("codigo_parceiro");
    if (codigo) {
      setCodigoParceiro(codigo);
    } else {
      setCodigoParceiro("fort");
    }
  }, []);

  document.title = "Reset de Senha | Painel Fort Exchange";
  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoLight} alt="" height="70" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Reset de Senha</h5>
                      <p className="text-muted">
                        Digite seu e-mail para receber um link de redefinição de
                        senha. Caso não receba o e-mail, verifique sua caixa de spam.
                      </p>
                    </div>
                    <div className="p-2 mt-4">
                      <Form onSubmit={handleSubmit} action="#">
                        <div className="mb-3">
                          <Label htmlFor="email" className="form-label">
                            E-mail
                          </Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Digite aqui"
                            type="email"
                            onChange={handleEmailChange}
                            onBlur={handleEmailChange}
                            value={email}
                            invalid={emailInvalid}
                          />
                          {emailInvalid && (
                            <FormFeedback type="invalid">
                              {errorsEmail}
                            </FormFeedback>
                          )}
                        </div>

                        <div className="mt-4">
                          <Button
                            color="success"
                            disabled={loading}
                            className="btn btn-success w-100"
                            type="submit"
                          >
                            {loading ? "Enviando..." : "Enviar"}
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>

                <div className="mt-4 text-center">
                  <p className="mb-0">
                    Voltar para a{" "}
                    <Link
                      to="/auth-signin-basic"
                      className="fw-bold text-primary text-decoration-underline"
                    >
                      {" "}
                      página de Login{" "}
                    </Link>{" "}
                  </p>
                </div>
              </Col>
            </Row>
            <ChatScript />
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default BasicPasswReset;
