import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Spinner } from "reactstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import axios from "axios";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Mousewheel } from "swiper";

const Widgets = () => {
  const [coins, setCoins] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchPrices = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/moedas/precos`
      );
      setCoins(response.data);
    } catch (error) {
      console.error("Houve um erro ao buscar os preços das moedas!", error);
    }
    setLoading(false);
  };

  const formatCurrency = (value) => {
    if (!value) {
      return "";
    }
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  useEffect(() => {
    fetchPrices();
  }, []);

  return (
    <React.Fragment>
      <Col xxl={3} md={6}>
        <Card className="card-animate">
          <CardBody>
            <div className="d-flex mb-3">
              <div className="flex-grow-1">
                <lord-icon
                  src="https://cdn.lordicon.com/fhtaantg.json"
                  trigger="loop"
                  colors="primary:#405189,secondary:#0ab39c"
                  style={{ width: "55px", height: "55px" }}
                ></lord-icon>
              </div>
              <div className="flex-shrink-0">
                <Link to="#" className="badge badge-soft-warning badge-border">
                  BTC
                </Link>
                <Link to="#" className="badge badge-soft-info badge-border">
                  ETH
                </Link>
                <Link to="#" className="badge badge-soft-primary badge-border">
                  MATIC
                </Link>
                <Link to="#" className="badge badge-soft-success badge-border">
                  USDT
                </Link>
              </div>
            </div>
            <h3 className="mb-2">
              <span className="counter-value" data-target="74858">
                <CountUp
                  start={0}
                  end={0}
                  separator=","
                  prefix="R$"
                  duration={3}
                />
              </span>
              <small className="text-muted fs-13">.00</small>
            </h3>
            <h6 className="text-muted mb-0">Comprado (BRL)</h6>
          </CardBody>
        </Card>
      </Col>
      <Col xxl={3} md={6}>
        <Card className="card-animate">
          <CardBody>
            <div className="d-flex mb-3">
              <div className="flex-grow-1">
                <lord-icon
                  src="https://cdn.lordicon.com/fhtaantg.json"
                  trigger="loop"
                  colors="primary:#405189,secondary:#0ab39c"
                  style={{ width: "55px", height: "55px" }}
                ></lord-icon>
              </div>
              <div className="flex-shrink-0">
                <Link to="#" className="badge badge-soft-warning badge-border">
                  BTC
                </Link>
                <Link to="#" className="badge badge-soft-info badge-border">
                  ETH
                </Link>
                <Link to="#" className="badge badge-soft-primary badge-border">
                  MATIC
                </Link>
                <Link to="#" className="badge badge-soft-success badge-border">
                  USDT
                </Link>
              </div>
            </div>
            <h3 className="mb-2">
              <span className="counter-value" data-target="74858">
                <CountUp
                  start={0}
                  end={0}
                  separator=","
                  prefix="R$"
                  duration={3}
                />
              </span>
              <small className="text-muted fs-13">.00</small>
            </h3>
            <h6 className="text-muted mb-0">Variação Cripto (BRL)</h6>
          </CardBody>
        </Card>
      </Col>
      <Col xxl={3} md={6}>
        <Card className="card-animate">
          <CardBody>
            <div className="d-flex mb-3">
              <div className="flex-grow-1">
                <lord-icon
                  src="https://cdn.lordicon.com/fhtaantg.json"
                  trigger="loop"
                  colors="primary:#405189,secondary:#0ab39c"
                  style={{ width: "55px", height: "55px" }}
                ></lord-icon>
              </div>
              <div className="flex-shrink-0">
                <Link to="#" className="badge badge-soft-warning badge-border">
                  BTC
                </Link>
                <Link to="#" className="badge badge-soft-info badge-border">
                  ETH
                </Link>
                <Link to="#" className="badge badge-soft-primary badge-border">
                  MATIC
                </Link>
                <Link to="#" className="badge badge-soft-success badge-border">
                  USDT
                </Link>
              </div>
            </div>
            <h3 className="mb-2">
              <span className="counter-value" data-target="74858">
                <CountUp
                  start={0}
                  end={0}
                  separator=","
                  prefix="R$"
                  duration={3}
                />
              </span>
              <small className="text-muted fs-13">.00</small>
            </h3>
            <h6 className="text-muted mb-0">Lucro (BRL)</h6>
          </CardBody>
        </Card>
      </Col>
      <Col xxl={3} md={6}>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Spinner height={100} width={100} />
          </div>
        ) : (
          <Swiper
            slidesPerView={1}
            spaceBetween={24}
            mousewheel={true}
            loop={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Mousewheel]}
            className="default-swiper rounded"
          >
            {coins.map((coin) => (
              <SwiperSlide key={coin.moeda}>
                <Card className="card-animate">
                  <div className="card-body bg-soft-warning">
                    <div className="d-flex mb-3">
                      <div className="flex-grow-1">
                        <lord-icon
                          src="https://cdn.lordicon.com/vaeagfzc.json"
                          trigger="loop"
                          colors="primary:#405189,secondary:#0ab39c"
                          style={{ width: "55px", height: "55px" }}
                        ></lord-icon>
                      </div>
                      <div className="flex-shrink-0">
                        <Link to="#" className="fw-medium">
                          {coin.moeda.toUpperCase()}
                        </Link>
                      </div>
                    </div>
                    <h3 className="mb-2">
                      {formatCurrency(coin.preco_atual_brl)}
                    </h3>
                    <h6 className="text-muted mb-0">Preço Atual</h6>
                  </div>
                </Card>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </Col>
    </React.Fragment>
  );
};

export default Widgets;
