import React, { useEffect, useState, useCallback } from "react";
import { Container, Row } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import AllOrders from "./AllOrders";
import axios from "axios";
import { toast } from "react-toastify";

const PixClientes = ({ isCliente }) => {
  const [loading, setLoading] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [shouldFetch, setShouldFetch] = useState(true); 

  const user = JSON.parse(sessionStorage.getItem("authUser"));

  const fetchPixTransactions = useCallback(
    async (user_id, page, limit) => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/clientes/depositos/${user_id}/${page}/${limit}`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        if (response.data && response.status && response.status === 1)  {
          return response.data;
        } else {
          toast.error(response.mensagem);
          return [];
        }
      } catch (error) {
        toast.error("Erro ao buscar operações.");
        console.log(error);
        return [];
      } finally {
        setLoading(false);
      }
    },
    [user.token] 
  );

  useEffect(() => {
    if (shouldFetch) {
      const fetchData = async () => {
        const retorno = await fetchPixTransactions(
          isCliente ? user.data._id : 0,
          1,
          10000
        );
        setOrderList(retorno);
        setShouldFetch(false);
      };

      fetchData();
    }
  }, [shouldFetch, isCliente, user.data._id, fetchPixTransactions]);

  document.title = "Depósitos PIX | Painel Fort Exchange";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="PIX"
            pageTitle={isCliente ? "Clientes Fort" : "Aplicações"}
          />
          <Row>
            <AllOrders
              orderList={orderList}
              loading={loading}
              setShouldFetch={setShouldFetch}
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PixClientes;
