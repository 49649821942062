import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Input,
  Row,
  Label,
  Col,
} from "reactstrap";
import { toast } from "react-toastify";
import axios from "axios";

const ModalNovaMoeda = ({
  show,
  fetchMoedas,
  setShowModal,
  redes,
  setMoedas,
}) => {
  const [loading, setLoading] = useState(false);
  const [moeda, setMoeda] = useState("");
  const [contrato, setContrato] = useState("");
  const [linkConsulta, setLinkConsulta] = useState("");
  const [dca, setDca] = useState(false);
  const [taxaEnvio, setTaxaEnvio] = useState(0);
  const [rede, setRede] = useState(null);

  const toggle = () => setShowModal(!show);

  const cadastrarMoeda = async () => {
    setLoading(true);
    const user = JSON.parse(sessionStorage.getItem("authUser"));
    const formData = {
      moeda,
      contrato,
      rede: rede,
      link_consulta: linkConsulta,
      cripto_td: dca,
      taxa_envio: taxaEnvio,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/moedas`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      console.log(formData);

      if (response.status && response.status === 1) {
        toast.success("Moeda cadastrada com sucesso!");
        setMoedas(await fetchMoedas());
        setShowModal(false);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro ao cadastrar moeda!");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        isOpen={show}
        toggle={toggle}
        backdrop={"static"}
        id="staticBackdrop"
        centered
        className="modal-lg"
      >
        <ModalHeader
          className="modal-title"
          id="staticBackdropLabel"
          toggle={toggle}
        >
          Dados da Moeda
        </ModalHeader>
        <ModalBody className="text-left p-5">
          <Row className="mb-3">
            <Col md="6" className="mb-3">
              <Label>Sigla da moeda:</Label>
              <Input
                type="text"
                placeholder="BTC, ETH, USDT..."
                value={moeda}
                onChange={(e) => {
                  setMoeda(e.target.value.toUpperCase());
                }}
              />
            </Col>
            <Col md="6" className="mb-3">
              <Label>Rede:</Label>
              <Input
                type="select"
                placeholder="Rede"
                value={rede}
                onChange={(e) => {
                  setRede(e.target.value);
                }}
              >
                <option value={null}>Selecione uma rede</option>
                {redes.map((rede) => (
                  <option value={rede.id} key={rede.id}>
                    {rede.nome}
                  </option>
                ))}
              </Input>
            </Col>

            <Col md="6" className="mb-3">
              <Label>Contrato:</Label>
              <Input
                type="text"
                placeholder="0x..."
                value={contrato}
                onChange={(e) => {
                  setContrato(e.target.value);
                }}
                maxLength={42}
              />
              <small className="text-muted">Somente redes EVM</small>
            </Col>

            <Col md="6" className="mb-3">
              <Label>Link Consulta de Preço:</Label>
              <Input
                type="text"
                placeholder="https://api.bitpreco.com..."
                value={linkConsulta}
                onChange={(e) => {
                  setLinkConsulta(e.target.value);
                }}
                maxLength={255}
              />
              <small className="text-muted">Somente Bitpreço</small>
            </Col>
            <Col md="6" className="mb-3">
              <Label>Taxa de Envio ( % ):</Label>
              <Input
                type="number"
                placeholder="Taxa de Envio"
                value={taxaEnvio}
                onChange={(e) => {
                  setTaxaEnvio(e.target.value);
                }}
                maxLength={5}
              />
            </Col>
            <Col md="6" className="mb-3">
              <div style={{ marginTop: "33px", marginLeft: "15px" }}>
                <Input
                  type="checkbox"
                  value={dca}
                  onChange={(e) => {
                    setDca(!dca);
                  }}
                  checked={dca}
                  className="me-2"
                />
                <Label>Disponível para DCA?</Label>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row className="mb-3">
            <div className="hstack gap-3 justify-content-right mt-3">
              <Button
                color="primary"
                onClick={() => cadastrarMoeda()}
                disabled={loading}
              >
                Cadastrar
              </Button>
              <Button color="light" onClick={() => setShowModal(false)}>
                Fechar
              </Button>
            </div>
          </Row>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default ModalNovaMoeda;
