import React, { useEffect } from "react";
import { Button } from "reactstrap";

const MatiButtonComponent = React.forwardRef(({ onClick, email }, ref) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://web-button.metamap.com/button.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <mati-button
      ref={ref}
      color="#22bfea"
      clientid={process.env.REACT_APP_MATI_CLIENT_ID}
      flowId={process.env.REACT_APP_MATI_FLOW_ID}
      metadata={`{"email":"${email}"}`}
      onClick={onClick}
      style={{ display: "none" }}
    />
  );
});
MatiButtonComponent.displayName = "MatiButtonComponent";

const MatiButton = ({ email }) => {
  const matiButtonRef = React.useRef(null);

  useEffect(() => {
    if (!matiButtonRef.current) return;

    const button = matiButtonRef.current;

    button.addEventListener("mati:loaded", ({ detail }) => {
      console.log("Started Flow", detail);
    });

    button.addEventListener("mati:userFinishedSdk", ({ detail }) => {
      console.log("Finished Flow", detail);
    });

    button.addEventListener("mati:exitedSdk", () => {
      console.log("Abandoned Flow");
    });
  }, [matiButtonRef]);

  const handleClick = () => {
    if (matiButtonRef.current) {
      matiButtonRef.current.click();
    }
  };

  return (
    <>
      <Button color="danger" onClick={handleClick} style={{width:"100%"}}>
        Completar KYC
      </Button>
      <MatiButtonComponent ref={matiButtonRef} email={email} />
    </>
  );
};

export default MatiButton;
