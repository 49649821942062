import React, { useCallback, useEffect, useState } from "react";
import { ethers } from "ethers";
import { Button } from "reactstrap";
import { toast } from "react-toastify";

const ConnectMetamaskButton = ({ networkConfig, isConnected, setIsConnected }) => {

  const connectMetamask = useCallback(async () => {
    if (!window.ethereum) {
      toast.error("Metamask não encontrado. Você precisa ter o Metamask instalado para se conectar.");
      return;
    }
    try {
      const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
      if (accounts.length === 0) {
        toast.error("Usuário negou a conexão.");
      }

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const network = await provider.getNetwork();
      if (network.chainId !== parseInt(networkConfig.chainId, 16)) {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [networkConfig],
        });

        const newNetwork = await provider.getNetwork();
        setIsConnected(newNetwork.chainId === parseInt(networkConfig.chainId, 16));

        if (newNetwork.chainId === parseInt(networkConfig.chainId, 16)) {
          toast.success(`Conectado à ${networkConfig.chainName} com sucesso!`);
        } else {
          toast.error(`Falha ao conectar à ${networkConfig.chainName}.`);
        }
      } else {
        setIsConnected(true);
        toast.success(`Já está conectado à ${networkConfig.chainName}!`);
      }
    } catch (err) {
      setIsConnected(false);
      console.error(err);
    }
  }, [networkConfig, setIsConnected]);

  useEffect(() => {
    if (!window.ethereum) return;

    const handleAccountsChanged = async () => {
      const accounts = await window.ethereum.request({ method: "eth_accounts" });
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const network = await provider.getNetwork();
      setIsConnected(accounts.length > 0 && network.chainId === parseInt(networkConfig.chainId, 16));
    };

    handleAccountsChanged();

    window.ethereum.on("accountsChanged", handleAccountsChanged);
    window.ethereum.on("chainChanged", handleAccountsChanged);

    return () => {
      if (window.ethereum.removeListener) {
        window.ethereum.removeListener("accountsChanged", handleAccountsChanged);
        window.ethereum.removeListener("chainChanged", handleAccountsChanged);
      }
    };
  }, [networkConfig, setIsConnected]);

  return (
    <Button
      className={`btn ${isConnected ? "btn-success" : "btn-danger"}`}
      onClick={()=>connectMetamask()}
      disabled={isConnected}
    >
      {isConnected
        ? `Conectado à ${networkConfig.chainName}`
        : `Conectar Metamask`}
    </Button>
  );
};

export default ConnectMetamaskButton;
